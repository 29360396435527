import React, { useCallback, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { DebateComment } from "../../../../types/debate";
import { Typography } from "../../../Typography";
import { CircularAvatarPhoto } from "../../../elements";
import { ReplyDebate } from "../ReplyDebate";
import { DeleteDebate } from "../DeleteDebate";
import { isMobile } from "../../../../isMobile";
import { Link } from "react-router-dom";
import { CommentTextBody, CommentsTag, Citation, CommentMeta } from "./CommentStyled";
import { FaRegEdit } from "react-icons/fa";
import { CommentLikeButton } from "../../../CommentLikeButton";
import { useUserContext } from "../../../../context/user/AuthContext";

export interface Props {
	comment: DebateComment;
	parentId?: number | string | null;
	type: "lesson" | "comunity" | "article";
	entityId?: number;
	margin?: string;
	avatarSize?: string;
	showReactions?: boolean;
	replySuccessCallBack?: () => void;
	onCreateCommentAction?: () => void;
	link?: boolean; // indica se este comentário é um link para um topico details
	isAuthorUser?: boolean;
	rootComment?: boolean;
	onAction?: () => void;
}

const CommentWrapper: React.FC<Props> = ({
	link, //
	comment,
	type,
	parentId,
	entityId,
	margin,
	avatarSize,
	rootComment,
	// isAuthorUser,
	replySuccessCallBack,
	onAction,
}: Props) => {
	const { userData } = useUserContext();
	const [editPrivileges, setEditPrivileges] = useState(false);
	const setAvatarSize = useCallback(() => {
		if (isMobile.any()) {
			return "40px";
		} else {
			return avatarSize;
		}
	}, [avatarSize]);
	//console.log(comment);
	const splitForProfileId = (profileUrl?: string) => {
		if (!profileUrl) return "/";
		let id = profileUrl.split("/")[4];
		return `/perfil/${id}`;
	};

	const urlizeComment = useCallback((commentText?: string): string => {
		const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

		const regex = new RegExp(expression);

		if (!commentText) return "";

		const matched = commentText.match(regex); // checar se tem algum link no comentário

		if (!matched) return commentText; // se não tiver link, retorna o texto original

		let newCommentText = commentText;

		matched.forEach((m) => {
			let stripped = m
				.split("<")
				.map((item) => item.split(">"))
				.flat()
				.map((item) => item.split("("))
				.flat()
				.map((item) => item.split(")"))
				.flat()
				.map((item) => item.match(regex))
				.flat()
				.filter((item) => item !== null && item !== undefined);

			stripped.forEach((match) => {
				if (match) {
					newCommentText = newCommentText.replace(match, `<a href='${match}' target='_blank' rel='nofollow'>${match}</a>`);
				}
			});
		});

		// console.log(newCommentText);
		return newCommentText;
	}, []);

	useEffect(() => {
		if (comment && comment.author.id === userData.user_id) {
			setEditPrivileges(true);
		}
	}, []);

	const handleAction = () => {
		if (onAction) {
			onAction();
		}
	};

	return (
		<Box margin={margin} display='flex'>
			<Box>
				{link ? (
					<CircularAvatarPhoto //
						avatarImg={comment?.author.imageUrl}
						size={setAvatarSize()}
						isMonitor={comment?.author.isMonitor}
						isProfessor={comment?.author.isProfessor}
						isTutor={comment?.author.isTutor}
						borderType='single'
						innerBorderSize='3px'
						outerBorderSize='6px'
					/>
				) : (
					<Link to={`/perfil/${comment?.author.profileId}`} title={`Ver perfil de ${comment.author.name}`}>
						<CircularAvatarPhoto //
							avatarImg={comment?.author.imageUrl}
							size={setAvatarSize()}
							isMonitor={comment?.author.isMonitor}
							isProfessor={comment?.author.isProfessor}
							isTutor={comment?.author.isTutor}
							borderType='single'
							innerBorderSize='3px'
							outerBorderSize='6px'
						/>
					</Link>
				)}
			</Box>
			<Box width='100%' marginLeft='10px'>
				<Box display='flex' justifyContent='space-between;' alignItems='baseline'>
					<Typography variant='title' fontWeight='bold' size='0.875rem' margin='0 0 10px 16px'>
						{comment?.author.name} {comment.author.isFounder && "(membro fundador)"}
						<Typography display='inline-block' variant='text' size='0.875rem' color='#b5b5b5' margin='0 0 0 5px'>
							{comment?.date}
						</Typography>
						<CommentMeta>
							<Typography variant='text' size='0.875rem' color='#b5b5b5'>
								{comment.author.profession && comment.author.profession}
								{comment.author.profession && comment.author.address && " - "}
								{comment.author.address && comment.author.address !== "null/null" && comment.author.address}
							</Typography>
						</CommentMeta>
					</Typography>

					<Box display='flex' alignItems='center' marginLeft='auto'>
						{!link && (
							<>
								{!rootComment && (
									<>
										<CommentLikeButton type={type} likeCount={comment.likeCount} commentId={comment.id} liked={comment.isLiked} />
										{editPrivileges && (
											<>
												{parentId && (
													<>
														<DeleteDebate commentId={comment.id} type={type} entityId={entityId} successCallback={replySuccessCallBack} />
														<ReplyDebate
															iconElement={<FaRegEdit size={20} color='#013A7B' />}
															parentId={parentId}
															commentId={comment.id}
															type={type}
															entityId={entityId}
															successCallback={replySuccessCallBack}
															debateData={comment}
															comment={comment}
															actionCallback={() => handleAction()}
														/>
														&nbsp;
													</>
												)}
											</>
										)}
										{parentId ? (
											<ReplyDebate
												commentId={comment.id}
												parentId={parentId}
												type={type}
												entityId={entityId}
												successCallback={replySuccessCallBack}
												comment={comment}
												actionCallback={() => handleAction()}
											/>
										) : (
											<ReplyDebate comment={comment} commentId={comment.id} type={type} entityId={entityId} successCallback={replySuccessCallBack} actionCallback={() => handleAction()} />
										)}
									</>
								)}
							</>
						)}
					</Box>
				</Box>
				<Divider />
				<Typography variant='text' size='0.875rem' margin='10px 0'>
					{comment.repliedComment && (
						<Citation>
							<span className='citedComment'>
								Em resposta à <Link to={() => splitForProfileId(comment.repliedComment?.perfil_url)}>{comment.repliedComment.nome}</Link> em {comment.repliedComment.created}
							</span>
							<div className='citedCommentBody' dangerouslySetInnerHTML={{ __html: comment.repliedComment.descricao }}></div>
						</Citation>
					)}
					<CommentTextBody dangerouslySetInnerHTML={{ __html: rootComment ? comment?.text : urlizeComment(comment?.text) }} />
					{/* <br />
					User has Privileges? {editPrivileges.toString()}
					<br />
					Coment User ID: {comment.author.id}
					<br />
					user ID: {userData.user_id} */}
				</Typography>
				{comment.isNew ? <CommentsTag>Novo</CommentsTag> : ""}
			</Box>
		</Box>
	);
};

CommentWrapper.defaultProps = {
	margin: "0",
	avatarSize: "70px",
	showReactions: true,
};

export { CommentWrapper as Comment };
