import React from "react";

interface IIconProps {
	color?: string;
}

const SVG = ({ color }: IIconProps) => (
	<svg id='Componente_8_1' data-name='Componente 8 – 1' width='31.412' height='21.454' viewBox='0 0 35.412 25.454'>
		<defs>
			<linearGradient id='linear-gradient-pos' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'>
				<stop offset='0' stopColor='#00def0' />
				<stop offset='1' stopColor='#00f0c2' />
			</linearGradient>
			<clipPath id='clip-path-pos'>
				<rect id='Retângulo_79' data-name='Retângulo 79' width='35.412' height='25.454' fill='url(#linear-gradient-pos)' />
			</clipPath>
		</defs>
		<g id='Grupo_86' data-name='Grupo 86' clipPath='url(#clip-path-pos)'>
			<path
				id='Caminho_39'
				data-name='Caminho 39'
				d='M34.747,7.285,18.146.091a1.107,1.107,0,0,0-.88,0L.665,7.285a1.108,1.108,0,0,0,0,2.031L6.639,11.9v6.91c0,3.724,4.861,6.64,11.067,6.64s11.067-2.917,11.067-6.64V11.9l1.107-.48V23.241a1.107,1.107,0,1,0,2.213,0V10.465l2.653-1.15a1.108,1.108,0,0,0,0-2.031M17.706,2.313,31.523,8.3l-1.1.475L17.843,7.2a1.107,1.107,0,1,0-.275,2.2l8.863,1.108-8.726,3.781L3.889,8.3Zm8.854,16.5c0,1.043-.856,2.116-2.348,2.945a15.011,15.011,0,0,1-13.011,0c-1.492-.829-2.348-1.9-2.348-2.945V12.863l8.414,3.646a1.106,1.106,0,0,0,.88,0l8.414-3.646Z'
				transform='translate(0 0)'
				fill='url(#linear-gradient-pos)'
			/>
		</g>
	</svg>
);

export { SVG as PosIcon };
