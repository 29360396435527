// import RobotoRegular from './assets/fonts/Lato-Regular.ttf';
import media from "./media";

const Theme = {
	shadows: ["0px 4px 4px rgba(0, 0, 0, 0.25)"],
	headerHeight: "70px",
	color: {
		primary: "#013A7B",
		secondary: "#476890",
	},
	palette: {
		type: "light",
		primary: {
			main: "#2D9CDB",
			error: "#ff4444",
			highlight: "#2E3B80",
			disable: "#ccc",
		},
		secondary: {
			main: "#fff",
		},
	},
	margin: {
		small: "8px",
		regular: "12px",
		large: "16px",
	},
	typography: {
		fontFamily: {
			primary: "'Roboto', sans-serif",
			secondary: "'Kanit', sans-serif",
		},
		size: {
			smallest: "10px",
			small: "14px",
			regular: "16px",
			large: "18px",
			largest: "20px",
		},
	},
	...media,
};

export default Theme;
