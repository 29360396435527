import styled from "styled-components";
import media from "../../../styles/media";

const ClassFileItemContainer = styled.li`
	list-style: none;
	padding: 15px;
	border: 1px solid #efefef;
	border-radius: 10px;
	border-bottom: 0 none;
	box-shadow: 0 1px #ffffff inset, 0 1px 4px rgba(34, 25, 25, 0.4);
	background: #ffffff;
	width: 48%;
	position: relative;
	display: flex;
	flex-direction: column;
	${media.greaterThan("huge")`
		width: 32%;
	`}
	${media.lessThan("medium")`
		width: 100%;
	`}
`;

const FileTypeIcon = styled.div`
	display: flex;
	align-items: center;
	font-size: 0.9rem;
	color: #ccc;
	svg {
		margin-right: 7px;
	}
`;

const FileName = styled.span`
	display: block;
	margin: 15px 0 4px;
	font-size: 1.125rem;
	word-break: break-all;
	flex: 1;
`;

const FileSize = styled.small`
	display: block;
	font-size: 0.8rem;
	opacity: 0.7;
	margin-bottom: 20px;
`;

const FileFooter = styled.div`
	a,
	button {
		background: none;
		cursor: pointer;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		color: #666;
		transition: 0.3s;
		padding-top: 12px;
		border-top: 1px solid #efefef;
		position: relative;
		bottom: 0;
		&:hover {
			opacity: 0.6;
		}
		svg {
			margin-right: 7px;
		}
	}
`;

const FileObs = styled.div`
	font-size: 0.9rem;
	margin-bottom: 12px;
	p {
		margin-bottom: 0 !important;
		font-weight: 600;
	}
`;

const FileStatus = styled.div`
	position: absolute;
	top: 15px;
	right: 15px;
	font-size: 0.7rem;
	text-transform: uppercase;
	font-weight: 700;
	color: white;
	padding: 2px 5px;
	border-radius: 50px;
	&.apv {
		background: #7cbb00;
	}
	&.rpv {
		background: red;
	}
	&.pnd {
		background: #ccc;
	}
`;

export {
	ClassFileItemContainer, //
	FileTypeIcon,
	FileName,
	FileSize,
	FileFooter,
	FileObs,
	FileStatus,
};
