import styled from "styled-components";
import media from "../../styles/media";

interface QuestionContainerProps {
	isError: boolean;
}

const QuizContainerContainer = styled.div`
	//display: flex;
	.btnTentativa {
		padding: 10px;
		border: 1px solid #ccc;
		margin: 0 10px 0 0;
		background: none;
		outline: none;
		border-radius: 3px;
		&:hover,
		&.selected {
			border: 1px solid ${(props) => props.theme.color.primary};
			color: ${(props) => props.theme.color.primary};
			font-weight: 600;
		}
	}
`;

const QuestionContainer = styled.div<QuestionContainerProps>`
	padding-bottom: 35px;
	margin-bottom: 35px;
	border-bottom: 1px solid #e9e7d0;
	//background: ${(props) => (props.isError ? "red" : "")};
`;

const QuestionTitle = styled.h4`
	font-weight: unset;
	display: flex;
	width: calc(100% - 30px);
	p {
		display: block;
	}
	b {
		font-weight: 700;
		&.qnum {
			width: 35px;
		}
	}
	.enunciado {
		width: calc(100% - 35px);
	}
	.cenunciado {
		flex: 1;
	}
`;

const CorrectionTitle = styled.div`
	font-weight: unset;
	margin-bottom: 20px;
	display: flex;
	p {
		display: block;
	}
	b {
		font-weight: 700;
		&.qnum {
			width: 35px;
		}
	}
	.enunciado {
		width: calc(100% - 35px);
	}
	.cenunciado {
		flex: 1;
	}
	.correctionIcon {
		width: 30px;
	}
`;

const QuestionOptionsContainer = styled.div``;

const RadioWrapper = styled.label`
	display: flex;
	//align-items: center;
	min-height: 25px;
	min-width: 25px;
	margin-bottom: 12px;
	input {
		width: 25px;
		height: 25px;
		margin: 0 6px 0 0;
	}
	span {
		width: calc(100% - 35px);
	}
	.correctionIcon {
		width: 30px;
	}
	.correctionText {
		width: calc(100% - 30px);
	}
`;

const ButtonWrapper = styled.div`
	background: #fff;
	padding-top: 35px;
	margin-top: -71px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	button {
		outline: none;
	}
	${media.lessThan("medium")`
    justify-content: center;
    flex-wrap: wrap;
  `};
`;

const ErrorText = styled.span`
	color: red;
	margin: 0 16px 0 0;
	display: block;
	${media.lessThan("medium")`
    width: 100%;
    margin: 0 0 16px 0;
    text-align: center;
  `};
`;

const QuestionCommentary = styled.div`
	big {
		font-size: 1rem;
		font-weight: 700;
		display: block;
		margin: 0 0 6px;
		padding: 16px 0 0;
	}
`;

const VideoWrapper = styled.div`
	border-radius: 6px;
	overflow: hidden;
	width: 100%;
	position: relative;
	margin-bottom: 24px;
	&:before {
		display: block;
		content: "";
		padding-top: 56.25%;
	}
	&:after {
		content: "Carregando...";
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 90;
		background: #333;
		color: white;
	}
	${media.lessThan("medium")`
        height: auto;
    `}
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 99;
	}
`;
export {
	QuizContainerContainer, //
	QuestionContainer,
	QuestionTitle,
	QuestionOptionsContainer,
	RadioWrapper,
	ButtonWrapper,
	ErrorText,
	CorrectionTitle,
	QuestionCommentary,
	VideoWrapper,
};
