import styled from 'styled-components';

const CheckboxWrapper = styled.div`
  padding: 5px 15px;
  &:not(:first-child){
    border-top: 1px solid #F1F1F1;
  }
  .MuiTypography-body1{
    font-size: 0.75rem;
  }
`;
export { CheckboxWrapper };
