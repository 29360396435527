import styled, { css } from "styled-components";

const RelatedItemCardContainer = styled.div`
	display: flex;
	border-radius: 6px;
	overflow: hidden;
	width: 100%;
`;
interface OrderProps {
	order: number;
}

const Order = styled.div<OrderProps>`
	display: flex;
	min-width: 90px;
	padding: 10px;
	align-items: center;
	justify-content: center;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), ${(props) => props.theme.color.primary};
	font-size: 1rem;
	font-weight: bold;
	color: #fff;
	line-height: 20px;
	.order-number {
		margin: 0 5px;
		${(props) =>
			props.order === 1 &&
			css`
				margin-left: 25px;
			`}
	}
`;

const InfoWrapper = styled.div`
	padding: 10px 20px;
	font-size: 1rem;
	line-height: 1.3;
	color: #fff;
	font-weight: 700;
	background: ${(props) => props.theme.color.primary};
	width: 30%;
	display: flex;
	align-items: center;
`;

const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
`;
const Subtitle = styled.div`
	font-size: 0.75rem;
	font-weight: 400;
	color: rgba(255, 255, 255, 0.6);
`;

const Content = styled.div`
	padding: 10px 20px;
	border: 1px solid #e8e8e8;
	border-radius: 0 6px 6px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 70%;
`;

export { RelatedItemCardContainer, Order, TitleWrapper, Subtitle, Content, InfoWrapper };
