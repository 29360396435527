import React from "react";
import { Box } from "@material-ui/core";

import Swiper, { Pagination, Autoplay, Navigation } from "swiper";
import { Swiper as Slider, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

import { isMobile } from "../../../../isMobile";

import { CourseCategorySliderContainer } from './CourseCategorySliderStyled'
import { CourseCardReturn } from "../../../../helpers/courseCardReturn";
import { CourseCardLink } from "../../../../helpers/linkCourseCard";

export interface Props {
  courseList: any[],
  isOpenForSalesSlider: boolean
}

Swiper.use([Pagination, Autoplay, Navigation]);

const CourseCategorySliderWrapper: React.FC<Props> = ({
  courseList,
  isOpenForSalesSlider,
  ...props
}: Props) => {

  return (
    <CourseCategorySliderContainer {...props}>
      {isMobile.any() || courseList.length > 4 ? (
        <Slider
          pagination={{
            clickable: true,
          }}
          slidesPerView={isMobile.any() ? 1 : 4}
          navigation
          watchOverflow
          spaceBetween={15}
        >
          {courseList.map((curso: any) => {
            return curso.tem_acesso !== isOpenForSalesSlider && (
              <SwiperSlide key={curso.id}>
                {isOpenForSalesSlider ?
                  <a className='courseLink' href={curso.link_vendas} target="_blank">
                    {CourseCardReturn(curso)}
                  </a> : <>{CourseCardLink(curso)}</>}
              </SwiperSlide>
            )
          })}
        </Slider>
      ) : (
        <>
          <Box
            width='100%'
            display='grid'
            gridTemplateColumns={{
              xs: "100%",
              sm: "100%",
              md: "24% 24% 24% 24%",
            }}
            gridRowGap='15px'
            gridColumnGap='1%'>
            {courseList.map((curso: any) => {
              return curso.tem_acesso !== isOpenForSalesSlider && (
                <React.Fragment key={curso.id}>
                  {isOpenForSalesSlider ?
                    <a className='courseLink' href={curso.link_vendas} target="_blank">
                      {CourseCardReturn(curso)}
                    </a> : <>{CourseCardLink(curso)}</>}
                </React.Fragment>
              )
            })}
          </Box>
        </>
      )}
    </CourseCategorySliderContainer>
  );
};

CourseCategorySliderWrapper.defaultProps = {

}

export { CourseCategorySliderWrapper as CourseCategorySlider };