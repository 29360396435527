import BaseService from "./base.service";

export default class ComunidadeService extends BaseService {
	constructor() {
		super("comunidade/topicos");
	}

	formatReaction(reaction) {
		return {
			name: reaction.reaction,
			count: reaction.contador,
		};
	}

	formatComment(comment) {
		const reactions_formated = comment.reacts.map((reaction) => this.formatReaction(reaction));
		const formated_comment = {
			author: {
				name: comment.criado_por.nome,
				isMonitor: true,
				imageUrl: comment.criado_por.imagem_url,
			},
			text: comment.descricao,
			date: comment.data_publicacao,
			reactions: reactions_formated,
			userReactions: [],
		};

		return formated_comment;
	}

	debateListFormatData(debate) {
		const reactions_formated = debate.reacts.map((reaction) => this.formatReaction(reaction));
		const debateComment = {
			author: {
				name: debate.criado_por.nome,
				isMonitor: true,
				imageUrl: debate.criado_por.imagem_url,
			},
			text: debate.descricao,
			date: debate.data_publicacao,
			reactions: reactions_formated,
			userReactions: [],
		};

		return {
			id: debate.id,
			slug: debate.id,
			title: debate.titulo,
			comment: debateComment,
			usersReactions: reactions_formated,
			reactionsCount: debate.reacts.length,
			studentsCount: 10,
			commentsCount: 10,
			isFavorite: false,
			isNotificationOn: false,
		};
	}

	formatListData(comunity_debates) {
		const debates_category = comunity_debates.map((comunity_debate) => {
			const debates_formated = comunity_debate.topicos.map((debate) => this.debateListFormatData(debate));

			const category = {
				category: comunity_debate.nome,
				slug: comunity_debate.slug,
				debatesCount: comunity_debate.topicos.length,
				studentsCount: 10,
				debates: debates_formated,
			};
			return category;
		});

		return debates_category;
	}

	formatDetailData(debate) {
		const formatedDebate = this.debateListFormatData(debate);
		const formatedComments = debate.comentarios?.map((comment) => this.formatComment(comment));
		formatedDebate.comments = formatedComments;

		return formatedDebate;
	}
}
