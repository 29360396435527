import React from "react";
import { Box } from "@material-ui/core";
import { Typography } from "../../../Typography";
import { CardWrapper } from "../..";
import { EditIcon } from "../../Icons";
import { truncateHTML, truncateTitle } from "../../../../lib/string";
import { LessonCardContainer, LessonCardContent, LessonCardContentHeader, LessonCardFooter, LessonCardHeader } from "./LessonCardStyled";

import { ILesson } from "../../../../services/prod.lesson.service";
import { CreateLesson } from "../../../features/Producer_bkp";

export interface Props {
	lesson: ILesson;
	editSuccessCallback?: () => void;
}

const LessonCardWrapper: React.FC<Props> = ({ lesson, editSuccessCallback }: Props) => (
	<LessonCardContainer>
		<CardWrapper padding='0' margin='0'>
			<LessonCardHeader>
				<Typography variant='title' size='16px'>
					{truncateTitle(lesson.name)}
				</Typography>
			</LessonCardHeader>
			<LessonCardContentHeader>
				<Typography variant='subtitle' fontWeight='bold' size='12px'>
					<span>{lesson.materialsCount}</span> {lesson.materialsCount === 1 ? "material" : "materiais"}
				</Typography>
				<Typography variant='subtitle' fontWeight='bold' size='12px'>
					<span>{0}</span> cursos vinculados
				</Typography>
			</LessonCardContentHeader>
			<LessonCardContent>
				<Box dangerouslySetInnerHTML={{ __html: truncateHTML(lesson.description) || "Sem descrição" }} />
			</LessonCardContent>
			<LessonCardFooter>
				<Typography variant='subtitle' fontWeight='bold' size='0.7rem' color='#000000ee'>
					Incluído em {lesson.releaseDate}
				</Typography>
				<Box display='flex' alignItems='flex-end'>
					<CreateLesson lessonData={lesson} icon={(props) => EditIcon(props)} successCallback={editSuccessCallback} />
				</Box>
			</LessonCardFooter>
		</CardWrapper>
	</LessonCardContainer>
);

LessonCardWrapper.defaultProps = {};

export { LessonCardWrapper as LessonCard };
