import { useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { multipartHeaders } from "../services/config";

interface IUseCreateFromModal {
	formRef: any;
	schema?: Yup.ObjectSchema;
	isUpdate?: boolean;
	item?: any;
	service: any;
	successMessage: string;
	handleSuccess: (...args: any) => void;
	handleError?: (...args: any) => void;
}

export const useCreateFromModal = (props: IUseCreateFromModal) => {
	const { formRef, schema, isUpdate, item, service, successMessage, handleSuccess, handleError } = props;

	const [loading, setLoading] = useState(false);

	const handleResult = (response: any) => {
		if (response.error) {
			const validationErrors: any = {};
			if (response.data) {
				Object.keys(response.data).map((error: string) => {
					//console.log("hhauahu", typeof response.data[error][0]);
					if (typeof response.data[error][0] === "string") {
						validationErrors[error] = response.data[error][0];
					} else if (typeof response.data[error][0] === "object") {
						//console.log(response.data[error][0]);
					}
				});
				formRef?.current?.setErrors(validationErrors);
				if (handleError) {
					handleError(response.data);
				}
			}
		} else {
			toast.success(`${successMessage}`);
			handleSuccess(response.data);
		}
	};

	async function handleUpdate(data: any) {
		setLoading(true);

		//console.log("UPDATE DATA=> ", data);

		const isComment = data.comentario ? true : false;

		let newData = data;
		if (data.comentario === data.id) {
			newData = { ...data, comentario: null };
		}

		const response = await service.update(item.id, newData, !isComment, multipartHeaders);

		//console.log("RESPONSE FROM UPDATE => ", response);

		setLoading(false);
		handleResult(response);
	}

	async function handleCreate(data: any) {
		setLoading(true);
		const response = await service.create(data, multipartHeaders);
		setLoading(false);
		handleResult(response);
	}

	async function handleSubmit(data: any) {
		try {
			formRef?.current?.setErrors({});
			if (schema) {
				await schema.validate(data, {
					abortEarly: false,
				});
			}

			if (isUpdate) {
				handleUpdate(data);
			} else {
				handleCreate(data);
			}
		} catch (err) {
			const validationErrors: any = {};
			if (err instanceof Yup.ValidationError) {
				err.inner.forEach((error: any) => {
					validationErrors[error.path] = error.message;
				});
				formRef?.current?.setErrors(validationErrors);
			}
		}
	}

	return { handleSubmit, loading };
};
