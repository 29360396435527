import React from "react";
import { ContentLoadingErrorContainer } from "./ContentLoadingErrorStyled";
import { IoMdRefresh } from "react-icons/io";
import { Typography } from "../Typography";

export interface Props {
	refresh: () => void;
	loadedResourceErrorMsg?: string;
}

const ContentLoadingErrorWrapper: React.FC<Props> = ({ loadedResourceErrorMsg, ...props }: Props) => {
	return (
		<ContentLoadingErrorContainer {...props}>
			<Typography variant='title' align='center' margin='0 0 15px'>
				Ops! Algo inesperado aconteceu.
			</Typography>
			<Typography variant='text' align='center' margin='0 0 15px'>
				Ocorreu um erro ao carregar {loadedResourceErrorMsg ? loadedResourceErrorMsg : "este recurso"}.<br />
				Clique no botão abaixo para tentar novamente
			</Typography>
			<button
				className='btnRefresh'
				onClick={() => {
					props.refresh();
				}}>
				<span className='txt'>Recarregar</span>
				<span className='icon'>
					<IoMdRefresh size={25} />
				</span>
			</button>
		</ContentLoadingErrorContainer>
	);
};

ContentLoadingErrorWrapper.defaultProps = {};

export { ContentLoadingErrorWrapper as ContentLoadingError };
