function truncateHTML(html:string, truncateSize = 250):string {
  const truncateIdentifier = ' [...]';
  const strippedTag = html.replace(/(<([^>]+)>)/gi, '')
    .substring(0, truncateSize)
    .replace('\n', '<br />')
    .replace('\r', '<br />')
    .replace('\t', '&nbsp;&nbsp;&nbsp;&nbsp;');

  return strippedTag.length > truncateSize ? strippedTag + truncateIdentifier : strippedTag;
}

function truncateTitle(title:string, truncateSize = 60):string {
  const truncatedString = title.substring(0, truncateSize);
  const previousSpaceIndex = truncatedString.lastIndexOf(' ');
  return title.length > truncateSize ? `${title.substring(0, previousSpaceIndex).trimEnd()}...` : title;
}

function phoneMask(phoneNumber:string):string {
  const numbers = phoneNumber.replace(/\D/g, '').split('');
  const maskedPhone = numbers.map((number, index) => {
    if (index <= 10) {
      switch (index) {
        case 0: return `(${number}`;
        case 2: return `) ${number}`;
        case 7: return `-${number}`;
        default: return number;
      }
    }
    return '';
  });

  return maskedPhone.join('').trim();
}

function postalCodeMask(postalCode:string):string {
  const numbers = postalCode.replace(/\D/g, '').split('');
  const maskedPostalCode = numbers.map((number, index) => {
    if (index <= 7) {
      switch (index) {
        case 2: return `.${number}`;
        case 5: return `-${number}`;
        default: return number;
      }
    }
    return '';
  });

  return maskedPostalCode.join('').trim();
}

export {
  truncateHTML, truncateTitle, phoneMask, postalCodeMask,
};
