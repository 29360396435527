import styled from 'styled-components'
import { Props } from './index';

const BestSellingSealContainer = styled.div<Props>`
  display: flex;
  svg {
    overflow: visible;
  }
  .ribbon {
    fill: ${(props) => props.ribbonColor ? props.ribbonColor : "#DD0000"}
  }
  .heart {
    fill: ${(props) => props.heartColor ? props.heartColor : "#DD0000"}
  }
  .background {
    fill: ${(props) => props.background ? props.background : "#FFF"};
    stroke: ${(props) => props.strokeColor ? props.strokeColor : "#8EBE05"};
    stroke-width: 2;
  }
  `
  export { BestSellingSealContainer }