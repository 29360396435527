import api from "./api";
import BaseService from "./base.service";

export default class DownloadsService extends BaseService {
	constructor(page?: number | string) {
		if (page) {
			super(`multimidia/materiais?page=${page}`);
		} else {
			super("multimidia/materiais");
		}
	}

	async mark_as_seen(id: number) {
		try {
			const response = await api.get(`${this.resource}/${id}/baixar/`);
			// console.log("Material Baixado => ", response.data);
			return response.data;
		} catch (err) {
			console.error(`erro ao marcar material como visto ${this.resource} :`, err);
			//console.log(`erro ao listar ${this.resource} :`, err.response);
			return { success: false };
		}
	}
}
