import styled from "styled-components";
import { Props } from "./index";

const GamificationBadgeContainer = styled.div<Props>`
	width: ${(props) => (props.size ? props.size + "px" : "")};
	max-height: ${(props) => (props.size ? props.size + "px" : "300px")};
	margin: 0 5px;
	img {
		margin: 0 auto;
		display: block;
		max-width: 100%;
		max-height: ${(props) => (props.size ? props.size + "px" : "300px")};
	}
`;
export { GamificationBadgeContainer };
