import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@material-ui/core";

import { Typography, PageContainer, GreyContainer, InnerContentContainer } from "../../components";

// import { FeatureBanner } from "../../components/elements";
import {
	DebateItem,
	//CreateDebateModal,
} from "../../components/features/Debate";
// import { DebateItem as DebateItemType, ComunityDebate } from "../../types/debate";

import ComunityFeaturedService from "../../services/comunityFeatured.service";

import ComunityAllService from "../../services/comunityAll.service";
import ComunityService from "../../services/comunity.service";
import loadingGif from "../../assets/images/loading.gif";

import { CategoriesContainer, FilterTitle } from "../Articles/ArticlesStyled";
import { Tag } from "./ComunityStyled";

import { FaFireAlt } from "react-icons/fa";
import { IoIosChatbubbles } from "react-icons/io";
import { useParams } from "react-router-dom";
import { HomePageFeatureSlider } from "../../components/features/HomePageFeatureSlider";

export interface Props {
	background?: string;
}

interface ICommunityDebateCategory {
	id: number;
	nome: string;
	topicos: any[];
}

const Comunity: React.FC<Props> = ({ ...props }) => {
	const [comunityDebates, setComunityDebates] = useState<ICommunityDebateCategory[]>([]);
	const [categoryFilter, setCategoryFilter] = useState("");
	const [filteredCategory, setFilteredCategory] = useState<ICommunityDebateCategory | undefined>(undefined);
	const [loadingDebates, setLoadingDebates] = useState(true);
	const [loadingAllDebates, setLoadingAllDebates] = useState(true);
	const [featuredDebate, setFeaturedDebate] = useState<any>({});
	const [allDebates, setAllDebates] = useState<ICommunityDebateCategory[]>([]);
	const { slug } = useParams<{ slug: string }>();
	const categoryContainerRef = useRef<HTMLDivElement>(null);

	const getFeaturedDebate = useCallback(async () => {
		const featuredService = new ComunityFeaturedService();
		const response = await featuredService.list();

		console.log(response)

		const normDebDest = {
			id: response.id,
			title: response.titulo,
			comment: {
				author: {
					id: 1,
					profileId: "1",
					isPlus: true,
					isFounder: false,
					address: `São Pedro da Aldeia/RJ`,
					profession: "Monitor",
					isMonitor: true,
					name: response.criado_por.nome, // mudar depoiss
					imageUrl: response.criado_por.imagem_url,
				},
				text: trucateString(response.descricao, 200),
				date: response.data_publicacao,
				id: response.id,
				isNew: false,
				isLiked: false,
				likeCount: 0,

				repliedComment: null,
			},
			reactionsCount: 10,
			studentsCount: response.comentarios__criado_por__count,
			commentsCount: response.comentarios__count,
			isFavorite: false,
			isNotificationOn: false,
		};

		// setFeaturedDebate(normDebDest);
	}, []);

	const getAllDebates = useCallback(async () => {
		setLoadingAllDebates(true);
		const allDebatesService = new ComunityAllService();
		const response = await allDebatesService.list();

		setAllDebates(response);
		setLoadingAllDebates(false);
		getDebates();
		//console.log("ALL DEBATES => ", response);
	}, []);

	const isCategoryListOverflowing = (element: any) => {
		if (element.scrollHeight > element.clientHeight) {
			//console.log("vazou vertical");
		}
		if (element.scrollWidth > element.clientWidth) {
			//console.log("vazou pros lado");
		}
	};

	const getDebates = useCallback(async () => {
		setLoadingDebates(true);
		const comunityService = new ComunityService();

		const response: ICommunityDebateCategory[] = await comunityService.list();

		setComunityDebates(response);
		setLoadingDebates(false);
		//blerp
		isCategoryListOverflowing(categoryContainerRef.current);

		//setDebatesCount(response.length);
	}, []);

	const trucateString = useCallback((txt: string, charCount: number) => {
		if (txt && txt.length > charCount) {
			return `${txt.substring(0, charCount)}[...]`;
		}
		return txt;
	}, []);

	const toggleCategory = useCallback(
		(category?: string) => {
			//alert(category);
			if (category) {
				setCategoryFilter(category);
				const filter = comunityDebates.find((debateCategory) => {
					return slugify(debateCategory.nome) === category;
				});

				//console.log("CATEGORIA FILTRADA", filter);

				if (filter) {
					setFilteredCategory(filter);
				} else {
					setFilteredCategory(undefined);
				}
			} else {
				setCategoryFilter("");
				setFilteredCategory(undefined);
			}
		},
		[categoryFilter, filteredCategory, comunityDebates]
	);

	const slugify = (str: string) => {
		str = str.replace(/^\s+|\s+$/g, "");
		str = str.toLowerCase();
		var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
		var to = "aaaaeeeeiiiioooouuuunc------";
		for (var i = 0, l = from.length; i < l; i++) {
			str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
		}

		str = str
			.replace(/[^a-z0-9 -]/g, "")
			.replace(/\s+/g, "-")
			.replace(/-+/g, "-");

		return str;
	};

	useEffect(() => {
		if (slug) {
			toggleCategory(slug);
		}
	}, [comunityDebates]);

	useEffect(() => {
		window.scrollTo(0, 0);
		getFeaturedDebate();
		getAllDebates();
	}, []);

	return (
		<PageContainer showBreadcrumb={false} showSearch={false} pageTitle='Notícias' plusExclusive>
			{!slug && (
				<>
					<FilterTitle>Categorias:</FilterTitle>
					<CategoriesContainer ref={categoryContainerRef}>
						{comunityDebates ? (
							<>
								{comunityDebates.map((cat) => (
									<>
										{slugify(cat.nome) === categoryFilter ? (
											<Tag
												onClick={() => {
													toggleCategory();
												}}
												title='Remover Filtro'
												className='active'>
												{cat.nome}
											</Tag>
										) : (
											<Tag
												onClick={() => {
													toggleCategory(slugify(cat.nome));
												}}
												title={cat.nome}>
												{cat.nome}
											</Tag>
										)}
									</>
								))}
							</>
						) : (
							"Ocorreu um erro ao carregar as categorias"
						)}
					</CategoriesContainer>
				</>
			)}
			<GreyContainer>
				<InnerContentContainer>
					<HomePageFeatureSlider location="NOTICIAS" />
					{filteredCategory ? (
						<>
							{loadingDebates ? (
								<div
									style={{
										width: "100%",
										minHeight: "50px",
										padding: "30px 0",
										textAlign: "center",
									}}>
									<img className='loadingGif' src={loadingGif} alt='loading' />
								</div>
							) : (
								<div>
									<Box display='flex' alignItems='center' height='70px'>
										<Typography variant='title' lineHeight='1.25rem'>
											{filteredCategory.nome}
										</Typography>
										<Typography margin='0 0 0 10px' variant='title' size='1.25rem' primary>
											{filteredCategory.topicos.length}
										</Typography>
									</Box>
									{filteredCategory.topicos.map((debate) => {
										let normalizedDebate = {
											id: debate.id,
											title: debate.titulo,
											comment: {
												author: {
													id: 1,
													profileId: "1",
													isPlus: true,
													isFounder: false,
													address: `São Pedro da Aldeia/RJ`,
													profession: "Monitor",
													isMonitor: debate.is_mentor,
													isTutor: debate.is_professor,
													isProfessor: debate.is_tutor,
													name: debate.criado_por.nome,
													imageUrl: debate.criado_por.imagem_url,
												},
												text: trucateString(debate.descricao, 200),
												date: debate.data_publicacao,
												id: debate.id,
												isNew: false,
												isLiked: false,
												likeCount: 0,
												repliedComment: null,
											},
											reactionsCount: 10,
											studentsCount: debate.comentarios__criado_por__count,
											commentsCount: debate.comentarios__count,
											isFavorite: false,
											isNotificationOn: false,
										};
										return <DebateItem onAction={() => { }} key={debate.id} link showComments={false} debateItem={normalizedDebate} type={"comunity"} />;
									})}
								</div>
							)}
						</>
					) : (
						<>
							{!slug && (
								<>
									{/* {featuredDebate.id && (
										<div>
											<Box display='flex' alignItems='center' height='70px'>
												<Typography variant='title' lineHeight='1.25rem'>
													<FaFireAlt size={22} color='#F2502C' style={{ marginRight: "10px" }} />
													Notícia em Destaque
												</Typography>
											</Box>
											<DebateItem onAction={() => {}} link showComments={false} debateItem={featuredDebate} type={"comunity"} />
										</div>
									)} */}

									<Box display='flex' alignItems='center' height='70px'>
										<Typography variant='title' lineHeight='1.25rem'>
											<IoIosChatbubbles size={22} color='#013A7B' style={{ marginRight: "10px" }} />
											Notícias Recentes
										</Typography>
									</Box>
								</>
							)}
							{loadingAllDebates ? (
								<div
									style={{
										width: "100%",
										minHeight: "50px",
										padding: "30px 0",
										textAlign: "center",
									}}>
									<img className='loadingGif' src={loadingGif} alt='loading' />
								</div>
							) : (
								<>
									{allDebates?.map((debate: any) => {
										let normalizedDebate = {
											id: debate.id,
											title: debate.titulo,
											comment: {
												author: {
													id: 1,
													profileId: "1",
													isPlus: true,
													isFounder: false,
													address: `São Pedro da Aldeia/RJ`,
													profession: "Monitor",
													isMonitor: debate.criado_por.is_mentor,
													isTutor: debate.criado_por.is_professor,
													isProfessor: debate.criado_por.is_tutor,
													name: debate.criado_por.nome,
													imageUrl: debate.criado_por.imagem_url,
												},
												text: trucateString(debate.descricao, 200),
												date: debate.data_publicacao,
												id: debate.id,
												isNew: false,
												isLiked: false,
												likeCount: 0,

												repliedComment: null,
											},
											reactionsCount: 10,
											studentsCount: debate.comentarios__criado_por__count,
											commentsCount: debate.comentarios__count,
											isFavorite: false,
											isNotificationOn: false,
										};
										return <DebateItem onAction={() => { }} key={debate.id} link showComments={false} debateItem={normalizedDebate} type={"comunity"} />;
									})}
								</>
							)}

							{/* {comunityDebates?.map((category: ICommunityDebateCategory) => (
								<div key={category.id}>
									<Box display='flex' alignItems='center' height='70px'>
										<Typography variant='title' lineHeight='1.25rem'>
											{category.nome}
										</Typography>
										<Typography margin='0 0 0 10px' variant='title' size='1.25rem' primary>
											{category.topicos.length}
										</Typography>
									</Box>
									{category.topicos.map((debate) => {
										let normalizedDebate = {
											id: debate.id,
											title: debate.titulo,
											comment: {
												author: {
													id: 1,
													profileId: "1",
													isPlus: true,
													isFounder: false,
													address: `São Pedro da Aldeia/RJ`,
													profession: "Monitor",
													isMonitor: true,
													name: debate.criado_por.nome,
													imageUrl: debate.criado_por.imagem_url,
												},
												text: trucateString(debate.descricao, 200),
												date: debate.data_publicacao,
												id: debate.id,
												isNew: false,
												isLiked: false,
												likeCount: 0,

												repliedComment: null,
											},
											reactionsCount: 10,
											studentsCount: debate.comentarios__criado_por__count,
											commentsCount: debate.comentarios__count,
											isFavorite: false,
											isNotificationOn: false,
										};
										return <DebateItem key={debate.id} link showComments={false} debateItem={normalizedDebate} type={"comunity"} />;
									})}
								</div>
							))} */}
						</>
					)}
				</InnerContentContainer>
			</GreyContainer>
		</PageContainer>
	);
};

export { Comunity };
