import styled, { keyframes, css } from "styled-components";
import { Props } from ".";
import media from "../../styles/media";

const spin = keyframes`
    100% {
        transform:rotate(360deg); 
    } 
`;

const spinnerSize = "20px";

const FavoriteButtonContainer = styled.div`
	.spin {
		width: ${spinnerSize};
		height: ${spinnerSize};
		animation-name: ${spin};
		animation-duration: 1s;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
	}
`;

const FullButton = styled.button`
	background: none;
	cursor: pointer;
	display: flex;
	border-radius: 6px;
	border: 1px solid ${(props) => props.theme.color.primary};
	padding: 15px;
	justify-content: center;
	align-items: center;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0);
	transition: 0.3s ease;

	outline: none;
	${media.lessThan("medium")`
		padding: 10px 10px 12px 10px;
	`};
	span {
		white-space: nowrap;
		display: block;
		padding: 4px 0 0;
		margin: 0 6px 0 0;
		color: ${(props) => props.theme.color.primary};
		&.spin {
			padding: 0 !important;
		}
	}
	svg {
		color: ${(props) => props.theme.color.primary};
	}
	&:hover {
		border: 1px solid ${(props) => props.theme.color.primary};
		box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
		svg {
			color: ${(props) => props.theme.color.primary};
		}
		span {
			color: ${(props) => props.theme.color.primary};
		}
	}
`;

const SmallButton = styled.div<{
	rounded?: "roundClear" | "roundDark";
}>`
	width: ${spinnerSize};
	height: ${spinnerSize};
	button {
		background: none;
		width: ${spinnerSize};
		height: ${spinnerSize};
		cursor: pointer;
	}
	${(props) =>
		props.rounded === "roundClear"
			? css`
					background-color: white;
					svg {
						fill: ${(props) => props.theme.color.primary};
					}
					&:hover {
						background-color: ${(props) => props.theme.color.primary};
						svg {
							fill: white;
						}
					}
			  `
			: props.rounded === "roundDark"
			? css`
					background-color: ${(props) => props.theme.color.primary};
					svg {
						fill: white;
					}
					&:hover {
						background-color: white;
						border-color: ${(props) => props.theme.color.primary};
						svg {
							fill: ${(props) => props.theme.color.primary};
						}
					}
			  `
			: css``}
	${(props) =>
		props.rounded &&
		css`
			border: 2px solid transparent;
			padding: 18px !important;
			border-radius: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
		`}
	cursor: pointer;
`;

export { FavoriteButtonContainer, SmallButton, FullButton };
