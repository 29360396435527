import styled from "styled-components";
import media from "../../../../../styles/media";

const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 10px;
	width: 350px;
	transition: background-color 0.5s ease;

	${media.lessThan("medium")`
		width: 100%;
    `}
`;

const CardTitle = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	.title {
		font-weight: 700;
		font-size: 1rem;
		line-height: 1.3;
	}
	h2 {
		max-width: 90%;
	}
`;
const CardHeaderSmallTitle = styled.div`
	font-weight: 700;
	font-size: 0.625rem;
	text-transform: uppercase;
	line-height: 2;
	color: ${(props) => props.theme.color.primary};
`;

const CardHeader = styled.div`
	display: flex;
	justify-content: space-between;
`;

const CardFooter = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
`;

const CardSubtitle = styled.div`
	font-size: 0.75rem;
	line-height: 1.7;
	color: rgba(0, 0, 0, 0.5);
	.optional {
		display: block;
		text-transform: uppercase;
	}
`;

const CardHeaderHelpText = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	color: #000;
	position: relative;
	.headerHelper {
		font-size: 0.75rem;
		line-height: 1.7;
		margin-right: 38px;
	}
	.iconWrapper {
		position: absolute;
		right: 0;
	}
`;

const CardFooterText = styled.div`
	margin-top: 15px;
	font-weight: 400;
	font-size: 0.75rem;
	line-height: 19px;
	color: rgba(0, 0, 0, 0.5);
`;

export { CardWrapper, CardTitle, CardFooter, CardSubtitle, CardHeader, CardHeaderHelpText, CardHeaderSmallTitle, CardFooterText };
