import React from "react";
import {
  LoadingPlaceholderContainer,
  Placeholder,
} from "./LoadingPlaceholderStyled";

export interface Props {
  variant: "text" | "onelinetext" | "image" | "roundedImage";
  linesNumber?: number;
  width?: string;
  height?: string;
}

const LoadingPlaceholderWrapper: React.FC<Props> = ({
  variant,
  linesNumber,
  width,
  height,
}: Props) => {
  const PlaceholderComponent = Placeholder[variant];

  const createLines = () => {
    const count = linesNumber || 10;
    const lines = [];
    for (let i = 0; i < count; i++) {
      lines.push(<div key={i} className="text-input__loading--line" />);
    }
    return lines;
  };
  return (
    <LoadingPlaceholderContainer width={width} height={height}>
      {variant === "text" && (
        <PlaceholderComponent>{createLines()}</PlaceholderComponent>
      )}
      {variant === "onelinetext" && (
        <PlaceholderComponent>
          <div className="text-input__loading--line" />
        </PlaceholderComponent>
      )}
      {variant === "image" && (
        <PlaceholderComponent>
          <div className="text-input__loading--line" />
        </PlaceholderComponent>
      )}
      {variant === "roundedImage" && (
        <PlaceholderComponent>
          <div className="text-input__loading--line" />
        </PlaceholderComponent>
      )}
    </LoadingPlaceholderContainer>
  );
};

LoadingPlaceholderWrapper.defaultProps = {
  linesNumber: 10,
};

export { LoadingPlaceholderWrapper as LoadingPlaceholder };
