import styled from "styled-components";
import { darken } from "polished";
import { Props } from "./index";

const NotificationContainer = styled.div<Props>`
	display: flex;
	max-width: 569px;
	background: ${(props) => (props.notification.type === "monitor" ? darken(0.03, props.theme.color.primary) : "#FFF")};
	color: ${(props) => (props.notification.type === "monitor" ? "#FFF" : "#000")};
	padding: 20px 30px;
	border-bottom: 1px solid #ededed;
	.imgAvatar {
		width: 155px;
		display: flex;
		justify-content: center;
	}
`;
const NotificationInfoWrapper = styled.div<Props>`
	margin-left: 20px;
`;

const NotificationDate = styled.div<Props>`
	color: ${(props) => (props.notification.type === "monitor" ? "#FFF" : "#B0B0B0")};
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	padding: 5px 0 7px 0;
`;
const NotificationText = styled.div`
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
`;
export { NotificationContainer, NotificationDate, NotificationText, NotificationInfoWrapper };
