import styled from "styled-components";

const InfosPjContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 1.5rem;

	.loading {
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 10px;
	}

	.configVenda {
		width: 45%;
		background-color: white;
		border: 1px solid #f2f2f2;
		padding: 20px;
		border-radius: 10px;
		h3 {
			color: ${(props) => props.theme.color.primary};
		}
		small {
			opacity: 0.7;
		}
	}
	.btnWrapper,
	.modalBtnWrapper {
		display: flex;
		justify-content: space-between;
		margin-top: 1.5rem;
	}
	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
	}
	.modalBody {
		width: 90%;
		max-width: 350px;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-height: 90%;
		overflow-y: auto;
		background-color: white;
		box-shadow: 0 0 25px 5px rgba(0, 0, 0, 0.6);
		padding: 20px;
		border-radius: 5px;
		&.modalBodyShowUsers {
			max-width: 450px;
		}
		h2 {
			color: ${(props) => props.theme.color.primary};
			margin-bottom: 1rem;
		}
	}

	.liberacao {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #f2f2f2;
		padding: 10px 0;
		.actionsLib {
			width: 20px;
			button {
				background: transparent;
				cursor: pointer;
				svg {
					cursor: pointer;
				}
				&:hover svg {
					fill: ${(props) => props.theme.color.primary};
				}
			}
		}
		.infosLib {
			flex: 1;
			opacity: 0.8;
			span {
				font-weight: 600;
			}
			small {
				font-size: 0.8em;
			}
		}
	}
`;
export { InfosPjContainer };
