import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Typography } from "../../Typography";

interface Props {
	name: string;
	label?: string;
	type?: string;
}

type RichTextInputProps = JSX.IntrinsicElements["input"] & Props;

const RichTextInput: React.FC<RichTextInputProps> = ({ name, label, type, ...rest }: RichTextInputProps) => {
	const inputRef = useRef<any>(null);
	// const [reactQuillRef, setReactQuillRef] = useState<any | null>(null)
	const { fieldName, defaultValue, registerField, error } = useField(name);

	useEffect(() => {
		registerField({
			name: fieldName,
			ref: inputRef.current,
			getValue() {
				const editor = inputRef?.current?.getEditor();
				const unprivilegedEditor = inputRef?.current?.makeUnprivilegedEditor(editor);
				return unprivilegedEditor.getHTML();
			},
		});
	}, [defaultValue, fieldName, registerField]);

	return (
		<>
			{label && (
				<Typography variant='title' size='1rem' margin='0 0 -15px 0'>
					{label}
				</Typography>
			)}
			<ReactQuill value={defaultValue || ""} ref={inputRef} />
			<br />
		</>
	);
};
RichTextInput.defaultProps = {
	type: "text",
};
export { RichTextInput };
