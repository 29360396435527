import { IAddUserData } from "../types/pj";
import api from "./api";
import BaseService from "./base.service";

export default class PJService extends BaseService {
	constructor() {
		super("vendas-pj");
	}

	async liberarAcesso(data: IAddUserData) {
		try {
			const response = await api.post(`${this.resource}/liberar-acesso/`, data);

			return response.data;
		} catch (err) {
			console.error(`erro ao liberar acesso ${this.resource} :`, err);

			throw new Error("Erro ao liberar acesso");
		}
	}

	async removerAcesso(id: number) {
		try {
			const response = await api.delete(`${this.resource}/remover-acesso/${id}/`);

			return response.data;
		} catch (err) {
			console.error(`erro ao liberar acesso ${this.resource} :`, err);

			throw new Error("Erro ao liberar acesso");
		}
	}

	async meusCreditos() {
		try {
			const response = await api.get(`${this.resource}/meus-creditos/`);

			return response.data;
		} catch (err) {
			console.error(`Erro ao buscar créditos ${this.resource} :`, err);

			throw new Error("Erro ao buscar créditos");
		}
	}

	async minhasLiberacoes() {
		try {
			const response = await api.get(`${this.resource}/minhas-liberacoes/`);

			return response.data;
		} catch (err) {
			console.error(`Erro ao buscar créditos ${this.resource} :`, err);

			throw new Error("Erro ao buscar créditos");
		}
	}
}
