function daysInThisMonth() { // retorna quantidade de dias no mês
    var now = new Date();
    return new Date(now.getFullYear(), now.getMonth()+1, 0).getDate();
}

function rngData(min:number, max:number) { // retorna um aleatório entre o min e max
    return Math.round(Math.random() * (max - min) + min);
}

export let randomDataset = {
    labels: [] as string[],
    data: [] as number[]
}

export function randomizeDataset() {
    for(let i:number = 0; i < daysInThisMonth(); i++) {
        let day = i + 1
        let disMes = new Date().getMonth() + 1;
        
        let numMes, diaMes:string;
        

        disMes < 10 ? numMes = '0' + disMes : numMes = disMes.toString()
        day < 10 ? diaMes = '0' + day : diaMes = day.toString()

        randomDataset.labels[i] = diaMes + '/' + numMes
        randomDataset.data[i] = rngData(10,65)
    }
}

randomizeDataset();