import React, { useCallback, useEffect, useState } from "react";
import { HomePageFeatureSliderContainer, ResponsiveImg, BannerInfoArea } from "./HomePageFeatureSliderStyled";
import Swiper, { Pagination, Thumbs, Autoplay } from "swiper";
import { Swiper as Slider, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { isMobile } from "../../../../isMobile";
// import { ContentLoadingError } from "../../../../components/ContentLoadingError";

import BannersService from "../../../../services/banners.service";
import { Typography } from "../../..";

export interface Props {
	location: "ARTIGOS" | "CURSOS" | "HOME" | "MATERIAIS" | "SERIES" | "NOTICIAS"
}

interface IBanner {
	id: number;
	imagem: string;
	imagem_mobile: string;
	link_botao: string | null;
	subtitulo: string;
	titulo: string;
	exibir_textos: boolean;
}

Swiper.use([Thumbs, Pagination, Autoplay]);

const HomePageFeatureSliderWrapper: React.FC<Props> = ({ ...props }: Props) => {
	const [thumbsSwiper, setThumbsSwiper] = useState<Swiper | undefined>(undefined);
	const [banners, setBanners] = useState<IBanner[]>([]);

	const getBanners = useCallback(async () => {
		const bannersService = new BannersService();
		const response = await bannersService.list();

		setBanners(response.filter((banner: any) => banner.locais.includes(props.location)));
	}, []);

	useEffect(() => {
		getBanners();
	}, []);

	return (
		<HomePageFeatureSliderContainer {...props}>
			{banners.length ? ( // isso só ta aqui pra garantir que tenham banners e pro map das thumbs funcionar certinho
				<>
					<div className='featuredTitle' style={{ "width": "100%" }}>
						<Typography variant='title' uppercase color='#014491' margin='20px 0 10px'>
							Em Destaque
						</Typography>
					</div>
					<div className='banners'>
						<Slider thumbs={{ swiper: thumbsSwiper }} height={100} pagination loop autoplay={{ disableOnInteraction: false, delay: 7000 }}>
							{banners.map((banner) => (
								<SwiperSlide key={banner.id}>
									{banner.link_botao ? (
										<a href={banner.link_botao} title={banner.titulo}>
											<ResponsiveImg>
												<img src={isMobile.any() ? banner.imagem_mobile : banner.imagem} alt={banner.titulo} />
											</ResponsiveImg>
										</a>
									) : (
										<ResponsiveImg>
											<img src={isMobile.any() ? banner.imagem_mobile : banner.imagem} alt={banner.titulo} />
										</ResponsiveImg>
									)}
									{banner.exibir_textos && (
										<>
											{banner.link_botao ? (
												<>
													<a href={banner.link_botao} title={banner.titulo}>
														<BannerInfoArea>
															<div className='infos'>
																<big className='bannerTitle'>{banner.titulo}</big>
																{banner.subtitulo && <small className='bannerSubTitle'>{banner.subtitulo}</small>}
															</div>
														</BannerInfoArea>
													</a>
												</>
											) : (
												<BannerInfoArea>
													<div className='infos'>
														<big className='bannerTitle'>{banner.titulo}</big>
														{banner.subtitulo && <small className='bannerSubTitle'>{banner.subtitulo}</small>}
													</div>
												</BannerInfoArea>
											)}
										</>
									)}
								</SwiperSlide>
							))}
						</Slider>
					</div>
					<div className='thumbs'>
						<Slider onSwiper={setThumbsSwiper} watchSlidesVisibility watchSlidesProgress direction={isMobile.any() ? "horizontal" : "vertical"} slidesPerView={4} height={100}>
							{banners.map((banner) => (
								<SwiperSlide key={banner.id}>
									<ResponsiveImg>
										<img src={isMobile.any() ? banner.imagem_mobile : banner.imagem} alt={banner.titulo} />
									</ResponsiveImg>
								</SwiperSlide>
							))}
						</Slider>
					</div>
				</>
			) : (
				""
			)}
		</HomePageFeatureSliderContainer>
	);
};

export { HomePageFeatureSliderWrapper as HomePageFeatureSlider };
