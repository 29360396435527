import React, { useEffect, useState, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";

import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import AccountCircle from "@material-ui/icons/AccountCircle";

import loadingGif from "../../assets/images/loading.gif";

import { CircularAvatarPhoto } from "../../components/elements";

import { ResetPasswordContainer } from "./ResetPasswordStyled";

import ResetPasswordService from "../../services/resetPassword.service";

export interface Props {
	backgroundColor?: string;
	backgroundURL?: string;
}

const ResetPassword: React.FC<Props> = ({ ...props }) => {
	const [error, setError] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [successMsg, setSuccessMsg] = useState("");
	const history = useHistory();
	const [userImg, setuserImg] = useState("");
	const [emailInput, setEmailInput] = useState("");
	const [loading, setLoading] = useState(false);

	const resetPassword = useCallback(async () => {
		if (emailInput) {
			try {
				const resetService = new ResetPasswordService();
				const response = await resetService.reset(emailInput);

				//console.log("RESPONSE DO RESET ==> ", response);

				if (response.error) {
					throw new Error(response.data.mensagem);
				} else {
					setLoading(true);
					setEmailInput("");
					setSuccessMsg(`${response.mensagem} Aguarde, você será redirecionado ao Login em 5 segundos`);
					setTimeout(() => {
						history.push("/");
					}, 5000);
				}
			} catch (error) {
				console.error("ERRO NA RECUPERAÇÃO DE SENHA ==>", error);
				setError(true);
				setErrorMsg(`Houve um erro na recuperação de senha.`);
			}
		} else {
			setError(true);
			setErrorMsg("Informe o e-mail do usuário");
		}
	}, [emailInput, loading]);

	useEffect(() => {
		setLoading(false);
		setuserImg(localStorage.getItem("userImg") || "");
	}, []);
	return (
		<ResetPasswordContainer {...props}>
			<div className='innerLoginWrapper'>
				<CircularAvatarPhoto avatarImg={userImg} avatarName='Nosso Mundo Azul' size='120px' />
				<h1>Centro Integrado de Aprendizagem em Autismo</h1>
				<p className='highlight'>Recuperação de senha</p>

				<form action='#' onSubmit={resetPassword}>
					{successMsg && <div className='successMsg statusMsg'>{successMsg}</div>}
					{errorMsg && <div className='errorMsg statusMsg'>{errorMsg}</div>}

					<FormControl error={error} fullWidth>
						<InputLabel htmlFor='email'>E-mail de Acesso</InputLabel>
						<Input
							fullWidth
							id='standard-adornment-password'
							type='email'
							value={emailInput}
							required
							onChange={(e) => setEmailInput(e.target.value)}
							endAdornment={
								<InputAdornment position='end'>
									<AccountCircle />
								</InputAdornment>
							}
						/>
					</FormControl>

					<Link style={{ textDecoration: "none" }} to='/'>
						Voltar ao Login
					</Link>
					{loading ? (
						<>
							<div
								style={{
									width: "100%",
									minHeight: "50px",
									padding: "10px 0",
									textAlign: "center",
								}}>
								<img className='loadingGif' src={loadingGif} alt='loading' />
							</div>
						</>
					) : (
						<>
							<div className='buttonWrapper'>
								<button type='submit'>Enviar</button>
							</div>
						</>
					)}
				</form>
			</div>
		</ResetPasswordContainer>
	);
};

export { ResetPassword };
