/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import api from "./api";
import BaseService from "./base.service";

export interface IUserSeal {
	badge: string;
	codigo: string;
	created: string;
	curso: string;
	id: number;
	is_vencido: boolean;
	selo_url: string;
	uniqueidentifier: string;
	validade: number;
	vencimento: string | null;
	//tem um obj usuário aqui q eu não trouxe pq acho q não é necessário. Talvez seja no futuro.
}

export interface IGenericSeal {
	nome: string;
	selo: string;
	tipo: string;
}

export interface IStudent {
	id: number;
	userId: number;
	name: string;
	avatar: string;
	address: {
		number: string;
		complement: string;
		district: string;
		city: string;
		state: string;
		postalCode: string;
	};
	cellphone?: string;
	cpf: string;
	email: string;
	showCellphone: boolean;
	showPresentation: boolean;
	showEmail: boolean;
	showAddress: boolean;
	showPhone: boolean;
	tenantId: string;
	recorte: string;
	check: [];
	selos: IUserSeal[];
	selos_genericos: IGenericSeal[];
}

interface ICursoConcluido {
	id: number;
	curso: string;
	usuario: number;
	created: string;
}

interface ICertificadoEmitido {
	curso: string;
	id: number;
	uniqueidentifier: string;
	usuario: number;
}
export interface IStudentApiResponse {
	id: number;
	nome: string;
	apresentacao: string;
	profissao: string;
	bairro: string;
	data_nascimento: string;
	celular?: string;
	cep: string;
	check: [];
	cidade: string;
	complemento: string;
	cpf: string;
	email: string;
	exibir_celular: boolean;
	exibir_dados_profissionais: boolean;
	exibir_email: boolean;
	exibir_endereco: boolean;
	exibir_telefone: boolean;
	numero: string;
	tenant_id: string;
	uf: string;
	usuario_id: number;
	foto: string;
	recorte: string;
	cursos_concluidos: ICursoConcluido[];
	certificados: ICertificadoEmitido[];

	lng: number;
	ltd: number;
	logradouro: string;

	notifica_artigo: boolean;
	notifica_curso: boolean;
	notifica_material: boolean;
	notifica_multimidia: boolean;
	notifica_posts: boolean;
	notifica_posts_interesse: boolean;
	is_familiar: boolean;
	is_profissional: boolean;
	is_publico: boolean;

	grupos: string[];
	selos: IUserSeal[];
	selos_genericos: IGenericSeal[];
}

export default class StudenService extends BaseService {
	constructor() {
		super("usuarios/meu-perfil");
	}

	async getAccessTimers() {
		const finalResponse: any[] = [];

		const subscriptions = await api.get("usuarios/minha-assinatura/");
		const courses = await api.get("usuarios/minhas-compras/");

		const rawResponse = await Promise.allSettled([subscriptions, courses]);

		const response = rawResponse.filter((res) => res.status === "fulfilled") as PromiseFulfilledResult<any>[];

		finalResponse.push([response[0].value.data, ...response[1].value.data]);

		return finalResponse.flat().filter((timer: any) => timer.tempo_restante > 0);
	}

	async updateAvatar(data: any) {
		// console.log("FOTO FROM FORM DATA", data.get("foto"));
		for (var pair of data.entries()) {
			console.log(pair[0] + ", " + pair[1]);
		}
		// console.log("RESOURCE => ", `${this.resource}/`);
		try {
			// const response = await api.put(`${this.resource}/`, data, multipartHeaders);
			const response = await api({
				method: "put",
				url: `${this.resource}/`,
				data: data,
				headers: { "Content-Type": undefined },
			});

			return response.data;
		} catch (err) {
			console.error(`[BS] Erro ao atualizar ${this.resource} :`, err);
			// console.log(`[BS] Erro ao atualizar ${this.resource} :`, err.response);

			return {
				error: true,
			};
		}
	}

	formatDetailData(student: IStudentApiResponse): IStudent {
		return {
			id: student.id,
			userId: student.usuario_id,
			name: student.nome,
			avatar: student.foto || "",
			address: {
				number: student.numero || "",
				complement: student.complemento || "N/I",
				district: student.bairro || "N/I",
				city: student.cidade || "N/I",
				state: student.uf || "N/I",
				postalCode: student.cep || "N/I",
			},
			cellphone: student.celular || "N/I",
			cpf: student.cpf || "N/I",
			email: student.email || "N/I",
			showCellphone: student.exibir_celular,
			showPresentation: student.exibir_dados_profissionais,
			showEmail: student.exibir_email,
			showAddress: student.exibir_endereco,
			showPhone: student.exibir_telefone,
			tenantId: student.tenant_id,
			recorte: student.recorte || "N/I",
			check: [],
			selos: student.selos,
			selos_genericos: student.selos_genericos,
		};
	}
}
