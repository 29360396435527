import styled from "styled-components";

const BreadcrumbContainer = styled.div`
	margin-left: 20px;
	.MuiBreadcrumbs-ol {
		min-height: 20px;
	}
	.MuiBreadcrumbs-li {
		color: ${(props) => props.theme.color.primary};
		font-size: 0.625rem;
		font-weight: 400;
		text-transform: uppercase;
		a {
			color: ${(props) => props.theme.color.primary};
			text-decoration: none;
		}
		p {
			font-size: 0.625rem;
			font-weight: 400;
			color: ${(props) => props.theme.color.primary};
		}
	}
	.MuiBreadcrumbs-separator {
		margin: 0;
		color: ${(props) => props.theme.color.primary};
	}
`;
export { BreadcrumbContainer };
