import React, { useState, useEffect, useCallback } from "react";
import { Box } from "@material-ui/core";
import { useParams } from "react-router-dom";

//import { IoIosHeart, IoIosHeartEmpty, IoMdNotificationsOutline, IoMdNotifications } from "react-icons/io";
import { Typography, PageContainer, GreyContainer, InnerContentContainer, FavoriteButton } from "../../components";
import { Button } from "../../components/elements";
import { Comment, OtherDebateCard } from "../../components/features/Debate";
//import { IconWrapper, IconsGroup } from "../../components/elements/Icons";
import {
	//comunityDebates,
	otherDebatesData,
} from "../../mockData/debateDataMock";

import { DebateComment as DebateCommentType, DebateItem as DebateItemType, OtherDebateType } from "../../types/debate";

import { ComunityDetailContainer, CommentWrapper, LikeWrapper } from "./ComunityDetailStyled";
import { LikeButton } from "../ArticleDetail/ArticleDetailStyled";
import ComunityService from "../../services/comunity.service";
import CommentService from "../../services/comunityComments.service";

import { IoMdThumbsUp } from "react-icons/io";

import { isMobile } from "../../isMobile";

import formatComments from "../../helpers/formatDebateItem";
import { useUserContext } from "../../context/user/AuthContext";
import { ReplyDebate } from "../../components/features/Debate/ReplyDebate";
import { HiOutlineReply } from "react-icons/hi";

const ComunityDetail: React.FC = () => {
	const { userData } = useUserContext();
	const commentsToAdd = 3;
	const { comunityId } = useParams<{ comunityId: string }>();

	const [debate, setDebate] = useState<DebateItemType | undefined>(undefined);
	const [debateComments, setDebateComments] = useState<DebateCommentType[] | undefined>(undefined);
	const [commentsToShow, setCommentsToShow] = useState(commentsToAdd);
	const [isLiked, setIsLiked] = useState(true);
	const [likesCount, setLikesCount] = useState(0);
	// const [isFavorite, setIsFavorite] = useState(false);
	// const [isNotificationOn, setIsNotificationOn] = useState(false);

	// const handleToogleIsFavorite = () => {
	//   setIsFavorite(!isFavorite);
	// };

	// const handleToogleIsNotificationOn = () => {
	//   setIsNotificationOn(!isNotificationOn);
	// };

	const handleViewMoreComments = () => {
		setCommentsToShow(commentsToShow + commentsToAdd);
	};

	const getComments = useCallback(async () => {
		const commentsService = new CommentService(comunityId);
		const response = await commentsService.list(true);
		//console.log("COMMENTS FROM DETAILS ==> ", response);
		const normalizedComments = formatComments(response.results);

		//console.log("NORM COM", normalizedComments);

		setDebateComments(normalizedComments);
	}, [comunityId]);

	const getDebateDetails = useCallback(async () => {
		const comunityService = new ComunityService();

		const response = await comunityService.get_by_id(comunityId);

		const normalizedDebate = {
			...response,
			title: response.titulo,
			comment: {
				author: {
					id: response.criado_por.id,
					name: response.criado_por.nome,
					isMonitor: response.is_mentor,
					isTutor: response.is_professor,
					isProfessor: response.is_tutor,
					imageUrl: response.criado_por.imagem_url,
				},
				text: response.descricao,
				date: response.data_publicacao,
			},
			studentsCount: 10,
			commentsCount: 10,
			isFavorite: false,
			isNotificationOn: false,
			likesCount: response.curtidas__count,
		};

		//console.log("LIKES COUNT GET DETAILS ==> ", response.curtidas__count);

		setDebate(normalizedDebate);
		setIsLiked(response.curtido);
		setLikesCount(response.curtidas__count);
		// setIsFavorite(normalizedDebate.isFavorite);
		// setIsNotificationOn(normalizedDebate.isNotificationOn);
	}, [comunityId]);

	const likeTopic = useCallback(
		async (id) => {
			const communityService = new ComunityService();
			//const response =
			await communityService.like(id);
			//console.log("CURTIDA TÓPICO ==> ", response);

			if (isLiked) {
				setLikesCount(likesCount - 1);
			} else {
				setLikesCount(likesCount + 1);
			}

			setIsLiked(!isLiked);
		},
		[isLiked, likesCount, comunityId]
	);
	useEffect(() => {
		window.scrollTo(0, 0);
		getDebateDetails();
		getComments();
	}, [comunityId]);

	const pageTitle = () => (
		<>
			{debate && (
				<Box display='flex' alignItems='center' justifyContent='space-between' padding='0 20px 0 20px'>
					<Typography variant='title'>{debate.title}</Typography>
					{/* <Box display="flex" alignItems="center" marginTop="-20px">
					<ReactionToolbar
					reactions={debate.usersReactions}
					reactionsCount={debate.reactionsCount}
					usersCount={debate.studentsCount}
					commentsCount={debate.commentsCount}
					/>
					<IconsGroup marginLeft="8px" marginContainer="0 0 0 5px">
					{isFavorite
						? <IconWrapper onClick={() => handleToogleIsFavorite()} icon={<IoIosHeart size="20px" />} filled={false} primaryColor />
						: <IconWrapper onClick={() => handleToogleIsFavorite()} icon={<IoIosHeartEmpty size="20px" />} filled={false} primaryColor />}
					{isNotificationOn
						? <IconWrapper onClick={() => handleToogleIsNotificationOn()} icon={<IoMdNotifications size="20px" />} filled={false} primaryColor />
						: <IconWrapper onClick={() => handleToogleIsNotificationOn()} icon={<IoMdNotificationsOutline size="20px" />} filled={false} primaryColor />}
					</IconsGroup>
				</Box> */}
				</Box>
			)}
		</>
	);

	return (
		<PageContainer showBreadcrumb={false} showSearch={false} pageTitleChildren={pageTitle()} plusExclusive>
			{debate && (
				<>
					<GreyContainer>
						<InnerContentContainer>
							<ComunityDetailContainer>
								<Comment
									entityId={debate.id}
									showReactions={false}
									replySuccessCallBack={getComments}
									onCreateCommentAction={getComments}
									comment={debate.comment}
									margin='0 0 90px 0 '
									avatarSize='90px'
									type='comunity'
									//link
									rootComment
								/>
								<LikeWrapper>
									<ReplyDebate iconElement={<HiOutlineReply size={20} color='#013A7B' />} commentId={debate.comment.id} type='comunity' entityId={debate.id} successCallback={getComments} isChonky />
									<LikeButton
										isLiked={isLiked}
										onClick={() => {
											likeTopic(debate.id);
										}}>
										{isLiked ? (
											<>
												<span>Você curtiu este tópico</span>
												<IoMdThumbsUp size={20} />
												<span>{likesCount > 0 && likesCount}</span>
											</>
										) : (
											<>
												<span>Curtir Tópico</span>
												<IoMdThumbsUp size={20} />
												<span>{likesCount > 0 && likesCount}</span>
											</>
										)}
									</LikeButton>
									<FavoriteButton refID={debate.id} favType="TOPICO" buttonStyle="full" />
								</LikeWrapper>
								{debateComments &&
									debateComments.map((comment: any, index) => {
										if (commentsToShow > index) {
											return (
												<React.Fragment key={index}>
													<CommentWrapper key={index}>
														<Comment
															entityId={debate.id}
															parentId={comment.id}
															margin={isMobile.any() ? "0 0 80px 20px" : "0 0 80px 100px"}
															comment={comment.comment}
															isAuthorUser={comment.comment.author.id === userData.user_id}
															type='comunity'
															replySuccessCallBack={getComments}
														/>
													</CommentWrapper>
													{comment.comments.length > 0 ? (
														<>
															{comment.comments.map((innerComment: any, index: number) => {
																return (
																	<CommentWrapper key={index}>
																		<Comment
																			isAuthorUser={innerComment.author.id === userData.user_id}
																			entityId={debate.id}
																			parentId={comment.id}
																			margin={isMobile.any() ? "0 0 80px 20px" : "0 0 80px 100px"}
																			replySuccessCallBack={getComments}
																			comment={innerComment}
																			type='comunity'
																			onAction={() => { }}
																		/>
																	</CommentWrapper>
																);
															})}
														</>
													) : (
														""
													)}
												</React.Fragment>
											);
										}
										return undefined;
									})}

								<Button margin='0 0 0 100px' color='primary' disabled={!debateComments || commentsToShow >= debateComments?.length} onClick={handleViewMoreComments}>
									Ver mais comentários
								</Button>
							</ComunityDetailContainer>
						</InnerContentContainer>
					</GreyContainer>
					{/* <InnerContentContainer>
						<Typography variant='title' size='1.25rem' margin='40px 0 20px 20px'>
							Outros debates em destaque
						</Typography>
						<Box marginBottom='40px' display='grid' justifyContent='center' gridTemplateColumns={{ xs: "90%", md: "24% 24% 24% 24%" }} gridRowGap='15px' gridColumnGap={{ xs: "5%", md: "1%" }}>
							{otherDebatesData().map((otherDebate: OtherDebateType, index) => (
								<OtherDebateCard otherDebate={otherDebate} key={index} />
							))}
						</Box>
					</InnerContentContainer> */}
				</>
			)}
		</PageContainer>
	);
};

export { ComunityDetail };
