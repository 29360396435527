import styled from "styled-components";
import { darken } from "polished";
import media from "../../styles/media";

import { Props } from "./index";
import { CircularAvatarPhotoContainer } from "../../components/elements/CircularAvatarPhoto/CircularAvatarPhotoStyled";

const ResetPasswordContainer = styled.div<Props>`
	display: flex;
	background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : props.theme.color.primary)};
	background-image: ${(props) => (props.backgroundURL ? `url(${props.backgroundURL})` : "")};
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	${media.lessThan("medium")`
		height: 100vh;
		flex-direction: column;
		justify-content: center;
  	`}
	&:before {
		content: "";
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1250;
		width: 100%;
		height: 100%;
		background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : props.theme.color.primary)};
		opacity: 0.5;
	}
	.innerLoginWrapper {
		position: relative;
		z-index: 1300;
		background: white;
		width: 420px;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0 5%;
		${media.lessThan("medium")`
			width: 90%;
			height:  auto;
			max-height: 90vh;
			margin: 0 auto;
			border-radius: 10px;
			padding: 0 10% 30px;
			box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
		`}
		.MuiIconButton-root {
			padding: 0;
		}
		${CircularAvatarPhotoContainer} {
			${media.lessThan("medium")`
				margin-top: -45px;
				width: 90px;
				height: 90px;		
			`}
			.roundContainer {
				border: ${(props) => `3px solid ${darken(0.2, props.theme.color.primary)}`};
			}
		}
		h1 {
			color: ${(props) => props.theme.color.primary};
			text-align: center;
			font-size: 1.5rem;
			margin-bottom: 25px;
			${media.lessThan("medium")`
				margin-bottom: 5px;
			`}
		}
		.highlight {
			position: fixed;
			top: 50%;
			left: 450px;
			transform: translateY(-50%);
			color: #fff;
			max-width: 350px;
			font-size: 1.5rem;
			text-shadow: 0 0 8px #000;
			${media.lessThan("medium")`
				position: static;
				top: auto;
				transform: translateY(0);
				text-shadow: 0 0 0;
				color: rgba(0,0,0, .3);
				font-size: .8rem;
				text-align: center;
				margin-bottom: 45px;
			`}
		}

		.statusMsg {
			text-align: center;
			font-size: 0.9em;
			width: 100%;
			&.successMsg {
				color: #4bb543;
			}
			&.errorMsg {
				color: #db0f13;
			}
		}
		form {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			a {
				font-size: 0.7rem;
				color: ${(props) => props.theme.color.primary};
				margin-left: auto;
			}

			.MuiFormControl-root {
				margin: 5px 0;
				&:first-child {
					margin-bottom: 10px;
				}
			}
			.buttonWrapper {
				width: 100%;
				display: flex;
				justify-content: center;
				padding: 25px 0 0;
				${media.lessThan("medium")`
					padding: 45px 0 0;
				`}
				button {
					color: white;
					background: ${(props) => props.theme.color.primary};
					padding: 10px 25px;
					font-size: 1.125rem;
					border-radius: 100px;
					text-transform: uppercase;
					border: 1px solid transparent;
					transition: 0.3s;
					cursor: pointer;
					outline: none;
					${media.lessThan("medium")`
						font-size: 1rem;
						padding: 7px 20px;
					`}
					&:hover {
						border-color: ${(props) => props.theme.color.primary};
						background: white;
						color: ${(props) => props.theme.color.primary};
					}
				}
			}
		}
	}
`;
export { ResetPasswordContainer };
