import styled, { css } from "styled-components";
import { fade } from "@material-ui/core/styles/colorManipulator";

import { CardWrapper } from "../CardStyleBase";

import { Props } from "./index";

const ModuleClassCardContainer = styled(CardWrapper)<Props>`
	margin-top: 1px;
	cursor: pointer;
	background: ${(props) => fade(props.theme.color.primary, 0.05)};
	position: relative;
	${(props) =>
		props.isActive &&
		css`
			border-left: 7px solid ${props.theme.color.primary};
			background: ${(props) => {
				const color = props.theme.color.primary;

				return fade(color, 0.03);
			}};
		`};

	.materials {
		display: flex;
		margin-top: 15px;
		align-items: center;
		span {
			display: block;
			margin-left: 5px;
		}
	}
`;

const CardIcon = styled.div`
	width: 30px;
	height: 30px;
	border: 1px solid ${(props) => props.theme.color.primary};
	color: ${(props) => props.theme.color.primary};
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	right: 15px;
	transform: translateY(-50%);
	opacity: 0.9;
`;

const CompletedClassTag = styled.div`
	display: flex;
	justify-content: center;
	position: absolute;
	top: 10px;
	right: 15px;
	.txt {
		background: ${(props) => props.theme.color.primary};
		color: #fff;
		text-transform: uppercase;
		font-size: 11px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 30px 0 20px;
		border-radius: 40px;
	}
	.icon {
		background: #2d9cdb;
		color: #fff;
		border-radius: 40px;
		border: 2px solid #fff;
		display: flex;
		width: 20px;
		height: 20px;
		justify-content: center;
		align-items: center;
		margin-left: -20px;
	}
`;

export { ModuleClassCardContainer, CardIcon, CompletedClassTag };
