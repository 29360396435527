import React, { ReactNode } from "react";
import { Typography } from "../..";
import { WhoCanSeeTagContainer } from "./WhoCanSeeTagStyled";

export interface Props {
	background?: string;
	children?: ReactNode;
	value?: "all" | "owner";
	onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const WhoCanSeeTagWrapper: React.FC<Props> = ({ children, value, onChange, ...props }: Props) => (
	<WhoCanSeeTagContainer {...props}>
		<Typography variant='text' size='0.75rem' lineHeight='1' primary>
			QUEM PODE VER?
		</Typography>

		<div className='selectWrapper'>
			<select name='whoCanSee' onChange={onChange} value={value}>
				<option value='all'>Todos</option>
				<option value='owner'>Somente eu</option>
			</select>
		</div>
	</WhoCanSeeTagContainer>
);

WhoCanSeeTagWrapper.defaultProps = {
	background: "#FFF",
};

export { WhoCanSeeTagWrapper as WhoCanSeeTag };
