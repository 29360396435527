import React from 'react';

import { IoIosPaper } from 'react-icons/io';

import { IconWrapper } from '../IconWrapper';

export interface Props {
  rounded?: boolean;
  backgroundColor?: string;
  color?: string;
  size?: string;
}

const MaterialIconWrapper: React.FC<Props> = ({
  ...props
}: Props) => (
  <IconWrapper
    backgroundColor={props.backgroundColor}
    size={props.size}
    rounded={props.rounded}
    icon={<IoIosPaper size="60%" color={props.color} />}
  />
);

MaterialIconWrapper.defaultProps = {
  rounded: true,
  color: '#FFF',
  size: '32px',
};

export { MaterialIconWrapper as MaterialIcon };
