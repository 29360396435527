import styled from "styled-components";
import media from "../../../../styles/media";

const ChonkyButton = styled.button`
	background: none;
	cursor: pointer;
	display: flex;
	border-radius: 6px;
	border: 1px solid #e5e5e5;
	padding: 15px;
	justify-content: center;
	align-items: center;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0);
	transition: 0.3s ease;
	/* margin-right: 10px; */
	outline: none;
	${media.lessThan("medium")`
		padding: 10px 10px 12px 10px;
	`};
	span {
		white-space: nowrap;
		display: block;
		padding: 4px 0 0;
		color: #5e5e5e;
	}
	svg {
		margin: 0px 8px;
		color: #5e5e5e;
	}
	&:hover {
		border: 1px solid ${(props) => props.theme.color.primary};
		box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
		svg {
			color: ${(props) => props.theme.color.primary};
		}
		span {
			color: ${(props) => props.theme.color.primary};
		}
	}
`;

export { ChonkyButton };
