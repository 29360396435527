import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import { Typography, PageContainer, GreyContainer, InnerContentContainer, LastNews, LastArticles } from "../../components";
import { HomePageFeatureSlider } from "../../components/features/HomePageFeatureSlider";
import { MainCoursesHomeSlider } from "../../components/features/MainCoursesHomeSlider";
import { HomeCoursesCarousel } from "../../components/features/HomeCoursesCarousel";
import { MultimediaHomeCarousel } from "../../components/features/MultimediaHomeCarousel";
import { ContentLoadingError } from "../../components/ContentLoadingError";
import {
	FeaturedContentContainer, //
	VideoWrapper,
	VerticalFeatureBanner,
	WeeklyContentWrapper,
	ErrorWrapper,
	// ThinBanner,
	// BannerCursoFree,
	// BannerButtonForm,
} from "./HomeStyled";

import MultimidiasVideosService from "../../services/multimidiaVideos.service";

// import { CookieModal } from "../../components/elements/CookieModal";
// import { CookieImageModal } from "../../components/elements";
// import { FirstContentPopUp } from "../../components/features/FirstContentPopUp";

interface IWeeklyContent {
	video: string;
}

const Home: React.FC = ({ ...props }) => {
	const [weeklyContent, setWeeklyContent] = useState({} as IWeeklyContent);

	const getWeeklyContent = useCallback(async () => {
		const videosService = new MultimidiasVideosService(true);
		const response = await videosService.list();

		setWeeklyContent(response);
	}, []);

	useEffect(() => {
		let zoomParam = new URL(window.location.href).searchParams.get("lembreteZoom");
		if (zoomParam) {
			alert("Lembrete adicionado com sucesso. Fique de olho no seu e-mail.");
		}
		window.scrollTo(0, 0);
		getWeeklyContent();
	}, []);

	return (
		<PageContainer showBreadcrumb={false} showSearch={false}>
			<GreyContainer>
				<InnerContentContainer>


					<HomePageFeatureSlider location="HOME" />

					<Typography variant='title' uppercase color='#014491' margin='35px 0 10px'>
						Meus Cursos Principais
					</Typography>
					<FeaturedContentContainer>
						<MainCoursesHomeSlider />
					</FeaturedContentContainer>
					<Typography variant='title' uppercase color='#014491' margin='35px 0 10px'>
						Últimas Novidades
					</Typography>
					<WeeklyContentWrapper>
						{weeklyContent ? (
							<>
								<VideoWrapper>
									<ReactPlayer controls url={weeklyContent.video} />
								</VideoWrapper>
							</>
						) : (
							<>
								<ErrorWrapper>
									<ContentLoadingError refresh={getWeeklyContent} loadedResourceErrorMsg='o conteúdo em destaque da semana' />
								</ErrorWrapper>
							</>
						)}

						<VerticalFeatureBanner>
							<LastNews />
							{/* <Link to='/noticias'>
								<img src='https://www.nossomundoazul.com.br/static/images/banner-comunidade-vert.jpg' alt='Blerp' />
							</Link> */}
						</VerticalFeatureBanner>
						<VerticalFeatureBanner>
							<LastArticles />
							{/* <a href='https://www.lojaacademiadoautismo.com.br/?utm_campaign=ciaautismo&utm_source=home_ciaautismo&utm_media=banner'>
								<img src={bannerLoja} alt='Banner da Loja' />
							</a> */}
						</VerticalFeatureBanner>
					</WeeklyContentWrapper>
					<Typography variant='title' uppercase color='#014491' margin='35px 0 10px'>
						Séries
					</Typography>
					<MultimediaHomeCarousel />
					<Typography variant='title' uppercase color='#014491' margin='35px 0 10px'>
						Outros Cursos
					</Typography>
					<HomeCoursesCarousel />
				</InnerContentContainer>
			</GreyContainer>
		</PageContainer>
	);
};

export { Home };
