import styled from "styled-components";
import { Typography } from "../../../Typography";

const WideImg = styled.div`
	position: relative;
	&:before {
		content: "";
		display: block;
		padding-top: 56.25%;
	}
	img {
		width: 100%;
		height: 100%;
		position: absolute;
		object-fit: cover;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

const MultimediaHomeCarouselContainer = styled.div`
	.itemMultimedia {
		width: 100%;
		height: 84px;
		padding: 12px;
		margin-bottom: 25px;
		border: 1px solid #e0e0e0;
		border-radius: 4px;
		display: flex;
		align-items: center;
		text-decoration: none;
		font-weight: bold;
		color: ${(props) => props.theme.color.primary};
		background: #f6f8f9;
		box-shadow: 0 2px 3px rgba(0, 0, 0, 0);
		transition: 0.3s;
		&:hover {
			box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
		}
		${WideImg} {
			width: 100px;
		}
		p {
			flex: 1;
			padding: 0 12px;
			font-size: 0.9em;
		}
	}
`;

const LoadingWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

export { MultimediaHomeCarouselContainer, WideImg, LoadingWrapper };
