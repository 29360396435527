export function circleRadius(size: number) {
  const s = size * 0.2;
  return (size - s) / 2;
}
export function strokeWidth(size: number) {
  return size * 0.1;
}
export function circlePercentage(percentage: number) {
  // function pra calcular o percentual, em Graus, que precisa ser preenchido pelo círculo de progresso
  let finalPct: number;
  finalPct = (100 - percentage) / 100;
  if (percentage === 100) {
    return 359; // não pode ser 360 pq buga o tamanho do path
  }
  return 360 - (360 * finalPct);
}

function polarToCartesian(centerX:number, centerY:number, radius:number, angleInDegrees:number) {
  const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians)),
  };
}
export function describeArc(x:number, y:number, radius:number, startAngle:number, endAngle:number) {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  const d = [
    'M', start.x, start.y,
    'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y,
  ].join(' ');

  return d;
}
