import styled from "styled-components";
import media from "../../../../styles/media";

const ResponsiveImg = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	img {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		object-fit: cover;
	}
`;

const HomePageFeatureSliderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 2rem;
	.banners {
		border-radius: 6px;
		width: 83%;
		height: 300px;
		${media.greaterThan("huge")`
			height: 360px;
		`}
		.swiper-container {
			height: 300px;
			${media.greaterThan("huge")`
				height: 360px;
			`}
			${media.lessThan("medium")`
				height: 170px;
			`}
		}
		${media.lessThan("medium")`
			height: 170px;
			border-radius: 6px 6px 0 0;
		`}
	}
	.thumbs {
		width: 15%;
		height: 300px;
		border-radius: 6px;
		${media.lessThan("medium")`
			height: 70px;
			border-radius: 0 0 6px 6px;
		`}
		.swiper-container {
			height: 300px;
			${media.lessThan("medium")`
				height: 70px;
			`}
		}
		${ResponsiveImg} {
			transition: 0.3s;
			&:hover {
				opacity: 0.7;
			}
			img {
				max-height: 100px;
				${media.lessThan("medium")`
						max-width: 100px;
				`}
			}
		}
		.swiper-slide {
			position: relative;
			&:before {
				position: absolute;
				top: 0;
				left: 0;
				height: calc(100% - 10px);
				width: calc(100% - 10px);
				content: "";
				display: block;
				z-index: 999;
				border: 5px solid transparent;
				transition: 0.3s ease;
				cursor: pointer;
			}
			&:hover {
				&:before {
					border: 5px solid ${(props) => props.theme.color.primary};
				}
			}
		}
		.swiper-slide-thumb-active {
			&:before {
				border: 5px solid ${(props) => props.theme.color.primary};
			}
		}
	}
	.banners,
	.thumbs {
		overflow: hidden;
		${media.lessThan("medium")`
			width: 100% !important;
		`}
	}
`;

const BannerInfoArea = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	padding: 20px;
	width: 100%;
	height: 100%;
	background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 5%, rgba(0, 0, 0, 0) 90%);
	color: #fff;
	.infos {
		width: 100%;
		position: absolute;
		bottom: 20px;
		left: 20px;
	}
	.bannerTitle,
	.bannerSubtitle {
		display: block;
	}
	.bannerTitle {
		font-size: 2em;
	}
`;

export { HomePageFeatureSliderContainer, ResponsiveImg, BannerInfoArea };
