import styled from "styled-components";
import { Props } from "./index";

const CircularProgressIndicatorContainer = styled.div<Props>`
	display: flex;
	width: ${(props) => (props.size ? `${props.size}px` : "45px")};
	height: ${(props) => (props.size ? `${props.size}px` : "45px")};
	background-color: rgba(255, 255, 255, 0.8);
	border-radius: 50%;
	svg {
		width: ${(props) => (props.size ? `${props.size}px` : "45px")};
		height: ${(props) => (props.size ? `${props.size}px` : "45px")};
		position: relative;
		z-index: 1000;
		path {
			stroke-linecap: round;
			stroke: ${(props) => props.theme.color.primary};
			transform: ${(props) => {
				const rotation = 360 * (props.percentage / 100);
				return `rotate(calc(${rotation}deg)) scaleX(-1);`;
			}};
			transform-origin: center;
		}
		circle {
			width: 100%;
			height: 100%;
			fill: none;
			stroke: #e0e0e0;
			stroke-linecap: round;
		}
	}
	.percent {
		position: relative;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		z-index: 1001;
		.number {
			color: #000;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-content: center;
			align-items: center;
			font-size: ${(props) => (props.size ? `${props.size / 3.5}px` : "14px")};
			font-weight: 600;
			&:after {
				content: "%";
			}
		}
	}
`;
export { CircularProgressIndicatorContainer };
