import React, { useEffect } from "react";
// import { IClassFile } from "../../../types/course";
import {
	ClassFileItemContainer, //
	FileTypeIcon,
	FileName,
	FileSize,
	FileFooter,
	FileObs,
	FileStatus,
} from "./ClassFileItemStyled";

import {
	SiMicrosoftword, //
	SiMicrosoftpowerpoint,
	SiMicrosoftexcel,
	SiAdobeacrobatreader,
} from "react-icons/si";

import { BsFileEarmarkText, BsImage, BsLink45Deg } from "react-icons/bs";

import { IoTrashOutline } from "react-icons/io5";

export interface Props {
	file: any;
	removeAction?: () => void;
	type: "file" | "classFile";
}

const ClassFileItemWrapper: React.FC<Props> = ({ file, type, removeAction, ...props }: Props) => {
	const fileTypeIcon = (ext: string) => {
		const iconSize = 20;
		switch (ext) {
			//
			case "pdf":
				return <SiAdobeacrobatreader size={iconSize} color='#ff0000' />;
			case "txt":
			case "plain":
				return <BsFileEarmarkText size={iconSize} color='#CCC' />;
			case "ppt":
			case "pptx":
				return <SiMicrosoftpowerpoint size={iconSize} color='#ffbb00' />;
			case "doc":
			case "docx":
				return <SiMicrosoftword size={iconSize} color='#00a1f1' />;
			case "xls":
			case "xlsx":
			case "vnd.ms-excel":
				return <SiMicrosoftexcel size={iconSize} color='#7cbb00' />;
			case "jpg":
			case "png":
			case "jpeg":
				return <BsImage size={iconSize} color='#00f0c4' />;
			default:
				return <BsFileEarmarkText size={iconSize} color='#CCC' />;
		}
	};

	useEffect(() => {
		// console.log(file);
	}, []);

	const extractExtensionFromFullName = (fullName: string) => {
		const arrayFromString = fullName.split(".");
		const ext = arrayFromString[arrayFromString.length - 1];
		return ext;
	};

	return (
		<ClassFileItemContainer {...props}>
			{type === "file" ? (
				<>
					<FileTypeIcon>
						{fileTypeIcon(extractExtensionFromFullName(file.name))} .{extractExtensionFromFullName(file.name)}
					</FileTypeIcon>
					<FileName>{file.name}</FileName>
					<FileSize>{file.size} bytes</FileSize>
					{removeAction && (
						<FileFooter>
							<button onClick={removeAction}>
								<IoTrashOutline size={20} color='#ff0000' /> Remover
							</button>
						</FileFooter>
					)}
				</>
			) : (
				<>
					<FileTypeIcon>
						{fileTypeIcon(file.extensao)} .{file.extensao}
					</FileTypeIcon>
					<FileName>
						{file.nome_arquivo}.{file.extensao}
						<FileSize>{file.tamanho}</FileSize>
					</FileName>
					{file.status && (
						<>
							{file.status === "Aceito" ? (
								<FileStatus className='apv'>Aprovado</FileStatus>
							) : file.status === "Novo" ? (
								<FileStatus className='pnd'>Pendente</FileStatus>
							) : (
								<FileStatus className='rpv'>Rejeitado</FileStatus>
							)}
						</>
					)}
					{file.observacao && (
						<FileObs>
							<p>Observações:</p>
							<small>{file.observacao}</small>
						</FileObs>
					)}
					<FileFooter>
						<a href={file.arquivo} target='_blank' title={`Acessar ${file.nome_arquivo}.${file.extensao}`}>
							<BsLink45Deg size={20} />
							Acessar Arquivo
						</a>
					</FileFooter>
				</>
			)}
		</ClassFileItemContainer>
	);
};

export { ClassFileItemWrapper as ClassFileItem };
