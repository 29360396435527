import styled from "styled-components";

const HomeCoursesCarouselContainer = styled.div`
	display: flex;
	.swiper-container {
		min-height: 400px;
	}
	a,
	.courseLink {
		text-decoration: none;
		position: relative;
		transition: 0.3s ease;
		top: 0;
		h1 {
			transition: 0.3s ease;
		}
		&:hover {
			h1 {
				color: ${(props) => props.theme.color.primary};
			}
		}
	}
`;
export { HomeCoursesCarouselContainer };
