import BaseService from "./base.service";
import api from "./api";
//import { headers as configHeaders } from "./config";

export default class ArticlesDebatesService extends BaseService {
	constructor(id) {
		if (!id) throw new Error("Missing Class Id when trying to retrieve debates");

		super(`artigos/${id}/comentarios`);
	}

	async create(data) {
		try {
			const response = await api.post(`${this.resource}/novo/`, data, {
				headers: {
					"Content-Type": "application/json",
				},
			});

			//console.log("Comentário Criado Response ==>", response);

			return { status: response.status, data: response.data };
		} catch (err) {
			console.error(`erro ao criar ${this.resource} :`, err);
			console.error(`erro ao criar ${this.resource} :`, err.response);
			return {
				error: true,
				status: err.response ? err.response.status : null,
				data: err.response ? err.response.data : null,
			};
		}
	}
}
