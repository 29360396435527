import styled, { css } from "styled-components";
import { Props } from "./IconWrapper";
import media from "../../../styles/media";

const IconContainer = styled.div<Props>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${(props) => props.size};
	height: ${(props) => props.size};
	background: ${(props) => (props.backgroundColor ? props.backgroundColor : props.theme.color.primary)};

	${media.lessThan("medium")`
    background: #FFF;
  `};
	cursor: ${(props) => props.cursor};
	${(props) =>
		props.rounded &&
		css`
			border-radius: 50%;
		`}
	${(props) =>
		!props.filled &&
		css`
			background: transparent !important;
		`}
  ${(props) =>
		props.primaryColor &&
		css`
			svg {
				color: ${props.theme.color.primary};
				fill: ${props.theme.color.primary};
			}
		`}
   ${(props) =>
		props.border &&
		css`
			border: 1px solid #f2f2f2;
		`}
  ${(props) =>
		props.primaryBorder &&
		css`
			border: 1px solid ${props.theme.color.primary};
		`}
    ${(props) =>
		props.marginRight &&
		css`
			margin-right: ${props.marginRight};
		`}
    ${(props) =>
		props.marginLeft &&
		css`
			margin-left: ${props.marginLeft};
		`}
`;

export { IconContainer };
