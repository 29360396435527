import { TFavType } from "../types/favorites";
import api from "./api";
import BaseService from "./base.service";
import { headers } from "./config";

export default class FavoritesService extends BaseService {
	constructor() {
		super("favoritos");
	}

	async toggleFavorite(id: string | number, favType: TFavType) {
		try {
			const response = await api.post(`${this.resource}/favorito-toggle/`, {
				id_ref: id,
				tipo: favType,
			});
			return response.data;
		} catch (error) {
			console.error("Erro ao marcar ou remover favorito", error);
		}
	}
}
