import styled, { css } from "styled-components";
import { isMobile } from "../../../isMobile";

interface Props {
	open: boolean;
}

const SidebarContainer = styled.div<Props>`
	display: flex;
	ul {
		overflow: hidden;
	}
	.handleDrawer {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1300;
		color: ${(props) => props.theme.color.primary};
		top: 18px;
		left: 7px;
	}
	.MuiListItemText-primary,
	.MuiListItemText-secondary {
		${!isMobile.any() && css`
			@media screen and (max-height: 700px) {
				
	 			font-size: 11px !important;
			}
		`}
	}
	.makeStyles-drawerClose-3,
	.MuiDrawer-paperAnchorDockedLeft {
		${!isMobile.any() && css`
			@media screen and (max-height: 700px) {
				&:not(:hover) {
	 				width: 55px !important;
				}
			}
		`}
	}
	.MuiListItemText-root {
		margin-left: 7px;
	}
	.MuiListItemText-primary {
		font-weight: 700;
		color: ${(props) => props.theme.color.primary};
		font-size: 13px;
		text-transform: uppercase;
		//margin: 0 0 0 3px;
	}
	.MuiListItem-root {
		${!isMobile.any() && css`
			@media screen and (max-height: 700px) {
				height: 50px !important;
			}
		`}
		&:first-child {
			margin-bottom: 10px !important;
			${!isMobile.any() && css`
				@media screen and (max-height: 700px) {
					margin-bottom: 20px !important;
				}
			`}
		}
		&.MuiListItem-gutters {
			margin: 0px;
			padding: 8px 10px;
			${!isMobile.any() && css`
				@media screen and (max-height: 700px) {
					padding: 8px 10px 0;
				}
			`}
		}
		.MuiListItemIcon-root {
			min-width: 58px;
			${!isMobile.any() && css`
			@media screen and (max-height: 700px) {
				min-width: 30px;
			}`}
			> div {
				background: #fff;
				//box-shadow: 0px 0px 6px 6px rgba(0, 0, 0, 0.05);
				box-shadow: 0px 3px 6px #8cb1de55;
				width: 50px;
				height: 50px;
				${!isMobile.any() && css`
				@media screen and (max-height: 700px) {
					width: 35px;
 					height: 35px;
					svg {
						max-height: 20px;
						max-height: 20px;
					}
				}`}
			}
		}
		&:hover {
			background-color: transparent;
			.MuiListItemIcon-root {
				> div {
					background: #fff;
					border: 2px solid #f2c94c;
					> svg {
						//fill: #013a7b;
					}
				}
			}
		}
		.active {
			.MuiListItemText-primary {
				color: ${(props) => props.theme.color.primary};
			}
			.MuiListItemIcon-root {
				> div {
					background: #fff;
					border: 2px solid #f2c94c;
					> svg {
						//fill: #013a7b;
					}
				}
			}
		}
	}
`;

const MenuHeight = styled.div`
	height: ${(props) => props.theme.headerHeight};
`;
export { SidebarContainer, MenuHeight };
