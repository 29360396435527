import styled from "styled-components";
import { darken } from "polished";
import { Props } from "./index";

/*function roundUp(num:number) {
  return Math.round(num/2);
}

//console.log('RoundUP', roundUp(9));*/

const DiscountSealContainer = styled.div<Props>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: ${(props) => (props.size ? props.size + "px" : "45px")};
	height: ${(props) => (props.size ? props.size + "px" : "45px")};
	position: relative;
	svg {
		fill: ${(props) => (props.background ? props.background : "#DD0000")};
		stroke: ${(props) => (props.background ? darken(0.15, props.background) : darken(0.15, "#DD0000"))};
		stroke-width: 2;
		overflow: visible;
	}
	span {
		position: absolute;
		z-index: 1000;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: ${(props) => (props.primaryColor ? props.primaryColor : "#FFCC00")};
		font-size: ${(props) => (props.size ? props.size / 3 + "px" : "14px")};
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		text-align: center;
		width: 100%;
		line-height: 80%;
		em {
			font-size: 0.7em;
			font-style: normal;
		}
		b {
			font-weight: 700;
		}
		small {
			display: block;
			color: ${(props) => (props.secondaryColor ? props.secondaryColor : "#FFFFFF")};
			text-transform: uppercase;
			font-weight: 400;
			font-size: 0.65em;
			width: 100%;
		}
	}
`;
export { DiscountSealContainer };
