import styled from "styled-components";

const FixedBarContainer = styled.div`
	background-color: ${(props) => props.theme.color.primary};
	padding: 10px 20px;
	display: flex;
	justify-content: space-between;
	position: fixed;
	width: calc(100% - 75px);
	bottom: 0;
`;

export { FixedBarContainer };
