import React, { ReactNode } from 'react';
import { ImageWrapperContainer } from './ImageWrapperStyled';

export interface Props {
  src: string,
  width: string,
  height: string,
  alt?: string,
  children?: ReactNode
  locked?: boolean
}

const ImageWrapper: React.FC<Props> = ({
  children,
  ...props
}: Props) => (
  <ImageWrapperContainer
    {...props}
  >
    <img
      src={props.src}
      alt={props.alt}
      style={{
        objectFit: 'cover',
        width: '100%',
        height: '100%',
      }}
    />
  </ImageWrapperContainer>
);


ImageWrapper.defaultProps = {
  alt: '',
};

export { ImageWrapper };
