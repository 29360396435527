import BaseService from "./base.service";
import api from "./api";
//import { headers } from "./config";

export default class CommentService extends BaseService {
	constructor(id) {
		super(`comunidade/topicos/${id}/comentarios`);
	}

	async create(data) {
		console.log("DATA FROM REPLY/CREATE ==> ", data);

		try {
			const response = await api.post(`${this.resource}/novo/`, data, {
				headers: {
					"Content-Type": "application/json",
				},
			});

			//console.log("Comentário Criado Response ==>", response);

			return { status: response.status, data: response.data };
		} catch (err) {
			console.error(`erro ao criar ${this.resource} :`, err);
			console.error(`erro ao criar ${this.resource} :`, err.response);
			return {
				error: true,
				status: err.response ? err.response.status : null,
				data: err.response ? err.response.data : null,
			};
		}
	}

	formatReaction(reaction) {
		return {
			name: reaction.reaction,
			count: reaction.contador,
		};
	}

	formatComment(comment) {
		const reactions_formated = comment.reacts.map((reaction) => this.formatReaction(reaction));
		const formated_comment = {
			author: {
				name: comment.criado_por.nome,
				isMonitor: true,
				imageUrl: comment.criado_por.imagem_url,
			},
			text: comment.descricao,
			date: comment.created,
			reactions: reactions_formated,
			userReactions: [],
		};

		return formated_comment;
	}

	formatDetailData(comments) {
		const formatedComments = comments?.map((comment) => this.formatComment(comment));
		return formatedComments;
	}
}
