import React, { useEffect, useState } from "react";
import { PageContainer, GreyContainer, InnerContentContainer, ContentLoadingError } from "../../components";

import { ContratosContainer, TermBody } from "./ContratosStyled";

import TermsService from "../../services/terms.service";
import { useHistory, useParams } from "react-router";
import { Button } from "../../components/elements";

import loadingGif from "../../assets/images/loading.gif";

export interface Props {
	background?: string;
}

interface ITermo {
	id: number;
	grupo: number;
	cursos: string | string[];
	texto: string;
	termo_uso: string;
}

const Contratos: React.FC<Props> = ({ children, background = "#FFF", ...props }) => {
	const history = useHistory();
	const { id } = useParams<{ id: string }>();
	const [loading, setLoading] = useState(true);
	const [termToSign, setTermToSign] = useState<ITermo | undefined>(undefined);

	const getTermoToSign = async () => {
		setLoading(true);
		const termsService = new TermsService();
		const response = await termsService.list();
		//console.log("termo", response);

		const foundTermo = response.find((termo: ITermo) => termo.id === parseInt(id));

		if (!foundTermo) {
			setLoading(false);
			return;
		}

		setTermToSign(foundTermo);
		setLoading(false);
	};

	const signTerm = async () => {
		if (!termToSign) return false;
		const termsService = new TermsService();
		const response = await termsService.sign({
			id: termToSign.id,
		});

		//console.log("Assinado com sucesso", response);

		if (response.id) {
			return history.push("/redirection");
		}
	};

	useEffect(() => {
		getTermoToSign();
	}, [id]);

	return (
		<PageContainer pageTitle={termToSign ? termToSign.termo_uso : "Assinatura de Contrato e Termos de Serviço"} showBreadcrumb={false} showMenu={false}>
			<GreyContainer>
				<InnerContentContainer>
					<ContratosContainer background={background} {...props}>
						{loading ? (
							<img src={loadingGif} style={{ display: "block", margin: "0 auto" }} />
						) : (
							<>
								{termToSign ? (
									<>
										<TermBody dangerouslySetInnerHTML={{ __html: termToSign?.texto }}></TermBody>
										<Button variant='contained' color='primary' onClick={signTerm}>
											Aceitar e Assinar
										</Button>
									</>
								) : (
									<ContentLoadingError loadedResourceErrorMsg='este contrato' refresh={getTermoToSign} />
								)}
							</>
						)}
					</ContratosContainer>
				</InnerContentContainer>
			</GreyContainer>
		</PageContainer>
	);
};

export { Contratos };
