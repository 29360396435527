import React, { useCallback, useEffect, useState } from 'react';
import { InfosPjContainer } from './InfosPjStyled'
import PJService from '../../services/pj.service';
import { IAddUserData, ICreditos, ILiberacao } from '../../types/pj';
import { Button, Loading } from '../elements';
import { Input, TextField } from '@material-ui/core';
import { FaTrash } from 'react-icons/fa';

export interface Props {

}

interface newUserData {
  nome: string,
  email: string,
}

const InfosPjWrapper: React.FC<Props> = ({
  ...props
}: Props) => {
  const [meusCreditos, setMeusCreditos] = useState<ICreditos[]>([]);
  const [minhasLiberacoes, setMinhasLiberacoes] = useState<ILiberacao[]>([]);

  const [postLoading, setPostLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  //modalShowUsers
  const [showUsersModalOpen, setShowUsersModalOpen] = useState<boolean>(false)
  const [configName, setConfigName] = useState<string>('');
  //modalAddUser

  const [newUserModalOpen, setNewUserModalOpen] = useState<boolean>(false)
  const [configID, setConfigID] = useState<number>(0);
  const [newUserData, setNewUserData] = useState<newUserData>({
    nome: '',
    email: '',
  });


  const getMeusCreditos = async () => {
    const pjService = new PJService();

    const response = await pjService.meusCreditos();

    // console.log("CREDITOS", response)
    setMeusCreditos(response)
  }

  const getMinhasLiberacoes = async () => {
    setLoading(true)
    const pjService = new PJService();

    const response = await pjService.minhasLiberacoes();

    // console.log("LIBERACOES", response)
    setMinhasLiberacoes(response)
    setLoading(false)
  }

  const clearNewUserData = () => {
    setNewUserData({
      nome: '',
      email: '',
    })
  }

  const closeModal = () => {
    setShowUsersModalOpen(false);
    setNewUserModalOpen(false);
    clearNewUserData();
    setConfigID(0)
    setConfigName('');
  }

  const openNewUserModal = (cId: number) => {
    setShowUsersModalOpen(false)
    setConfigID(cId);
    setNewUserModalOpen(true)
  }

  const openShowUsersModal = (cName: string, cId: number) => {
    setNewUserModalOpen(false)
    setConfigName(cName);
    setShowUsersModalOpen(true);
    setConfigID(cId)
  }

  const removeUserFromGroup = async (uId: number) => {
    setPostLoading(true);
    if (!uId) return alert("Houve um erro, tente novamente.")

    const pjService = new PJService();
    try {
      await pjService.removerAcesso(uId);
      alert('Usuário removido com sucesso');
    } catch (error) {
      alert('Houve um erro ao remover o usuário');
    } finally {
      setPostLoading(false);
      getMeusCreditos();
      getMinhasLiberacoes();
      return closeModal();
    }
  }

  const addUserToGroup = useCallback(async () => {
    setPostLoading(true);
    if (!newUserData.nome) return alert("Por favor insira o nome do usuário")
    if (!newUserData.email) return alert("Por favor insira o email do usuário")

    const pjService = new PJService();

    const data: IAddUserData = {
      nome: newUserData.nome,
      email: newUserData.email,
      credito: configID
    }
    try {
      const response = await pjService.liberarAcesso(data);
      alert('Usuário inserido com sucesso');
    } catch (error) {
      alert('Houve um erro ao inserir o usuário');
    } finally {
      setPostLoading(false);
      getMeusCreditos();
      getMinhasLiberacoes();
      return closeModal();
    }
  }, [configID, newUserData])


  const handleChange = (prop: keyof newUserData) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewUserData({ ...newUserData, [prop]: event.target.value });
  };

  useEffect(() => {
    getMeusCreditos();
    getMinhasLiberacoes();
  }, [])

  return (
    <InfosPjContainer {...props}>
      {meusCreditos.length > 0 ?
        <>
          {meusCreditos.map((item: ICreditos) =>
            <div className='configVenda' key={item.id}>
              <h3>{item.config_venda}</h3><br />
              <small><b>Validade:</b> {new Date(item.data_expiracao).toLocaleDateString()}</small><br />
              <small><b>Créditos Contratados:</b> {item.total_creditos}</small><br />
              <small><b>Créditos Disponíveis:</b> {item.quantidade}</small><br />
              <div className="btnWrapper">
                <Button variant='outlined' color='primary' onClick={() => openShowUsersModal(item.config_venda, item.id)} disabled={minhasLiberacoes.filter((lib: ILiberacao) => lib.config_venda === item.config_venda).length < 1}>Ver usuários</Button>
                <Button variant='add' disabled={item.quantidade < 1} onClick={() => openNewUserModal(item.id)}>Adicionar usuário</Button>
              </div>
            </div>
          )
          }
        </>
        :
        <div className="loading">
          <Loading variant='simple' />
        </div>}

      {newUserModalOpen &&
        <div className="modalAddUser">
          <div className="overlay overlayAddUser" onClick={closeModal}></div>
          <div className="modalBody modalBodyAddUser">
            <h2>Adicionar usuário</h2>
            <div className="addUserForm">
              <TextField type='text' label='Nome' required fullWidth onChange={handleChange("nome")} /> <br /><br />
              <TextField type='email' label='Email' required fullWidth onChange={handleChange("email")} />

              {postLoading ?
                <div className="loading">
                  <Loading variant='simple' />
                </div>
                :
                <div className="modalBtnWrapper modalAddUserBtnWrapper">
                  <Button variant='outlined' color='primary' onClick={closeModal} disabled={postLoading}>Cancelar</Button>
                  <Button variant='add' disabled={postLoading} onClick={addUserToGroup}>Adicionar</Button>
                </div>
              }
            </div>
          </div>
        </div>}

      {showUsersModalOpen &&
        <div className="modalAddUser">
          <div className="overlay overlayShowUser" onClick={closeModal}></div>
          <div className="modalBody modalBodyShowUsers">
            <h2>Usuários</h2>
            {
              minhasLiberacoes.filter((lib: ILiberacao) => lib.config_venda === configName).map((liberacao: ILiberacao) =>
                <React.Fragment key={liberacao.id}>
                  {liberacao.status === "Ativa" &&
                    <div className='liberacao'>
                      <div className="infosLib">
                        {/* <small>{liberacao.status}</small><br /> */}
                        <span>{liberacao.usuario_nome}</span><br />
                        <small>{liberacao.usuario_email}</small><br />
                      </div>
                      <div className="actionsLib">
                        {postLoading ?
                          <Loading variant='simple' size='20px' />
                          :
                          <button onClick={() => removeUserFromGroup(liberacao.id)} title='Remover Usuário'>
                            <FaTrash size={20} color="#999" />
                          </button>
                        }
                      </div>
                    </div>
                  }
                </React.Fragment>
              )
            }
            <div className="modalBtnWrapper modalShowUsersBtnWrapper">
              <Button variant='outlined' color='primary' onClick={closeModal} disabled={postLoading}>Cancelar</Button>
              <Button variant='add' onClick={() => openNewUserModal(configID)} disabled={postLoading}>Adicionar</Button>
            </div>
          </div>
        </div>}
    </InfosPjContainer>
  );
};

export { InfosPjWrapper as InfosPj };