import styled from "styled-components";
import media from "../../../styles/media";

// import { Props } from './index';

const InnerContentContainerContainer = styled.div`
	width: 100%;
	margin: 0 auto;
	padding-bottom: 80px;
	${media.greaterThan("large")`
    max-width: 1200px;
  `}
	${media.greaterThan("huge")`
    max-width: 1400px;
  `}
`;
export { InnerContentContainerContainer };
