import BaseService from "./base.service";

export default class LikeCommentService extends BaseService {
	constructor(type) {
		switch (type) {
			case "lesson":
				super("cursos/comentarios");
				break;
			case "comunity":
				super(`comunidade/topicos/comentarios`);
				break;
			case "article":
				super("artigos/comentarios");
				break;
		}
	}
}
