import React from "react";
import { IconsRoundedGroup, IconTextWrapper, CertificateIcon, CourseInProgressIcon, LessonsCountIcon, CompletedCourseIcon } from "../../../Icons";
import { FiClock } from "react-icons/fi";

export interface Props {
	certificate: boolean;
	status: string | boolean | "notStarted";
	releasedLessons: number;
}

const CourseCardIconsToolbarWrapper: React.FC<Props> = ({ certificate, status, releasedLessons, ...props }: Props) => (
	<IconsRoundedGroup>
		<div>{certificate && <IconTextWrapper text='Com Certificado' icon={<CertificateIcon />} />}</div>
		<div>
			{status === "notStarted" ? (
				<IconTextWrapper text='Não Iniciado' icon={<FiClock size={22} color='#013A7B' />} />
			) : (
				<>{status === false ? <IconTextWrapper text='Em andamento' icon={<CourseInProgressIcon />} /> : <IconTextWrapper text='Concluído' icon={<CompletedCourseIcon />} />}</>
			)}
		</div>
		<div>
			<IconTextWrapper text='Aulas Disponíveis' icon={<LessonsCountIcon count={releasedLessons} />} />
		</div>
	</IconsRoundedGroup>
);

export { CourseCardIconsToolbarWrapper as CourseCardIconsToolbar };
