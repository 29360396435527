import BaseService from "./base.service";

export default class MultimidiaDetailsService extends BaseService {
	constructor() {
		super("multimidia/videos/categoria");
	}

	// formatComment(comment) {
	//   const reactions_formated = comment.reacts.map((reaction) =>
	//     this.formatReaction(reaction)
	//   );
	//   const formated_comment = {
	//     author: {
	//       name: comment.criado_por.nome,
	//       isMonitor: true,
	//       imageUrl: comment.criado_por.imagem_url,
	//     },
	//     text: comment.descricao,
	//     date: comment.created,
	//     reactions: reactions_formated,
	//     userReactions: [],
	//   };

	//   return formated_comment;
	// }
}
