import styled, { css } from 'styled-components';


interface Props {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
}

const SeeMoreWrapper = styled.div<Props>`
    position: absolute;
    ${(props) => props.top && css`
        top: ${props.top};
    `}
    ${(props) => props.bottom && css`
        bottom: ${props.bottom};
    `}
    ${(props) => props.left && css`
        left: ${props.left};
    `}
    ${(props) => props.right && css`
        right: ${props.right};
    `}
`;


export { SeeMoreWrapper };
