import styled from "styled-components";

interface Props {
	padding?: string;
}

const GreyContainerContainer = styled.div<Props>`
	background: #f6f8f9;
	border-bottom: 1px solid #f2f2f2;
	border-top: 1px solid #f2f2f2;
	padding: ${(props) => props.padding};
	min-height: 80vh;
`;
export { GreyContainerContainer };
