import React from "react";
import { CardWrapper, LoadingPlaceholder } from "../..";

const CourseCardWrapper: React.FC = () => (
	<CardWrapper height='420px' padding='0'>
		<LoadingPlaceholder variant='image' height='162px' width='100%' />
		<LoadingPlaceholder variant='text' linesNumber={2} />
		<br />
		<LoadingPlaceholder variant='text' linesNumber={4} />
	</CardWrapper>
);

export { CourseCardWrapper as CourseCardLoading };
