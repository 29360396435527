import styled from "styled-components";
import media from "../../../styles/media";

const SubscriptionBannerContainer = styled.div`
	display: flex;
`;
const BannerDestaque = styled.div`
	width: 100%;
	margin-bottom: 15px;
	a,
	img {
		display: block;
	}
	img {
		width: 100%;
	}
	img.desk {
		display: block;
		${media.lessThan("medium")`
		display: none;
	`}
	}
	img.mob {
		display: none;
		${media.lessThan("medium")`
		display: block;
	`}
	}
`;
export { SubscriptionBannerContainer, BannerDestaque };
