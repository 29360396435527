import styled from "styled-components";

const TeachersContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	position: relative;
	padding: 20px;
	width: 100%;
	span {
		color: ${(props) => props.theme.color.primary};
		font-weight: bold;
		font-size: 1rem;
	}
`;

export { TeachersContainer };
