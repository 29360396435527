import styled from "styled-components";
import { Props } from "./index";
import { darken } from "polished";

const StudentProfileMapContainer = styled.div<Props>`
	display: flex;
	justify-content: center;
	width: 100%;
	max-width: 1340px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
	position: relative;
	.leaflet-container {
		width: 100%;
		height: 300px;
	}
	.editPill {
		position: absolute;
		top: 20px;
		right: 20px;
		z-index: 401;
		display: inline-block;
		background: ${(props) => props.theme.color.primary};
		color: #fff;
		padding: 8px 16px;
		border-radius: 20px;
		font-size: 0.9rem;
		text-transform: uppercase;
		transition: 0.3s;
		cursor: pointer;
		&:hover {
			background: ${(props) => darken(0.08, props.theme.color.primary)};
		}
	}
`;
export { StudentProfileMapContainer };
