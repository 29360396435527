import React from 'react';
import { DividerContainer } from './DividerStyled';

export interface Props {
  gradient?: boolean
  color?: string
  margin?: string
  height?: string
}

const DividerWrapper: React.FC<Props> = ({
  gradient,
  color,
  margin,
  height,
}: Props) => (
  <DividerContainer gradient={gradient} color={color} margin={margin} height={height} />
);

DividerWrapper.defaultProps = {
  gradient: false,
  color: '#f2f2f2',
  margin: '0',
  height: '1px',
};

export { DividerWrapper as Divider };
