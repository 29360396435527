import styled from "styled-components";

const CardPictureHeaderContainer = styled.div`
	display: flex;
	position: relative;
	object-fit: cover;
	overflow: hidden;
	width: 100%;
	border-top-right-radius: 6px;
	border-top-left-radius: 6px;
	border-bottom: 3px solid ${(props) => props.theme.color.primary};

	.cadeado {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(0, 0, 0, 0.3);
		.round {
			width: 70px;
			height: 70px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			border: 1px solid white;
		}
	}
`;

const SealsWrapper = styled.div`
	position: absolute;
	left: 10px;
	top: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	> div {
		:not(:first-child) {
			margin-left: 5px;
		}
	}
`;

const CommentsTag = styled.span`
	position: absolute;
	top: 10px;
	right: 10px;
	white-space: nowrap;
	display: inline-block;
	padding: 5px;
	background: red;
	color: #fff;
	border-radius: 6px;
	font-size: 0.7rem;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	font-weight: 700;
`;

export { CardPictureHeaderContainer, SealsWrapper, CommentsTag };
