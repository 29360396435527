import styled from "styled-components";
import { Props } from "./index";
import media from "../../styles/media";

const SejaPlusContainer = styled.div<Props>`
	/* display: flex; */
	.videoColumn {
		width: 50%;
		${media.lessThan("medium")`
      width: 100%;
      margin-bottom: 30px;
    `}
		> div {
			text-align: center !important;
		}
	}
	.textColumn {
		width: 46%;
		${media.lessThan("medium")`
      width: 100%;
    `}
		> div {
			color: #000 !important;
		}
		ul {
			margin: 0 0 20px 20px;
			li {
				margin-bottom: 10px;
			}
		}
		.ctaHideDesk {
			display: none;
			> div {
				text-align: center !important;
			}
			${media.lessThan("medium")`
        display: block;
      `}
		}
	}

	p {
		font-size: 1.125rem;
	}
`;
const VideoWrapper = styled.div`
	border-radius: 6px;
	overflow: hidden;
	width: 100%;
	position: relative;
	&:before {
		display: block;
		content: "";
		padding-top: 56.25%;
	}
	${media.lessThan("medium")`
        height: auto;
    `}
	> div,
  iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

const CTAButton = styled.a`
	text-align: center;
	color: white;
	font-weight: 700;
	background: #429f53;
	padding: 16px;
	display: block;
	margin: 0 0 8px;
	border-radius: 6px;
	font-size: 1.4rem;
	text-decoration: none;
	transition: 0.3s;
	&:hover {
		opacity: 0.7;
	}
`;

export { SejaPlusContainer, VideoWrapper, CTAButton };
