import styled from 'styled-components';
import media from 'styled-media-query';


const ReactionToolbarContainer = styled.div`
  display: flex;
  height: 40px;
  margin-top: 35px;
  ${media.lessThan('medium')`
    display: none;
  `}
`;
const ReactionsWrapper = styled.div`
  display: flex;
  > div{
    border: 1px solid #ddd;
    margin-right: 5px;
  }
   ${media.lessThan('medium')`
    display: none;
  `}
`;
const InteractionsCount = styled.div`
  display: flex;
  border: 1px solid #dddddd;
  border-radius: 100px;
  padding: 0 10px;
  height: 40px;
  margin: 0 10px 0 auto;
`
export { ReactionToolbarContainer, ReactionsWrapper, InteractionsCount };
