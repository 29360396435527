import styled, { css } from "styled-components";

import { Props } from "./index";

const CardBaseContainer = styled.div<Props>`
	display: flex;
	flex-direction: column;
	background: #fff;
	//border: 1px solid #efefef;
	box-sizing: border-box;
	border-radius: 6px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	padding: ${(props) => props.padding};
	margin: ${(props) => props.margin};
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	min-height: ${(props) => props.minHeight};
	align-items: ${(props) => props.alignItems};

	section {
		overflow: hidden;
	}

	${(props) =>
		props.grey &&
		css`
			background: #fafafa;
		`}
`;

export { CardBaseContainer };
