import styled from 'styled-components';
import { Props } from './index';

const RecomendedRibbonContainer = styled.div<Props>`
  display: flex;
  color: #FFF;
  min-width: 150px;
  width: 20%;
  min-height: 150px;
  position: absolute;
  top: 0; left:0;
  overflow: hidden;
  span {
    display: block;
    width: 100%;
    position: absolute;
    left: -50px;
    top: 30px;
    display: block;
    width: 200px;
    padding: 10px 0;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
    transform: rotate(-45deg);
    background: ${(props) => (props.background ? props.background : '#DD0000')};
    &:before, &:after {
      height: 100%;
      width: 13px;
      content: '';
      display: block;
      position: absolute;
      top: 0;
    }
    &:before {
      background: linear-gradient(to right, rgba(112, 0, 0, 0.54),rgba(186, 0, 0, 0));
      left: 30px;
      transform: skew(-45deg);
    }
    &:after {
      background: linear-gradient(to left, rgba(112, 0, 0, 0.54), rgba(186, 0, 0, 0));
      right: 30px;
      transform: skew(46deg);
    }
  }
`;
export { RecomendedRibbonContainer };
