import React, { ReactNode } from 'react';
import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Typography } from '../../../Typography';
import {
  CardWrapper,
} from '../..';
import {
  ViewIcon,
} from '../../Icons';
import {
  TeacherCardContainer, TeacherCardContent, TeacherCardFooter, TeacherCardHeader,
} from './TeacherCardStyled';

import { ITeacher } from '../../../../services/prod.teacher.service';
import { CircularAvatarPhoto } from '../../CircularAvatarPhoto';

export interface Props {
  teacher: ITeacher;
  editComponent: ReactNode;
  viewUrl?: string;
}

const TeacherCardWrapper: React.FC<Props> = ({
  teacher,
  editComponent,
  viewUrl,
}: Props) => (
  <TeacherCardContainer>
    <CardWrapper padding="0" margin="0">
      <TeacherCardHeader>
        <CircularAvatarPhoto avatarImg={teacher.avatar} avatarName={teacher.name} borderColor="primary" outerBorderSize="2px" borderType="double" />
        <Typography variant="title">{teacher.name}</Typography>
      </TeacherCardHeader>
      <TeacherCardContent>
        <Typography variant="text">{teacher.description || 'Sem descrição'}</Typography>
      </TeacherCardContent>
      <TeacherCardFooter>
        <Typography variant="subtitle" fontWeight="bold" size="0.7rem" color="#000000ee">
          Incluído em
          {' '}
          {teacher.createdAt.toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
          })}
        </Typography>
        <Box display="grid" gridTemplateColumns="1fr" gridGap="5px">
          {editComponent && editComponent }
          {viewUrl && (
          <Link to={viewUrl}>
            <ViewIcon size="20px" />
          </Link>
          )}
        </Box>
      </TeacherCardFooter>
    </CardWrapper>
  </TeacherCardContainer>
);

TeacherCardWrapper.defaultProps = {

};

export { TeacherCardWrapper as TeacherCard };
