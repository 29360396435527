import styled, { css } from "styled-components";
import { darken, lighten } from "polished";
import media from "../../../styles/media";
import { Props } from "./index";

const boxShadowSingle = css<Props>`
	box-shadow: 0 0 0 ${(props) => props.innerBorderSize} ${(props) => (props.borderColor === "white" ? "#FFF" : props.borderColor === 'primary' ? props.theme.color.primary : props.borderColor === undefined ? props.theme.color.primary : props.borderColor)}, 0 0 0 ${(props) => props.outerBorderSize} transparent;
`;
const boxShadowSingleDarken = css<Props>`
	box-shadow: 0 0 0 ${(props) => props.innerBorderSize} ${(props) => darken(0.15, props.borderColor ? props.borderColor === 'primary' ? props.theme.color.primary : props.borderColor : props.theme.color.primary)}, 0 0 0 ${(props) => props.outerBorderSize} transparent;
`;

const boxShadowSingleLighten = css<Props>`
  box-shadow: 0 0 0 ${(props) => props.innerBorderSize} ${(props) => lighten(0.15, props.theme.color.primary)}, 
  0 0 0 ${(props) => props.outerBorderSize} transparent;
`;
const boxShadowDouble = css<Props>`
	box-shadow: 0 0 0 ${(props) => props.innerBorderSize} ${(props) => (props.borderColor === "white" ? "#FFF" : lighten(0.15, props.theme.color.primary))},
		0 0 0 ${(props) => props.outerBorderSize} ${(props) => props.theme.color.primary};
`;

const boxShadowDoubleWhite = css<Props>`
	box-shadow: 0 0 0 ${(props) => props.innerBorderSize} #fff, 0 0 0 ${(props) => props.outerBorderSize} ${(props) => props.theme.color.primary};
`;

const boxShadowTriple = css<Props>`
	box-shadow: 0 0 0 3px white, 0 0 0 7px ${(props) => props.theme.color.primary}, 0 0 0 9px white;
`;
const boxShadowMonitor = css<Props>`
	box-shadow: 0 0 0 ${(props) => props.innerBorderSize} white, 0 0 0 ${(props) => props.outerBorderSize} ${(props) => props.theme.color.primary};
`;

const CircularAvatarPhotoContainer = styled.div<Props>`
	display: inline-block;
	margin: ${(props) => props.outerBorderSize};
	width: ${(props) => props.size};
	height: ${(props) => props.size};
	position: relative;
	transition: width 0.5s ease, height 0.5s ease, top 0.5s ease;

	.cameraIcon {
		position: absolute;
		top: 50%;
		right: -24px;
		transform: translateY(-50%);
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background: ${(props) => props.theme.color.primary};
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 9999;
		cursor: pointer;
		border: 2px solid #fff;
		transition: 0.3s;
		&:hover {
			background: ${(props) => lighten(0.15, props.theme.color.primary)};
		}
	}
	.roundContainer:hover ~ .cameraIcon {
		background: ${(props) => lighten(0.15, props.theme.color.primary)};
	}
	.roundContainer {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		position: relative;
		overflow: hidden;
		${(props) => {
		switch (props.borderType) {
			case "single":
				if (props.darken) {
					return boxShadowSingleDarken;
				}
				if (props.lighten) {
					return boxShadowSingleLighten;
				}
				return boxShadowSingle;
			case "double":
				return boxShadowDouble;
			case "double-white":
				return boxShadowDoubleWhite;
			case "triple":
				return boxShadowTriple;
			default:
				return "";
		}
	}}

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}
	.isMonitor {
		${(props) => {
		if (props.borderType === "triple") {
			return boxShadowTriple;
		}
		return boxShadowMonitor;
	}}
	}
	.isUserProfile {
		cursor: pointer;
	}
	span {
		position: absolute;
		bottom: -12px;
		left: 50%;
		transform: translateX(-50%);
		display: inline-block;
		border-radius: 5px;
		padding: 7px 10px;
		background: ${(props) => props.theme.color.primary};
		color: white;
		text-transform: uppercase;
		letter-spacing: 0.5px;
		min-width: 75%;
		text-align: center;
		font-size: 12px;
		${media.lessThan("medium")`
			bottom: -14px;
			padding: 4px 7px;
			font-size: 10px;
        `}
	}
	.user-place-holder {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		background: #fafafa;
	}
`;
export { CircularAvatarPhotoContainer };
