import styled from "styled-components";

import { NotificationType } from "./index";

const NotificationListContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const NotificationCard = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	padding: 12px;
	border-radius: 6px;
	border: 1px solid #ccc;
	margin: 0 0 15px;
	background: #fff;
	transition: 0.3s ease;
	&.faded {
		opacity: 0.5;
	}
	&:hover {
		opacity: 1;
	}
`;

const NotificationInfos = styled.div`
	width: 90%;
	a {
		text-decoration: none;
		transition: 0.3s;
		display: flex;
		align-items: center;
		&:hover {
			opacity: 0.6;
		}
	}
`;

const NotificationIcons = styled.div`
	width: 10%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	button {
		outline: none;
		background: none;
		margin: 0 0 0 8px;
		color: ${(props) => props.theme.color.primary};
		cursor: pointer;
		transition: 0.3s;
		&:hover {
			opacity: 0.6;
		}
	}
`;

const NotificationTitle = styled.big`
	width: 100%;
	font-size: 1.3rem;
	font-weight: 700;
	color: ${(props) => props.theme.color.primary};
`;

const NotificationDescription = styled.p`
	width: 100%;
	opacity: 0.8;
`;

const LoadMoreButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const GlobalHandlersContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-space-between;
	align-items: center;
	flex-wrap: wrap;
	margin: 10px 0 30px;
	.right {
		width: 25%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	.left {
		flex: 1;
	}
	span {
		margin: 0 10px;
		display: block;
		height: 20px;
		width: 1px;
		background: ${(props) => props.theme.color.primary};
	}
	button {
		outline: none;
		background: none;
		color: ${(props) => props.theme.color.primary};
		cursor: pointer;
		transition: 0.3s;
		display: flex;
		justify-content: center;
		align-items: center;
		svg {
			margin-left: 4px;
		}
		&:hover {
			opacity: 0.6;
		}
	}
`;

const NotificationTag = styled.span<{
	type: NotificationType;
}>`
	display: inline-block;
	padding: 4px;
	border-radius: 3px;
	text-transform: uppercase;
	color: white;
	font-weight: 600;
	font-size: 0.8rem;
	white-space: nowrap;
	margin-right: 8px;
	background: ${(props) => {
		switch (props.type) {
			case "Acompanhamento Tópico":
				return "#3FB8AF";

			case "Artigo":
				return "#FE4365";

			case "Multimídia":
				return "#B3CC57";

			case "Aula Liberada":
				return "#F7A541";

			case "Comentário Aula":
				return "#74D9EF";

			case "Material":
				return "#8257cc";

			default:
				return "#FFCD98";
		}
	}};
`;

export {
	//
	NotificationListContainer,
	NotificationCard,
	NotificationTitle,
	NotificationDescription,
	NotificationInfos,
	NotificationIcons,
	LoadMoreButtonWrapper,
	GlobalHandlersContainer,
	NotificationTag,
};
