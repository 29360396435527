import React, { ReactNode } from 'react';
import styled from 'styled-components';

export interface Props {
  marginLeft?: string;
  marginContainer?: string;
  children?: ReactNode;
}

const IconsRoundedGroupWrapper = styled.div<Props>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #EFEFEF;
    border-radius: 100px;
    padding: 10px 20px;
    position: relative;
    margin: ${(props) => props.marginContainer};
    >div{
      width: 100%;
      position: relative;
    }
    > div:not(:first-child){
        &::before{
          content: "";
          position: absolute;
          left: 0;
          margin-top: -10px;
          height: calc(100% + 20px);
          width: 1px;
          background-color: #EFEFEF;
        }
    }
   
`;

const IconsRoundedGroup: React.FC<Props> = ({
  children,
  ...props
}: Props) => (
  <IconsRoundedGroupWrapper {...props}>
    {children}
  </IconsRoundedGroupWrapper>
);

IconsRoundedGroup.defaultProps = {
  marginLeft: '15px',
  marginContainer: '0px',
};

export { IconsRoundedGroup };
