import styled from "styled-components";
import { lighten } from "polished";
import { Props } from "./index";

const TrailCardContainer = styled.div<Props>`
	width: 100%;
	height: 100%;
	.cardHeader {
		width: 100%;
		background: ${(props) => lighten(0.4, props.theme.color.primary)};
		padding: 20px;
		border-radius: 5px 5px 0 0;
		border-bottom: 5px solid ${(props) => props.theme.color.primary};
		span {
			display: block;
			font-weight: 700;
		}
		small {
			font-size: 0.8em;
			opacity: 0.6;
		}
	}
	.cardBody {
		background: #fff;
		color: rgba(0, 0, 0, 0.5);
		padding: 20px;
		font-weight: 700;
		border: 1px solid rgba(0, 0, 0, 0.15);
		border-radius: 0 0 5px 5px;
		border-top: none;
		b {
			color: ${(props) => props.theme.color.primary};
			font-size: 1.125em;
		}
	}
`;
export { TrailCardContainer };
