import jwt_decode from "jwt-decode";
import { useState, useEffect } from "react";
import LoginService from "../../../services/login.service";
import Cookies from "js-cookie";
//import ProducerInfoService from "../../../services/prodinfo.service";

// import * as serviceWorkerRegistration from "../../../serviceWorkerRegistration";

export default function useAuth() {
	const [userData, setUserData] = useState<undefined | any>(undefined);
	// eslint-disable-next-line
	const [tenantData, setTenantData] = useState<undefined | any>(undefined);
	const [authenticated, setAuthenticated] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		(async () => {
			const token = localStorage.getItem("token");
			const userInfo = localStorage.getItem("user_info");
			if (token && userInfo) {
				const decoded = jwt_decode(userInfo);
				setAuthenticated(true);
				setUserData(decoded);
				// serviceWorkerRegistration.register();
			}
			// const producerInfoService = new ProducerInfoService();
			// const response = await producerInfoService.get_site_info();
			// localStorage.setItem("tenant", response.tenant_id);
			// localStorage.setItem("theme", response.configs.tema);
			// setTenantData(response);
			setLoading(false);
		})();
	}, []);

	async function handleLogin(email: string, password: string) {
		const loginService = new LoginService();

		const response = await loginService.login(email, password);
		//console.log("response from useAuth", response);
		if (response.success) {
			const data = response.success;
			const decoded = jwt_decode(data.access);

			localStorage.setItem("token", data.access);
			localStorage.setItem("refresh_token", data.refresh);
			localStorage.setItem("user_info", data.access);

			const cookieData = JSON.stringify({
				"user_info": data.access,
				"refresh": data.refresh,
			});

			Cookies.set("transparentIntegration", cookieData, {
				expires: 15,
				domain: ".ciaautismo.com.br",
			});

			setUserData(decoded);
			setAuthenticated(true);
			// serviceWorkerRegistration.register();
			return { success: data };
		}
		return { error: response.error };
	}

	function handleLogout() {
		setAuthenticated(false);
		setUserData(undefined);
		if (Cookies.get("transparentIntegration")) {
			Cookies.remove("transparentIntegration", {
				domain: ".ciaautismo.com.br",
			});
		}
		localStorage.removeItem("redirectUrl");
		localStorage.removeItem("token");
		localStorage.removeItem("refresh_token");
	}

	return {
		authenticated,
		userData,
		tenantData,
		loading,
		handleLogin,
		handleLogout,
	};
}
