import styled from 'styled-components';
import { Props } from './index';

const PreviewModuleClassCardContainer = styled.div<Props>`
  display: flex;
  width: calc(${(props) => props.width} - 12px);
  margin-bottom: 12px;
  &:not(first-child){
    margin-right: 12px;
  }
`;
export { PreviewModuleClassCardContainer };
