import React from 'react';
import { IoIosEye } from "react-icons/io";

import  { IconWrapper } from '../IconWrapper'

export interface Props extends React.HTMLAttributes<HTMLElement> {
  rounded?: boolean;
  backgroundColor?: string;
  color?: string;
  size?: string;
}

const ViewIconWrapper: React.FC<Props> = ({
  ...props
}: Props) => {
  return (
    <IconWrapper
      {...props}
      backgroundColor={props.backgroundColor}
      size={props.size}
      rounded={props.rounded}
      icon={<IoIosEye size="60%" color={props.color}/>} />
  );
};

ViewIconWrapper.defaultProps = {
  rounded: true,
  color: "#FFF",
  size: "32px"
}

export { ViewIconWrapper as ViewIcon };