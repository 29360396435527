import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import { IoIosSearch } from 'react-icons/io';
import { SearchBarContainer } from './SearchBarStyled';
import { TContentType } from '../../../../types/helpers';


export interface Props {
  helpText?: string;
  contentType: TContentType;
}


const SearchBarWrapper: React.FC<Props> = ({
  helpText,
}: Props) => (
  <SearchBarContainer>
    <form noValidate autoComplete="off">

      <TextField
        fullWidth
        id="search"
        variant="outlined"
        color="primary"
        placeholder="O que você procura"
        helperText={helpText}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IoIosSearch size="24" />
            </InputAdornment>
          ),
        }}
      />
    </form>
  </SearchBarContainer>
);

// SearchBarWrapper.defaultProps = {
//   background: '#FFF',
// };

export { SearchBarWrapper as SearchBar };
