import styled from "styled-components";
import { fade } from "@material-ui/core/styles/colorManipulator";

const SearchBarContainer = styled.div`
	display: flex;

	form {
		width: 100%;
	}
	.MuiOutlinedInput-input {
		padding: 0px;
	}
	.MuiInputBase-input {
		height: 35px;
		font-size: 0.875rem;
		color: #9a9a9a;
		border-radius: 0px;
		::placeholder {
			color: #9a9a9a;
		}
	}
	.MuiInputBase-root {
		border-radius: 0px;
	}
	.MuiOutlinedInput-root {
		&:hover {
			.MuiOutlinedInput-notchedOutline {
				border-color: ${(props) => fade(props.theme.color.primary, 0.5)};
			}
		}
		&.Mui-focused {
			.MuiOutlinedInput-notchedOutline {
				border-color: ${(props) => props.theme.color.primary};
				border-width: 1px;
			}
		}
	}
	.MuiOutlinedInput-notchedOutline {
		border-color: #f2f2f2;
	}
	.MuiFormHelperText-root {
		position: absolute;
		right: 0;
		top: 25%;
		margin-top: 0;
		font-size: 0.75rem;
		color: #a3a3a3;
	}
`;
export { SearchBarContainer };
