import React from 'react';

import { IconWrapper } from '../IconWrapper';


export interface Props extends React.HTMLAttributes<HTMLElement> {
  rounded?: boolean;
  filled?: boolean;
  backgroundColor?: string;
  color?: string;
  size?: string;
  iconSize?: string;
  tooltipText?: string;
  primaryColor?: boolean;
}


const PlayListIconWrapper: React.FC<Props> = ({
  children,
  ...props
}: Props) => (
  <IconWrapper
    {...props}
    tooltipText={props.tooltipText}
    backgroundColor={props.backgroundColor}
    size={props.size}
    rounded={props.rounded}
    primaryColor={props.primaryColor}
    icon={(
      <svg xmlns="http://www.w3.org/2000/svg" width={props.iconSize} height="auto" viewBox="0 0 26 23" fill={props.color}>
        <path d="M12.1686 19.8442H1.5779C0.695347 19.8442 0 20.5663 0 21.4221C0 22.278 0.722091 23 1.5779 23H12.1686C13.0511 23 13.7465 22.278 13.7465 21.4221C13.7465 20.5663 13.0511 19.8442 12.1686 19.8442Z" />
        <path d="M12.1686 13.2117H1.5779C0.695347 13.2117 0 13.9338 0 14.7896C0 15.6454 0.722091 16.3675 1.5779 16.3675H12.1686C13.0511 16.3675 13.7465 15.6454 13.7465 14.7896C13.7465 13.9338 13.0511 13.2117 12.1686 13.2117Z" />
        <path d="M23.829 6.60571H1.5779C0.695347 6.60571 0 7.3278 0 8.18362C0 9.03943 0.722091 9.76152 1.5779 9.76152H23.8023C24.6848 9.76152 25.3802 9.03943 25.3802 8.18362C25.3802 7.3278 24.6848 6.60571 23.829 6.60571Z" />
        <path d="M1.5779 3.15581H23.8023C24.6848 3.15581 25.3802 2.43372 25.3802 1.5779C25.3802 0.722091 24.6581 0 23.8023 0H1.5779C0.695347 0 0 0.722091 0 1.5779C0 2.43372 0.722091 3.15581 1.5779 3.15581Z" />
        <path d="M24.6315 16.8486L18.8815 13.5591C17.892 12.9975 16.6885 13.6928 16.6885 14.8428V21.3951C16.6885 22.5184 17.892 23.2137 18.8815 22.6789L24.6315 19.3893C25.5943 18.8277 25.5943 17.4103 24.6315 16.8486Z" />
      </svg>
      )}
  />
);

PlayListIconWrapper.defaultProps = {
  rounded: true,
  backgroundColor: '#E0E0E0',
  color: '#FFF',
  size: '50px',
  iconSize: '20px',
  filled: true,
};

export { PlayListIconWrapper as PlayListIcon };
