import styled from "styled-components";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { CardWrapper } from "../CardStyleBase";
import { Props } from "./index";

const CustomPlaylistCardContainer = styled(CardWrapper)<Props>`
	background-color: ${(props) => fade(props.theme.color.primary, 0.3)};
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
`;
export { CustomPlaylistCardContainer };
