import styled from "styled-components";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { ModuleCardContainer } from "../ModuleCard/ModuleCardStyled";
import media from "../../../../../styles/media";

import { Props } from "./index";

const ModuleCardWrapperContainer = styled.div<Props>`
	margin-bottom: 12px;
	/* margin-top: 70px; */
	position: relative;
	${(props) => {
		if (props.module.hasSeparador) {
			return `
				&:before {
					content: '';
					width: 100%;
					border-top: 2px solid #ccc;
					position: absolute;
					top: -27px;
					left: 0;
				}
			`;
		}
	}}
	${(props) => {
		if (props.module.textoSeparador) {
			return `
				&:after {
					content: '${props.module.textoSeparador}';
					display: block;
					position: absolute;
					top: -18px;
					left: 0;
					font-weight: bold;
					opacity: .7;				
					font-size: 0.9rem;
				}
			`;
		}
	}}
	${(props) => {
		if (props.module.textoSeparador && props.module.hasSeparador && props.module.textoSeparador.length > 70) {
			return `
				margin-top: 90px;
				&:before {
					top: -80px;
				}
				&:after {
					top: -74px;
				}
			`;
		}
		if (props.module.textoSeparador && props.module.hasSeparador && props.module.textoSeparador.length > 40) {
			return `
				margin-top: 70px;
				&:before {
					top: -60px;
				}
				&:after {
					top: -54px;
				}
			`;
		}
		if (props.module.textoSeparador && props.module.hasSeparador) {
			return `
				margin-top: 50px;
				&:before {
					top: -40px;
				}
				&:after {
					top: -34px;
				}
			`;
		}
		if (props.module.hasSeparador) {
			return `
				margin-top: 40px;
				&:before {
					top: -27px;
				}
			`;
		}
	}}
	${(props) => {
		if (props.module.textoSeparador && !props.module.hasSeparador) {
			return `
				&:after {
					display: none;
				}
			`;
		}
	}}
	&.active {
		${ModuleCardContainer} {
			background-color: ${(props) => {
				if (props.module.cor) {
					return fade(props.module.cor, 0.3);
				}
				return fade(props.theme.color.primary, 0.3);
			}};
			border-bottom-right-radius: 0px;
			border-bottom-left-radius: 0px;
		}
		&.blocked {
			${ModuleCardContainer} {
				border-bottom-right-radius: 6px;
				border-bottom-left-radius: 6px;
			}
		}
	}
	section {
		overflow: hidden;
	}
	${media.lessThan("medium")`
        width: 90% !important;
    `}
`;
export { ModuleCardWrapperContainer };
