import React, { ReactNode } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';

import { Button, Loading } from '../../../elements';

export interface Props {
    children: ReactNode
    create?: ReactNode
    title: string
    open: boolean
    loading?: boolean
    handleClose: () => void
    saveAction: () => void
    maxWidth?: false | 'lg' | 'xs' | 'sm' | 'md' | 'xl' | undefined
}

const ModalBase: React.FC<Props> = ({
  children,
  create,
  open,
  title,
  maxWidth,
  saveAction,
  handleClose,
  loading,
  ...props
}: Props) => (

  <Dialog
    fullWidth
    maxWidth={maxWidth !== undefined ? maxWidth : 'lg'}
    scroll="paper"
    open={open}
    onClose={handleClose}
    aria-labelledby="max-width-dialog-title"
  >
    <DialogTitle id="max-width-dialog-title">
      <Box display="flex" justifyContent="space-between">
        {title}
        {create && create}
      </Box>
    </DialogTitle>
    <DialogContent>
      <Box padding="20px 0 40px">
        {loading && <Loading variant="modal" />}
        <Box display={loading ? 'none' : 'block'}>{children}</Box>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button variant="link" onClick={handleClose}>
        Cancelar
      </Button>

      {loading
        ? <Button variant="loading">Salvar</Button>
        : <Button variant="save" onClick={saveAction}>Salvar</Button>}
    </DialogActions>
  </Dialog>

);

export { ModalBase };
