import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import { TagInput } from 'reactjs-tag-input';
import { Typography } from '../..';

interface Props {
    name: string;
    label?: string;
};

type ArrayTextInputProps = JSX.IntrinsicElements['input'] & Props;

const ArrayTextInput: React.FC<ArrayTextInputProps> = (
  {
    name, label, type, ...rest
  }: ArrayTextInputProps,
) => {
  const inputRef = useRef<any>(null);
  const {
    fieldName, defaultValue, registerField, error,
  } = useField(name);
  const [tags, setTags] = useState<{ id: number, displayValue: string }[]>(defaultValue || []);

  const onTagsChanged = (tags: {id:number, displayValue: string}[]) => {
    setTags(tags);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue() {
        const t = tags.map((item) => item.displayValue);
        return t;
      },
    });
  }, [defaultValue, fieldName, registerField, tags]);

  return (
    <>
      {label
      && (
      <Typography variant="title">
        {' '}
        {label}
      </Typography>
      )}
      <TagInput
        tags={defaultValue || []}
        ref={inputRef}
        placeholder="Digite o nome da Tag e aperte enter."
        onTagsChanged={onTagsChanged}
        tagStyle={`
        background: rgba(153, 153, 153, 0.2);
        border-radius: 24px;
        color: rgba(0, 0, 0, 0.87);
        font-size: 13px;
        line-height: 20px;
        padding: 5px 10px;
        letter-spacing: 0;
      `}
        wrapperStyle={`
                background: #FFF;
                border: 1px solid #E0E0E0;
                border-radius: 6px;
                position: relative;
                width: 100%;
                box-shadow: none;
                `}
        inputStyle={`
            background: #FFF;
            &::-webkit-input-placeholder {
                color: #828282;
            }
        `}
        tagDeleteStyle={`
            color: #FFF;
            background: rgba(0, 0, 0, 0.54);
            width: 20px;
            height: 20px;
            border-radius: 100px;
            display: inline-block;
            text-align: center;
            margin-left: 8px;
        `}
      />
      {error
        && (
        <Typography color="#f44336" margin="-15px 0 0 0" size="0.75rem" variant="text">
          {error}
        </Typography>
        )}
    </>
  );
};

export { ArrayTextInput };
