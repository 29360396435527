import React, { ReactNode } from "react";
import { GamificationBadgeContainer } from "./GamificationBadgeStyled";

export interface Props {
	name?: string;
	slug?: string;
	isAcquired: boolean;
	imgOnUrl: string;
	imgOffUrl?: string;
	size?: number;
}

const GamificationBadgeWrapper: React.FC<Props> = ({ ...props }: Props) => {
	return (
		<GamificationBadgeContainer {...props} title={props.name}>
			{props.isAcquired ? <img src={props.imgOnUrl} alt={props.name ? props.name : ""} /> : <>{props.imgOffUrl ? <img src={props.imgOffUrl} alt={props.name ? props.name : ""} /> : ""}</>}
		</GamificationBadgeContainer>
	);
};

export { GamificationBadgeWrapper as GamificationBadge };
