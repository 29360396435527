import React from "react";
import { IRepliedComment } from "../types/debate";

export interface IComment {
	aprovado: boolean;
	aula: number;
	comentarios: IComment[];
	concluiu_curso_terapia_avancada_comportamental: boolean;
	count_curtidas: number;
	curtiu: boolean;
	created: string;
	criado_por: {
		id: number;
		nome: string;
		username: string;
		imagem_url: string;
		perfil_url: string;
		aluno: {
			aluno_fundador: boolean;
			cidade: string | null;
			profissao: string | null;
			uf: string;
		};
	};
	criado_por_aluno: boolean;
	descricao: string;
	has_finish_masterclass: boolean;
	id: number;
	is_assinante: boolean;
	is_mentor: boolean;
	is_professor: boolean;
	is_tutor: boolean;
	is_new: boolean;
	comentario_pai: null | IRepliedComment;
}

const parseProfileUrl = (url: string) => {
	const id = url.split("/")[4];
	return id;
};

const formatSingle = (cmt: any) => {
	// console.log("FORMAT SINGLE", { cm: cmt, cp: cmt.criado_por });
	return {
		author: {
			id: cmt.criado_por.id,
			name: cmt.criado_por.nome,
			isMonitor: cmt.is_mentor,
			isProfessor: cmt.is_professor,
			isTutor: cmt.is_tutor,
			imageUrl: cmt.criado_por.imagem_url,
			profileId: parseProfileUrl(cmt.criado_por.perfil_url),
			address: `
				${
					cmt.criado_por.aluno
						? `
				${cmt.criado_por.aluno.cidade && cmt.criado_por.aluno.uf ? `${cmt.criado_por.aluno.cidade}/${cmt.criado_por.aluno.uf}` : ""}`
						: ""
				}
			`,
			profession: cmt.criado_por.aluno ? cmt.criado_por.aluno.profissao : "",
		},
		id: cmt.id,
		text: cmt.descricao,
		date: cmt.created,
		reactions: [],
		userReactions: [],
		isNew: cmt.is_new,
		isLiked: cmt.curtiu,
		likeCount: cmt.count_curtidas,
		repliedComment: cmt.comentario_pai,
	};
};

function printList(value: any, next?: any): any {
	let nx = next;
	if (next && next.length) {
		nx = next[0];
		return [value, ...printList(nx, nx.comentarios)];
	}
	return [value];
}

const formatInnerComments = (comentarios: any) => {
	return comentarios.map((comentario: any) => {
		let ls: any = [];

		printList(comentario, comentario.comentarios).map((cmt: any) => {
			ls.push(formatSingle(cmt));
		});

		return ls;
	});
};

const formatComments = (comments: any) => {
	const formated_comments = comments.map((comment: IComment) => {
		return {
			id: comment.id,
			title: `${comment.criado_por.nome} em ${comment.created}`,
			comment: {
				author: {
					name: comment.criado_por.nome,
					id: comment.criado_por.id,
					profileId: parseProfileUrl(comment.criado_por.perfil_url),
					imageUrl: comment.criado_por.imagem_url,
					isMonitor: comment.is_mentor,
					isProfessor: comment.is_professor,
					isTutor: comment.is_tutor,
					isPlus: comment.is_assinante,
					isFounder: comment.criado_por.aluno ? comment.criado_por.aluno.aluno_fundador : false,
					address: `${comment.criado_por.aluno.cidade}/${comment.criado_por.aluno.uf}`,
					profession: comment.criado_por.aluno.profissao,
				},
				text: comment.descricao,
				date: comment.created,
				reactions: [],
				userReactions: [],
				id: comment.id,
				isNew: comment.is_new,
				isLiked: comment.curtiu,
				likeCount: comment.count_curtidas,
				repliedComment: comment.comentario_pai,
			},
			comments: comment.comentarios ? formatInnerComments(comment.comentarios).flat() : [],
			studentsCount: 0,
			commentsCount: 0,
			isFavorite: false,
			isNotificationOn: false,
		};
	});

	return formated_comments;
};

export default formatComments;
