import React, { useEffect, useState } from "react";

import { RedirectionContainer } from "./RedirectionStyled";
import { useUserContext } from "../../context/user/AuthContext";
import { useHistory } from "react-router-dom";

import TermsService from "../../services/terms.service";

export interface Props {
	background?: string;
}

const Redirection: React.FC<Props> = ({ children, background = "#FFF", ...props }) => {
	const history = useHistory();
	const { userData } = useUserContext();

	const checkAndRedirect = async () => {
		const termsService = new TermsService();
		const response = await termsService.list();

		//console.log("RESPONSE => ", response);

		if (userData) {
			const userGroups = userData.grupos.split(",");
			const redirectUri = localStorage.getItem("redirectUrl");

			if (response[0]) {
				return history.push(`/contratos/${response[0].id}`); // se tiver contrato, manda pra assinar
			}

			if (redirectUri) {
				localStorage.removeItem("redirectUrl");
				return history.push(redirectUri); // se tiver uma url pra redirect, manda pra lá
			}

			if (userGroups.includes("Alunos") || userGroups.includes("NMAAlunos")) {
				return history.push("/dashboard"); // se for assinante, manda pra home
			}

			return history.push("/cursos"); // se não for assinante manda pra /cursos
		}
	};

	useEffect(() => {
		checkAndRedirect();
	}, []);
	return (
		<RedirectionContainer background={background} {...props}>
			{/* Loading... */}
		</RedirectionContainer>
	);
};

export { Redirection };
