import React, { useCallback, useEffect, useState } from "react";
import LikeCommentService from "../../services/likeComment.service";
import { CommentLikeButtonContainer } from "./CommentLikeButtonStyled";
import loadingImg from "../../assets/images/loading.gif";

export interface Props {
	liked: boolean;
	likeCount: number;
	commentId: number | string;
	type: "lesson" | "comunity" | "article";
}

const CommentLikeButtonWrapper: React.FC<Props> = ({ liked, likeCount, commentId, ...props }: Props) => {
	const [curtido, setCurtido] = useState(liked);
	const [count, setCount] = useState(likeCount);
	const [loading, setLoading] = useState(false);
	const likeComment = useCallback(async () => {
		setLoading(true);
		const likeCommentService = new LikeCommentService(props.type);
		const response = await likeCommentService.like(commentId);
		//console.log("LIKED COMMENT => ", response);
		if (curtido) {
			setCount(count - 1);
		} else {
			setCount(count + 1);
		}
		setCurtido(!curtido);
		setLoading(false);
	}, [curtido, count, loading]);
	return (
		<CommentLikeButtonContainer likeCount={likeCount} liked={liked} commentId={commentId} {...props}>
			{count > 0 && <span className='counter'>{count}</span>}

			<button onClick={likeComment} title={curtido ? "Você curtiu" : "Curtir Comentário"}>
				{loading ? (
					<img src={loadingImg} alt='carregando' width='21' height='21' />
				) : (
					<svg className={curtido ? "liked" : ""} viewBox='0 0 512 512' height='21px' width='21px' xmlns='http://www.w3.org/2000/svg'>
						<path d='M198 448h172c15.7 0 28.6-9.6 34.2-23.4l57.1-135.4c1.7-4.4 2.6-9 2.6-14v-38.6c0-21.1-17-44.6-37.8-44.6H306.9l18-81.5.6-6c0-7.9-3.2-15.1-8.3-20.3L297 64 171 191.3c-6.8 6.9-11 16.5-11 27.1v192c0 21.1 17.2 37.6 38 37.6zM48 224h64v224H48z'></path>
					</svg>
				)}
			</button>
		</CommentLikeButtonContainer>
	);
};

CommentLikeButtonWrapper.defaultProps = {};

export { CommentLikeButtonWrapper as CommentLikeButton };
