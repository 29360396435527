import React from 'react';
import { NotificationTag } from '../NotificationTag';
import { CircularAvatarPhoto } from '../../../elements/CircularAvatarPhoto';
import { ImageWrapper } from '../../../elements/ImageWrapper';

import {
  NotificationContainer, NotificationDate, NotificationText, NotificationInfoWrapper,
} from './NotificationStyled';

export interface Props {
  background?: string;
  notification: {
    type: string,
    image: string,
    date: string,
    text: string,
    hasPhoto?: boolean,
  };
}

const NotificationWrapper: React.FC<Props> = ({
  ...props
}: Props) => {
  const { notification } = props;
  return (
    <NotificationContainer {...props}>
      <div className="imgAvatar">
        {notification.type === 'monitor'
          ? <CircularAvatarPhoto avatarImg={notification.image} size="100px" isMonitor borderType="triple" />
          : <ImageWrapper src={notification.image} alt={notification.text} width="155px" height="87px" />}
      </div>
      <NotificationInfoWrapper {...props}>
        <NotificationTag type={notification.type} />
        <NotificationDate {...props}>{notification.date}</NotificationDate>
        <NotificationText>{notification.text}</NotificationText>
      </NotificationInfoWrapper>
    </NotificationContainer>
  );
};

NotificationWrapper.defaultProps = {
  background: '#FFF',
};

export { NotificationWrapper as Notification };
