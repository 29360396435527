import styled from "styled-components";
import media from "../../../styles/media";

const IssueCertificateLinkContainer = styled.button`
	margin-bottom: 10px;
	border-radius: 6px;
	padding: 25px 0;
	width: 100%;
	height: 80px;
	font-size: 1.125rem;
	font-weight: 700;
	cursor: pointer;
	outline: none;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	text-transform: uppercase;
	background: ${(props) => props.theme.color.primary};
	transition: 0.3s;
	&:hover {
		opacity: 0.7;
	}
	img.loader {
		max-width: 50px;
	}
	svg {
		margin-right: 10px;
	}
	${media.lessThan("medium")`
		padding: 15px 0;
		height: auto;
  	`}
`;
export { IssueCertificateLinkContainer };
