import React, { useEffect, useState } from 'react';
import { ClassRatingContainer, RatingTag } from './ClassRatingStyled'
import { Rating } from 'react-simple-star-rating'


export interface Props {
  lessonID: number | string;
  current: number;
  onRate: (rate: number) => void;
  // numberOfRatings: number;
}

const ClassRatingWrapper: React.FC<Props> = ({
  lessonID,
  current,
  // numberOfRatings,
  onRate
}: Props) => {
  const [currentRating, setCurrentRating] = useState<number>(0)


  useEffect(() => {
    setCurrentRating(current)
  }, [current])

  return (
    <ClassRatingContainer>
      <RatingTag>Avalie esta aula</RatingTag>
      <div>
        <Rating
          // allowFraction
          initialValue={currentRating ? currentRating : 0}
          size={24}
          onClick={onRate}
        />
        <span className='numberOfRatings'>({currentRating})</span>
      </div>
    </ClassRatingContainer>
  );
};

ClassRatingWrapper.defaultProps = {

}

export { ClassRatingWrapper as ClassRating };