import React from 'react'
import { Link } from 'react-router-dom'
import { CourseCardReturn } from './courseCardReturn'

export const CourseCardLink = (curso: any) => {
    return (
        <Link className='courseLink' to={`/cursos/${curso.slug}`}>
            {CourseCardReturn(curso)}
        </Link>
    )
}