import { Box } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { Typography, PageContainer, GreyContainer, InnerContentContainer, ContentLoadingError } from "../../components";
import { SquareCard } from "../../components/elements";

import { MultimidiasContainer } from "./MultimidiasStyled";
import MultimidiasVideosService from "../../services/multimidiaVideos.service";
import { HomePageFeatureSlider } from "../../components/features/HomePageFeatureSlider";

export interface Props {
	background?: string;
}

interface IMultimidiaCategory {
	descricao: string;
	id: number;
	imagem: string;
	nome: string;
	slug: string;
	url: string;
}

const Multimidias: React.FC<Props> = ({ children, ...props }) => {
	const [multimidias, setMultimidias] = useState<IMultimidiaCategory[]>([]);

	const getMultimidias = useCallback(async () => {
		const multimidiasVideosService = new MultimidiasVideosService();
		const response = await multimidiasVideosService.list();
		setMultimidias(response);
		//console.log(response);
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
		getMultimidias();
	}, []);

	return (
		<PageContainer pageTitle='Séries' showSearch={false} showBreadcrumb plusExclusive>
			<GreyContainer>
				<InnerContentContainer>
					<HomePageFeatureSlider location="SERIES" />
					<MultimidiasContainer>
						<Box
							width='100%'
							display='grid'
							gridTemplateColumns={{
								xs: "100%",
								sm: "100%",
								md: "24% 24% 24% 24%",
								// xl: '19% 19% 19% 19% 19%',
							}}
							gridRowGap='15px'
							gridColumnGap='1%'>
							{multimidias ? (
								<>
									{multimidias.map((multimidia: IMultimidiaCategory) => {
										return (
											<SquareCard
												key={multimidia.id}
												to={`/serie/${multimidia.slug}`}
												imageurl={multimidia.imagem || "https://ajuda.nossomundoazul.com.br/other-assets/multimidia-fallback.jpg"}
												title={multimidia.nome}
												description={multimidia.descricao}></SquareCard>
										);
									})}
								</>
							) : (
								<ContentLoadingError refresh={getMultimidias} />
							)}
						</Box>
					</MultimidiasContainer>
				</InnerContentContainer>
			</GreyContainer>
		</PageContainer>
	);
};

export { Multimidias };
