import React, {
  useEffect, useRef, InputHTMLAttributes, ReactNode,
} from 'react';
import { useField } from '@unform/core';
import { Box } from '@material-ui/core';
import { MultipleRelatedCheckboxInputWrapper } from './CheckboxInputStyled';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    options: {
        id: string;
        value: string;
        component: ReactNode
    }[];
}
const MultipleRelatedCheckboxInput: React.FC<Props> = ({
  name, options, ...rest
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue = [] } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => refs.filter((ref) => ref.checked).map((ref) => ref.value),
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach((ref) => {
          ref.checked = false;
        });
      },
      setValue: (refs: HTMLInputElement[], values: string[]) => {
        refs.forEach((ref) => {
          if (values.includes(ref.id)) {
            ref.checked = true;
          }
        });
      },
    });
  }, [defaultValue, fieldName, registerField]);
  return (
    <Box display="grid" gridTemplateRows="auto" gridRowGap="5px">
      {options.map((option, index) => (
        <MultipleRelatedCheckboxInputWrapper>
          <Box display="flex" alignItems="center" width="100%">
            <input
              defaultChecked={defaultValue.find((dv: string) => dv === option.id)}
              ref={(ref) => {
                inputRefs.current[index] = ref as HTMLInputElement;
              }}
              className="checkbox-input"
              value={option.value}
              type="checkbox"
              id={option.id}
              {...rest}
            />
            <label htmlFor={option.id} key={option.id}>
              {option.component}
            </label>
          </Box>
        </MultipleRelatedCheckboxInputWrapper>
      ))}
    </Box>
  );
};
// MultipleRelatedCheckboxInput.defaultProps = {
//   gridTemplateColumns: '100%',
// };
export { MultipleRelatedCheckboxInput };
