import BaseService from "./base.service";
import api from "./api";
import { IVideoStats } from "../types/course";
export default class VideoPlayerEventsService extends BaseService {
	constructor() {
		super("cursos/aula");
	}
	async assistirVideo(id: any, final: number, concluida: boolean, videoOptions: IVideoStats) {
		try {
			const response = await api.put(
				`${this.resource}/assistir-video/${id}/`,
				{
					final,
					concluida,
					videoOptions,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			// console.log("Progresso da aula => ", response.data);

			return response.data;
		} catch (err) {
			console.error(`erro ao enviar informações do vídeo ${this.resource} :`, err);
			return {
				error: true,
			};
		}
	}

	async pausarVideo(id: number, pause_time: number, tempo_total_aula: number, videoOptions: IVideoStats) {
		try {
			const response = await api.post(
				`${this.resource}/pausar-video/${id}/`,
				{
					pause_time,
					tempo_total_aula,
					videoOptions,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			// console.log("Aula pausada com Sucesso => ", response.data);

			return response.data;
		} catch (err) {
			console.error(`erro ao enviar informações do vídeo ${this.resource} :`, err);
			return {
				error: true,
			};
		}
	}

	async iniciarAssistir(idAula: any, inicio: number, tempo_total_aula: number, videoOptions: IVideoStats, id?: number) {
		let optionsWVID, optionsWoVID, optionsToUse;

		optionsWVID = {
			inicio,
			tempo_total_aula,
			id,
		};
		optionsWoVID = {
			inicio,
			tempo_total_aula,
		};

		optionsToUse = id ? optionsWVID : optionsWoVID;

		try {
			const response = await api.post(
				`${this.resource}/${idAula}/iniciar-assistir-video/`,
				{ ...optionsToUse, videoOptions },
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			// console.log("Aula iniciada com Sucesso => ", response.data);

			return response.data;
		} catch (err) {
			console.error(`erro ao enviar informações do vídeo ${this.resource} :`, err);
			return {
				error: true,
			};
		}
	}
}
