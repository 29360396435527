import styled, { css } from "styled-components";
import { darken } from "polished";

const FilterTagsContainer = styled.div`
	display: flex;
	background: #fff;
	column-gap: 10px;
	padding: 7px 20px;
	border-top: 1px solid #f2f2f2;
`;

interface TagProps {
	isActive?: boolean;
}

const Tag = styled.button<TagProps>`
	border-radius: 100px;
	font-weight: 400;
	height: 30px;
	padding: 0 20px;
	line-height: 30px;
	font-size: 0.75rem;
	color: #222b37;
	cursor: pointer;
	box-sizing: border-box;
	background-color: #f2f2f2;
	border: 1px solid #e0e0e0;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	&:focus {
		border: 1px solid #e0e0e0;
	}
	&:hover {
		background-color: ${darken(0.2, "#E0E0E0")};
	}
	${(props) =>
		props.isActive &&
		css`
			color: #fff;
			background-color: ${props.theme.color.primary};
			transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			&:hover {
				background-color: ${darken(0.2, props.theme.color.primary)};
			}
		`}
`;

export { FilterTagsContainer, Tag };
