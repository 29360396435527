import {
  CustomPlaylistType,
} from '../types/custom_playlist';

import { moduleClassData } from './courseDataMock';

const customPlaylistNamesData = (): CustomPlaylistType[] => [
  {
    id: 1,
    name: 'O Mundo Azul é Lá Fora',
    lessonsCount: 3,
    created: ' 21 de junho de 2020',
    lessons: [moduleClassData({}),
      moduleClassData({
        id: 2, materialsCount: 3, name: 'Passeando com uma criança autista no shopping', releaseDate: '23 junho 2020', videoUrl: 'https://www.youtube.com/watch?v=_wSgzjaDW2k',
      }),
      moduleClassData({
        id: 3, courseName: 'Curso outro', moduleName: 'Outro modulo qualquer', materialsCount: 3, name: '9 Barreiras que dificultam o desenvolvimento infantil', releaseDate: '26 junho 2020', videoUrl: 'https://www.youtube.com/watch?v=mLjM43oUKgI',
      })],
  },
];


export { customPlaylistNamesData };
