import React from "react";
import { IoNewspaperOutline } from "react-icons/io5";

interface IIconProps {
	color: string;
}

const SVG = ({ color }: IIconProps) => (
	<IoNewspaperOutline color='#f0c200' size={26} />

	// <svg id='Componente_3_1' data-name='Componente 3 – 1' width='30.42' height='21.553' viewBox='0 0 35.42 26.553'>
	// 	<defs>
	// 		<linearGradient id='linear-gradient-comunidade' x1='0.925' y1='0.029' x2='0.048' y2='1.115' gradientUnits='objectBoundingBox'>
	// 			<stop offset='0' stop-color='#f0c200' />
	// 			<stop offset='1' stop-color='#f0a961' />
	// 		</linearGradient>
	// 		<clipPath id='clip-path-comunidade'>
	// 			<rect id='Retângulo_82' data-name='Retângulo 82' width='35.42' height='26.553' fill='url(#linear-gradient-comunidade)' />
	// 		</clipPath>
	// 	</defs>
	// 	<g id='Grupo_92' data-name='Grupo 92' clipPath='url(#clip-path-comunidade)'>
	// 		<path
	// 			id='Caminho_45'
	// 			data-name='Caminho 45'
	// 			d='M31.7,14.271a4.409,4.409,0,1,0-7.3,0,7.343,7.343,0,0,0-1.773,1.416,13.318,13.318,0,0,0-4.376-2.894,7.366,7.366,0,1,0-9.953,0A13.3,13.3,0,0,0,0,25.1a1.455,1.455,0,0,0,2.909,0,10.367,10.367,0,1,1,20.735,0,1.455,1.455,0,0,0,2.909,0,13.2,13.2,0,0,0-2.067-7.106,4.458,4.458,0,0,1,8.024,2.673V25.1a1.455,1.455,0,1,0,2.909,0V20.666a7.371,7.371,0,0,0-3.715-6.4M8.82,7.367a4.456,4.456,0,1,1,4.456,4.456A4.461,4.461,0,0,1,8.82,7.367M26.553,11.8a1.5,1.5,0,1,1,1.5,1.5,1.5,1.5,0,0,1-1.5-1.5'
	// 			transform='translate(0 -0.001)'
	// 			fill='url(#linear-gradient-comunidade)'
	// 		/>
	// 	</g>
	// </svg>

	// <svg
	//   width="26px"
	//   height="41"
	//   viewBox="0 0 41 41"
	//   fill={color}
	//   xmlns="http://www.w3.org/2000/svg"
	// >
	//   <path d="M12.1719 20.5C15.3561 20.5 17.9375 17.9186 17.9375 14.7344C17.9375 11.5501 15.3561 8.96875 12.1719 8.96875C8.98761 8.96875 6.40625 11.5501 6.40625 14.7344C6.40625 17.9186 8.98761 20.5 12.1719 20.5Z" />
	//   <path d="M18.7383 23.7031C16.4833 22.558 13.9945 22.1016 12.1719 22.1016C8.60199 22.1016 1.28125 24.2909 1.28125 28.668V32.0312H13.293V30.7444C13.293 29.2229 13.9336 27.6974 15.0547 26.4258C15.9492 25.4104 17.2016 24.4679 18.7383 23.7031Z" />
	//   <path d="M27.2266 23.0625C23.0569 23.0625 14.7344 25.6378 14.7344 30.75V34.5938H39.7188V30.75C39.7188 25.6378 31.3962 23.0625 27.2266 23.0625Z" />
	//   <path d="M27.2266 20.5C31.1184 20.5 34.2734 17.345 34.2734 13.4531C34.2734 9.56124 31.1184 6.40625 27.2266 6.40625C23.3347 6.40625 20.1797 9.56124 20.1797 13.4531C20.1797 17.345 23.3347 20.5 27.2266 20.5Z" />
	// </svg>
);

export { SVG as ComunitySidebarIcon };
