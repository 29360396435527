// @ts-nocheck
import React from "react";

interface IIconProps {
	color: string;
}

const SVG = ({ color }: IIconProps) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="25px" height="26px" version="1.1" viewBox="0 0 98.18 114.34"
		style={{
			shapeRendering: "geometricPrecision",
			textRendering: "geometricPrecision",
			imageRendering: "optimizeQuality",
			fillRule: "evenodd",
			clipRule: "evenodd"
		}}
	>
		<defs>
			<linearGradient id='linear-gradient-cursos' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'>
				<stop offset='0' stopColor='#006df0' />
				<stop offset='1' stopColor='#00e7f0' />
			</linearGradient>
		</defs>
		<g id="Camada_x0020_1">
			<path className="fil0" fill='url(#linear-gradient-cursos)' d="M65.69 32.8c1.16,-0.46 2.34,-0.96 3.5,-1.49 2.46,-1.13 4.87,-2.42 7,-3.84 6.42,-4.29 10.42,-9.8 7.53,-16.05 -3.79,-8.21 -8.95,-11.41 -14.36,-11.41 -2.85,0 -5.66,0.94 -8.29,2.54 -2.41,1.46 -4.69,3.49 -6.7,5.8 -2.06,2.38 -3.87,5.14 -5.29,8 -1.42,-2.85 -3.2,-5.59 -5.29,-8 -2.01,-2.32 -4.28,-4.34 -6.7,-5.8 -2.63,-1.6 -5.44,-2.54 -8.29,-2.54 -5.41,0 -10.57,3.2 -14.36,11.41 -2.89,6.26 1.12,11.77 7.53,16.05 2.12,1.42 4.53,2.71 7,3.84 1.17,0.53 2.34,1.03 3.51,1.49l-27.34 0c-1.42,0 -2.71,0.58 -3.64,1.51 -0.93,0.93 -1.51,2.22 -1.51,3.64l0 17.84 6.72 0 0 49.47c0,2.5 1.02,4.77 2.66,6.41 1.65,1.65 3.92,2.67 6.42,2.67l66.57 0c2.49,0 4.76,-1.02 6.41,-2.66l0.01 -0.02c1.64,-1.65 2.66,-3.91 2.66,-6.4l0 -49.47 6.72 0 0 -17.84c0,-1.42 -0.58,-2.71 -1.51,-3.64 -0.93,-0.94 -2.23,-1.51 -3.65,-1.51l-27.33 0zm-49.89 73.84l26.88 0c-0.13,-0.4 -0.21,-0.83 -0.21,-1.28l0 -49.57 -28.06 0 0 49.47c0,0.37 0.16,0.72 0.41,0.97 0.25,0.26 0.6,0.41 0.97,0.41zm39.7 0l26.88 0c0.38,0 0.73,-0.16 0.98,-0.4 0.24,-0.25 0.4,-0.6 0.4,-0.98l0 -49.47 -28.05 0 0 49.57c0,0.45 -0.07,0.88 -0.21,1.28zm-13.02 -58.56l0 -7.39 0 -0.19 -34.77 0 0 7.58 34.77 0zm13.22 -7.58l0.01 0.19 0 7.39 34.77 0 0 -7.58 -34.78 0zm-2.71 -12.37c0.52,-3.96 2.33,-9.12 7.2,-14.76 1.52,-1.76 3.19,-3.26 4.87,-4.27 1.47,-0.9 2.95,-1.42 4.32,-1.42 2.48,0 5.06,1.95 7.38,6.96 0.85,1.83 -1.47,4.21 -4.82,6.45 -4.18,2.78 -14.38,7.52 -18.94,7.04zm-7.79 0c-0.52,-3.96 -2.33,-9.12 -7.2,-14.76 -1.52,-1.76 -3.19,-3.26 -4.87,-4.27 -1.47,-0.9 -2.95,-1.42 -4.32,-1.42 -2.48,0 -5.06,1.95 -7.37,6.96 -0.85,1.83 1.47,4.21 4.82,6.45 4.18,2.78 14.38,7.52 18.94,7.04z" />
		</g>
	</svg>
);

export { SVG as PresentIcon };
