import React, { ReactNode } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

import { Button } from '..';

const Transition = React.forwardRef((
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

export interface Props {
    title: string;
    text: string;
    OpenButton: ReactNode;
    onConfirm: (...args: any) => void;
}

const ConfirmModal: React.FC<Props> = ({
  title,
  text,
  onConfirm,
  OpenButton,
}: Props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };
  return (
    <div>
      <a href="#" onClick={handleClickOpen}>
        {OpenButton}
      </a>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{ title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="link" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="outlined" onClick={handleConfirm}>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export { ConfirmModal };
