import React from 'react';
import { NotificationTagContainer } from './NotificationTagStyled';

export interface Props {
  /** Tipo da tag general | class_released | assistant | performance | monitor | custom */
  type: any;
  color?: string;
  backgroundColor?: string;
  text?: string;
}
const getLabel = (type: string) => {
  switch (type) {
    case 'general':
      return 'Alerta Geral';
    case 'class_released':
      return 'Aula Liberada';
    case 'assistant':
      return 'Assistente';
    case 'performance':
      return 'Seu desempenho';
    case 'monitor':
      return 'Alerta do Monitor';
    case 'custom':
      return 'Costumizado';
    default:
      return 'custom';
  }
};

const NotificationTagWrapper: React.FC<Props> = ({
  ...props
}: Props) => (
  <NotificationTagContainer
    {...props}
  >
    {props.type !== 'custom'
      ? getLabel(props.type)
      : props.text}
  </NotificationTagContainer>
);

NotificationTagWrapper.defaultProps = {
  color: '#333',
  backgroundColor: '#FFF',
};

export { NotificationTagWrapper as NotificationTag };
