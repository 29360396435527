import React, { useCallback, useEffect, useState } from "react";
import { PageContainer, GreyContainer, InnerContentContainer, Typography } from "../../components";

import clsx from "clsx";

import {
	//
	NotificationListContainer,
	NotificationCard,
	NotificationTitle,
	NotificationDescription,
	NotificationInfos,
	NotificationIcons,
	LoadMoreButtonWrapper,
	GlobalHandlersContainer,
	NotificationTag,
} from "./NotificationListStyled";

import NotificationsService from "../../services/notifications.service.js";
import { Link } from "react-router-dom";

import {
	//
	IoMdTrash,
	IoMdNotificationsOutline,
	IoMdNotifications,
	IoIosCheckmarkCircleOutline,
	IoIosCheckmarkCircle,
} from "react-icons/io";
import { Button } from "../../components/elements";

import loadingGif from "../../assets/images/loading.gif";

export interface Props {}

export type NotificationType = "Acompanhamento Tópico" | "Artigo" | "Multimídia" | "Aula Liberada" | "Comentário Aula" | "Material";
interface INotification {
	id: number | string;
	descricao: string;
	imagem: string;
	lida: false;
	link: string;
	referencia: number;
	referencia_secundaria: number;
	tipo: NotificationType;
	titulo: string;
	url: string;
}

const NotificationList: React.FC<Props> = () => {
	const [pageTitle, setPageTitle] = useState("Suas Notificações Não Lidas");
	const [nextPage, setNextPage] = useState("");
	const [notificationsCount, setNotificationsCount] = useState(0);
	const [showAll, setShowAll] = useState(false);
	const [loading, setLoading] = useState(true);
	const [loadingList, setLoadingList] = useState(true);
	const [disableButton, setDisableButton] = useState(false);
	const [notifications, setNotifications] = useState<INotification[]>([]);
	const [readNotifications, setReadNotifications] = useState<string[]>([]);

	const getNotifications = useCallback(
		async (unread: boolean) => {
			setLoading(true);
			setDisableButton(false);

			const notificationsService = new NotificationsService({ unread });

			const response = await notificationsService.list();

			if (response.next) {
				const pageNumber = response.next.split("=")[1];
				setNextPage(String(pageNumber));
			} else {
				// console.log("caiu aqui? ");
				setDisableButton(true);
			}
			setNotificationsCount(response.count);
			setNotifications(
				response.results.map((result: INotification) => {
					return {
						...result,
						id: result.url.split("/")[5],
					};
				})
			);
			setLoading(false);
			setLoadingList(false);
			if (unread) {
				setPageTitle("Suas Notificações Não Lidas");
				setShowAll(false);
			} else {
				setPageTitle("Suas Notificações");
				setShowAll(true);
			}
		},
		[notifications, showAll, nextPage]
	);

	const getNotificationLink = useCallback(
		(notification: INotification) => {
			switch (notification.tipo) {
				case "Acompanhamento Tópico":
					const communityId = notification.link.split("/")[4];
					const commentId = notification.link.split("/")[6];
					const communityLink = `detalhe-comentario/${communityId}/${commentId}`;
					return communityLink;
				case "Artigo":
					const articleLink = `artigo/${notification.link.split("/")[3]}`;
					return articleLink;
				case "Multimídia":
					const category = notification.link.split("=")[1];
					const videoId = notification.link.split("/")[4];
					const serieLink = `serie/${category}/${videoId}`;
					return serieLink;
				case "Aula Liberada":
					const courseSlug = notification.link.split("/")[3];
					const aulaId = notification.link.split("_")[1];
					const courseLink = `cursos/${courseSlug}/${aulaId}`;
					return courseLink;
				case "Comentário Aula":
					const courseCommentSlug = notification.link.split("/")[4];
					const aulaCommentId = notification.link.split("/")[5];
					const courseCommentLink = `cursos/${courseCommentSlug}/${aulaCommentId}`;
					return courseCommentLink;
				case "Material":
					//TODO: colocar id do material na rota e filtrar a página de materiais pelo ID
					return `materiais`;
				default:
					return "sem-link";
			}
		},
		[notifications]
	);

	const getNotificationTag = (type: NotificationType) => {
		return <NotificationTag type={type}>{type}</NotificationTag>;
	};

	// const filterNotificationsByTag = (tag: NotificationType) => {
	//TODO: Filtrar lista de notificações pela tag escolhida
	// }

	const loadMoreNotifications = useCallback(
		async (unread: boolean) => {
			setLoading(true);

			try {
				const notificationsService = new NotificationsService({ unread });
				const response = await notificationsService.get_next_page(nextPage);

				if (response.next) {
					const pageNumber = response.next.split("=")[1];
					setNextPage(String(pageNumber));
				} else {
					setDisableButton(true);
				}

				setNotifications([...notifications, ...response.results]);
			} catch (error) {
				console.error("Erro ao carregar mais notificações", error);
			}

			setLoading(false);
		},
		[nextPage, notifications]
	);

	const markAsRead = useCallback(
		async (id) => {
			try {
				const notificationsService = new NotificationsService();

				await notificationsService.get_by_id(id);

				setReadNotifications([...readNotifications, String(id)]);
				setNotificationsCount(notificationsCount - 1);
			} catch (error) {
				console.error("Erro ao marcar notificação como lida", error);
			}
		},
		[readNotifications, notificationsCount]
	);
	const deleteNotification = useCallback(
		async (id) => {
			try {
				const notificationsService = new NotificationsService();

				await notificationsService.delete(id);

				const newNots = notifications.filter((notification: INotification) => notification.id !== id);

				setNotifications(newNots);
			} catch (error) {
				console.error("Erro ao excluir notificação", error);
			}
		},
		[notifications]
	);

	const markAllAsRead = useCallback(async () => {
		try {
			const notificationsService = new NotificationsService();
			await notificationsService.mark_all_as_read();

			setReadNotifications(notifications.map((notification: INotification) => String(notification.id)));
			setDisableButton(true);
			setNotificationsCount(0);
		} catch (error) {
			console.error("Não foi possível marcar todas as notificações como lidas", error);
		}
	}, [notifications]);

	const deleteAll = useCallback(async () => {
		try {
			const notificationsService = new NotificationsService();
			await notificationsService.delete_all();

			setNotifications([]);
			setDisableButton(true);
			setNotificationsCount(0);
		} catch (error) {
			console.error("Não foi possível apagar todas as notificações", error);
		}
	}, [notifications]);

	useEffect(() => {
		getNotifications(true);
	}, []);
	return (
		<PageContainer pageTitle={pageTitle} showSearch={false} pageTitleHighlightText={notificationsCount}>
			<GreyContainer>
				<InnerContentContainer>
					<GlobalHandlersContainer>
						<div className='left'>
							{showAll ? (
								<button title='Mostrar notificações não lidas' onClick={() => getNotifications(true)}>
									Mostrar notificações não lidas
									<IoMdNotificationsOutline size={24} />
								</button>
							) : (
								<button title='Mostrar todas as notificações' onClick={() => getNotifications(false)}>
									Mostrar todas as notificações
									<IoMdNotifications size={24} />
								</button>
							)}
						</div>
						<div className='right'>
							<button title='Marcar todas as notificações como lidas' onClick={() => markAllAsRead()}>
								Marcar todas como lidas <IoIosCheckmarkCircleOutline size={24} />
							</button>
							<span></span>
							<button title='Apagar todas as notificações' onClick={() => deleteAll()}>
								Apagar todas <IoMdTrash size={24} />
							</button>
						</div>
					</GlobalHandlersContainer>
					{loadingList ? (
						<div style={{ textAlign: "center" }}>
							<img src={loadingGif} alt='carregando' />
						</div>
					) : (
						<>
							{notifications.length ? (
								<>
									<NotificationListContainer>
										{notifications.map((notification: INotification, index) => (
											<NotificationCard key={index} className={clsx({ "faded": readNotifications.includes(String(notification.id)) || notification.lida })}>
												<NotificationInfos>
													<Link to={getNotificationLink(notification)}>
														{getNotificationTag(notification.tipo)}
														<NotificationTitle>{notification.titulo}</NotificationTitle>
													</Link>
													<NotificationDescription>{notification.descricao}</NotificationDescription>
												</NotificationInfos>
												<NotificationIcons>
													{notification.lida ? (
														<>
															<button title='Notificação Lida'>
																<IoIosCheckmarkCircle size={24} />
															</button>
														</>
													) : (
														<>
															{!readNotifications.includes(String(notification.id)) ? (
																<button title='Marcar como lida' onClick={() => markAsRead(notification.id)}>
																	<IoIosCheckmarkCircleOutline size={24} />
																</button>
															) : (
																<button title='Notificação Lida'>
																	<IoIosCheckmarkCircle size={24} />
																</button>
															)}
														</>
													)}
													<button title='Apagar notificação' onClick={() => deleteNotification(notification.id)}>
														<IoMdTrash size={24} />
													</button>
												</NotificationIcons>
											</NotificationCard>
										))}
									</NotificationListContainer>
									<LoadMoreButtonWrapper>
										{loading ? (
											<img src={loadingGif} alt='carregando' />
										) : (
											<Button disabled={disableButton} onClick={() => loadMoreNotifications(!showAll)} variant='contained' color='primary'>
												Carregar Mais Notificações
											</Button>
										)}
									</LoadMoreButtonWrapper>
								</>
							) : (
								<Typography variant='text'>{showAll ? "Você não tem novas notificações" : "Você não tem notificações não lidas"}</Typography>
							)}
						</>
					)}
				</InnerContentContainer>
			</GreyContainer>
		</PageContainer>
	);
};

export { NotificationList };
