import styled from "styled-components";
import plusIcon from "../../assets/images/plus_icon.svg";
import media from "../../styles/media";

const ComunityDetailContainer = styled.div`
	position: relative;
	&:before {
		content: "";
		height: 100%;
		width: 2px;
		left: 45px;
		position: absolute;
		opacity: 0.2;
		background: ${(props) => props.theme.color.primary};
	}
	${media.lessThan("medium")`
      &:before{
        height: calc(100% - 30px);
        top: 30px;
      }
    `}
	&:after {
		content: "";
		width: 50px;
		height: 50px;
		border-radius: 100px;
		position: absolute;
		bottom: 0;
		left: 20px;
		background: url(${plusIcon}) no-repeat ${(props) => props.theme.color.primary};
		background-position: center;
	}
`;

const CommentWrapper = styled.div`
	position: relative;
	&:before {
		content: "";
		height: 16px;
		width: 16px;
		border-radius: 100px;
		left: 38px;
		top: 34px;
		position: absolute;
		background: ${(props) => props.theme.color.primary};
	}
`;

const LikeWrapper = styled.div`
	position: relative;
	top: -90px;
	left: 111px;
	display: flex;
	gap: 10px;
	${media.lessThan("medium")`
		max-width: 250px;
		left: 63px;
	`};
`;

export { ComunityDetailContainer, CommentWrapper, LikeWrapper };
