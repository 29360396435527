//@ts-nocheck
import React, { useEffect } from "react";

import { useParams } from "react-router-dom";

import { CourseDetailProvider } from "../../context/courseDetail/courseDetailContext";

import CourseDetailContent from "./CourseDetailContent";

interface CourseParams {
	slug: string;
	aulaid?: string;
}

const CourseDetail: React.FC = ({ ...props }) => {
	const { slug, aulaid } = useParams<CourseParams>();
	useEffect(() => {
		window.scrollTo(0, 0);
		// if (slug === "simposio-internacional-de-autismo-2022") {
		// 	const style = document.createElement('style');
		// 	style.innerHTML = `
		// 		.greyContainer {
		// 			background: url("https://simposiodeautismo.com/images/bg_principal.jpg") no-repeat top center !important;
		// 			background-size: cover !important;
		// 		}
		// 		`;
		// 	document.head.appendChild(style);
		// }
	});
	return (
		<CourseDetailProvider slug={slug} aulaid={aulaid}>
			<CourseDetailContent />
		</CourseDetailProvider>
	);
};

export { CourseDetail };
