import React, { ReactNode } from "react";
import { Title, Subtitle, Uppertitle, Text, HelpText } from "./TypographyStyled";

export interface Props {
	/**  'title' | 'subtitle' | 'uppertitle' | 'text'  */
	variant: "title" | "subtitle" | "uppertitle" | "text" | "helptext";
	/** title: 30px | subtitle: 14px | uppertitle: 20px | text: 14px  */
	size?: string;
	primary?: boolean;
	margin?: string;
	color?: string;
	children?: ReactNode;
	fontWeight?: string;
	uppercase?: boolean;
	display?: string;
	align?: string;
	lineHeight?: string;
	className?: string;
}

const TypographyWrapper: React.FC<Props> = ({ children, ...props }: Props) => (
	<>
		{props.variant === "title" && <Title {...props}>{children}</Title>}
		{props.variant === "subtitle" && <Subtitle {...props}>{children}</Subtitle>}
		{props.variant === "uppertitle" && <Uppertitle {...props}>{children}</Uppertitle>}
		{props.variant === "text" && <Text {...props}>{children}</Text>}
		{props.variant === "helptext" && <HelpText {...props}>{children}</HelpText>}
	</>
);

TypographyWrapper.defaultProps = {
	margin: "0",
	uppercase: false,
	display: "block",
	align: "left",
	primary: false,
};

export { TypographyWrapper as Typography };
