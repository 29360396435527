import { Box } from '@material-ui/core'
import React from 'react'
import { Typography } from '../components'
import { CourseCardLink } from './linkCourseCard'

export const CourseCardsBoxLayout = (result: any) => {
    return (
        <>
            {result.cursos.length > 0 && (
                <>
                    <div key={result.slug} className='resultDiv'>
                        <Typography margin='0 0 30px 0' variant='title' color='#014491'>
                            {result.nome}
                            <span className='coursesCounter'>{result.cursos.length}</span>
                        </Typography>
                        <Box
                            width='100%'
                            display='grid'
                            gridTemplateColumns={{
                                xs: "100%",
                                sm: "100%",
                                md: "24% 24% 24% 24%",
                            }}
                            gridRowGap='15px'
                            gridColumnGap='1%'>
                            {result.cursos.map((curso: any) =>
                                <React.Fragment key={curso.id}>
                                    {CourseCardLink(curso)}
                                </React.Fragment>
                            )}
                        </Box>
                    </div>
                </>
            )}
        </>
    )
}