import styled, { css } from 'styled-components';
import { Props } from './index';

const ReactionEmojiCounterContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  border-radius: 100px;
  padding: ${(props) => props.padding};
  color: ${(props) => props.fontColor};
  font-size: ${(props) => props.fontSize};
  font-weight: bold;
  line-height: 1;
  height: ${(props) => props.height};
  background-color: ${(props) => props.backgroundColor};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'normal')};
  &:not(:first-child){
    margin-left: 3px;
  }
  .emoji-mart-emoji{
    display: flex;
    align-items: center;
  }
  .counter{
    margin-left: 4px;
  }
   ${(props) => props.transparent && css`
      background-color: transparent;
    `}
`;


export { ReactionEmojiCounterContainer };
