import React, { useRef } from "react";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { Box } from "@material-ui/core";

import { ModalBase } from "../ModalBase";
//import { multipartHeaders } from '../../../../services/config';
import { LessonMaterialMultipleInput } from "../../../form/LessonMaterialMultipleInput";
import { Input, RichTextInput, ArrayTextInput } from "../../..";
import { Button } from "../../../elements";
import { useCreateFromModal } from "../../../../hooks/useCreateFromModal";
import ProdLessonService, { ILesson } from "../../../../services/prod.lesson.service";

export interface Props {
	lessonData?: ILesson;
	icon?: React.FC<{ size?: string; onClick?: () => any }>;
	successCallback?: () => void;
}

const CreateLessonWrapper: React.FC<Props> = ({ lessonData, icon: Icon, successCallback, ...props }: Props) => {
	const [open, setOpen] = React.useState(false);
	const formRef = useRef<FormHandles>(null);
	const isUpdate = !!lessonData;
	const schema = Yup.object().shape({
		nome: Yup.string().required("Este campo é obrigatório."),
		descricao: Yup.string().required("Este campo é obrigatório."),
		tags: Yup.string().required("Este campo é obrigatório."),
		materiais: Yup.array().of(
			Yup.object().shape({
				arquivo: Yup.string().required("Este campo é obrigatório."),
				titulo: Yup.string().required("Este campo é obrigatório."),
			})
		),
	});
	const handleSuccess = (data: any) => {
		setOpen(false);
		if (successCallback) {
			successCallback();
		}
	};
	const { handleSubmit, loading } = useCreateFromModal({
		formRef,
		schema,
		isUpdate,
		item: lessonData,
		service: new ProdLessonService(),
		successMessage: `Aula ${isUpdate ? "Alterada" : "Criada!"}`,
		handleSuccess,
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const submitForm = () => {
		formRef?.current?.submitForm();
	};

	return (
		<>
			<Box display='flex' width='100%' justifyContent='flex-end' alignItems='center'>
				{Icon === undefined && (
					<Button variant='add' onClick={handleClickOpen}>
						Criar Nova Aula
					</Button>
				)}
				{Icon && <Icon size='20px' onClick={handleClickOpen} />}
			</Box>
			<ModalBase loading={loading} open={open} title={isUpdate ? "Editar Aula" : "Criar Nova Aula"} handleClose={handleClose} saveAction={submitForm}>
				<Form
					ref={formRef}
					onSubmit={handleSubmit}
					initialData={{
						nome: lessonData?.name,
						descricao: lessonData?.description,
						video: lessonData?.videoUrl,
						tags: lessonData?.tags?.map((tag, index) => ({ id: index, displayValue: tag })),
						materiais: lessonData?.materials,
					}}>
					<Box display='grid' gridTemplateColumns='1fr' gridTemplateRows='3fr' gridRowGap='20px' gridColumnGap='20px'>
						<Input name='nome' label='Nome' />
						<RichTextInput name='descricao' label='Descrição' />
						<Input name='video' label='Vídeo' />
						<ArrayTextInput name='tags' label='Tags' />
					</Box>
					<LessonMaterialMultipleInput />
				</Form>
			</ModalBase>
		</>
	);
};

// CreateLessonWrapper.defaultProps = {
//   background: '#FFF'
// }

export { CreateLessonWrapper as CreateLesson };
