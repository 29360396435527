import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Box } from '@material-ui/core';

import { Typography } from '../../../Typography';
import { PlayListIcon } from '../../../elements/Icons/PlayListIcon';
import { Button } from '../../../elements/Button';
import { FloatingCard, FloatingCardFooter, FloatingCardHeader } from '../../../elements/FloatingCard';

import { CustomPlaylistType } from '../../../../types/custom_playlist';
import { CheckboxWrapper } from './AddItemToCustomPlaylistCardStyled';

export interface Props {
  playlists: CustomPlaylistType[],
}

type checkboxOptions = {
  [key: string]: boolean
}

const AddItemToCustomPlaylistCardWrapper: React.FC<Props> = ({
  playlists,
  ...props
}: Props) => {
  const checkboxesData = playlists.reduce(
    (options, option) : checkboxOptions => ({
      ...options,
      [`checkbox-${option.id}`]: false,
    }),
    {},
  );

  const [checkboxes, setCheckboxes] = React.useState<checkboxOptions>(checkboxesData);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, playlist: CustomPlaylistType) => {
    const { name } = event.target;
    setCheckboxes({
      ...checkboxes,
      [name]: !checkboxes[name],
    });
  };
  // handleFormSubmit = formSubmitEvent => {
  //   formSubmitEvent.preventDefault();

  //   Object.keys(this.state.checkboxes)
  //     .filter(checkbox => this.state.checkboxes[checkbox])
  //     .forEach(checkbox => {
  //       console.log(checkbox, "is selected.");
  //     });
  // };
  return (
    <FloatingCard {...props} right="-40px" left="auto" top="53px">
      <FloatingCardHeader>
        <PlayListIcon primaryColor rounded={false} filled={false} size="20px" />
        <Typography variant="title" color="#000" margin="0 0 0 10px">Trilhas de Conhecimento</Typography>
      </FloatingCardHeader>
      <Box display="flex" flexDirection="column">
        {playlists.map((playlist) => (
          <CheckboxWrapper key={playlist.id}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={checkboxes[`checkbox-${playlist.id}`]}
                  onChange={(e) => handleChange(e, playlist)}
                  size="small"
                  name={`checkbox-${playlist.id}`}
                  color="primary"
                />
                )}
              label={playlist.name}
            />
          </CheckboxWrapper>
        ))}


      </Box>
      <FloatingCardFooter>
        <Button variant="outlined">
          Nova Trilha
        </Button>
      </FloatingCardFooter>
    </FloatingCard>
  );
};

// AddItemToCustomPlaylistCardWrapper.defaultProps = {
//   background: '#FFF',
// };

export { AddItemToCustomPlaylistCardWrapper as AddItemToCustomPlaylistCard };
