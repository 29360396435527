import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";

import { Box } from "@material-ui/core";

import StudentProfileService, { IGenericSeal, IStudentApiResponse, IUserSeal } from "../../services/student.profile.service";
import CourseService from "../../services/course.service";

import {
	BoletimWrapper, //
	BoletimCourseName,
	BoletimCourseCard,
	BoletimCourseCardCell,
	FlexPrintTitle,
	Tab,
	TabContent,
	TabGroup,
	TabsWrapper,
} from "./StudentProfileStyled";

import printLogo from "../../assets/images/logo-ciaautismo-print.png";

//import { UpdateUserProfile as UpdateModal } from "../../components/features/Student/UpdateStudentPassword";

//import { isMobile } from "../../isMobile";
import { Typography, PageContainer, StudentDashboardHeader, InnerContentContainer, GreyContainer, AccessTimers, InfosPj } from "../../components";
import {
	StudentProfileMap,
	Divider,
	Alert,
	// WhoCanSeeTag, CourseCard
} from "../../components/elements";
import { TitleTextWhoCanSee } from "../../components/features/UserProfile";

import { IAvaliacao, IBoletim } from "../../types/course";

import { FaTimes, FaCheck, FaPrint } from "react-icons/fa";
import { Badges } from "../../helpers/badgesList";
import { IAceiteTermo } from "../../types/aceites";
import TermsService from "../../services/terms.service";

interface IBadge {
	name: string;
	image: string;
}
export interface Props {
	background?: string;
}
interface IShowUserData {
	exibir_celular: boolean;
	exibir_dados_profissionais: boolean;
	exibir_email: boolean;
	exibir_endereco: boolean;
	exibir_telefone: boolean;
	exibir_idade: boolean;
	exibir_apresentacao: boolean;
	[key: string]: string | boolean;
}

const StudentProfile: React.FC<Props> = ({ ...props }) => {
	const componentRef = useRef();
	const [userPresentation, setUserPresentation] = useState("");
	const [userState, setUserState] = useState("");
	const [userCity, setUserCity] = useState("");
	const [userCellPhone, setUserCellPhone] = useState("");
	const [userAddress, setUserAddress] = useState("");
	const [userName, setUserName] = useState("");
	const [userEmail, setUserEmail] = useState("");
	const [userAvatar, setUserAvatar] = useState("");
	const [userProfession, setUserProfession] = useState("");
	const [showUserData, setShowUserData] = useState({} as IShowUserData);
	const [userCertificates, setUserCertificates] = useState<any[]>([]);
	const [userSeals, setUserSeals] = useState<IUserSeal[]>([]);
	const [student, setStudent] = useState({} as IStudentApiResponse);
	const [boletins, setBoletins] = useState<IBoletim[]>([]);
	const [badges, setBadges] = useState<IBadge[]>([]);
	const [genericBadges, setGenericBadges] = useState<IGenericSeal[]>([]);
	const [userAceites, setUserAceites] = useState<IAceiteTermo[]>([]);
	const [userGroups, setUserGroups] = useState<string[]>([])
	const [activeUserTab, setActiveUserTab] = useState<number>(1)

	const getStudentData = useCallback(async () => {
		const studentProfileService = new StudentProfileService();
		const studentData = await studentProfileService.list();

		// console.log("STUDENT DATA", studentData);
		setStudent(studentData);
		setUserPresentation(studentData.apresentacao);
		setUserProfession(studentData.profissao);
		setUserAddress(`${studentData.logradouro}, ${studentData.numero} - ${studentData.bairro},${studentData.cidade} / ${studentData.uf}`);
		setUserState(studentData.uf);
		setUserCity(studentData.cidade);
		setUserCellPhone(studentData.celular || "");
		setUserName(studentData.nome);
		setUserEmail(studentData.email);
		setUserAvatar(studentData.foto);
		setUserCertificates(studentData.certificados);
		setUserSeals(studentData.selos);
		setGenericBadges(studentData.selos_genericos);
		setShowUserData({
			exibir_celular: studentData.exibir_celular,
			exibir_dados_profissionais: studentData.exibir_dados_profissionais,
			exibir_email: studentData.exibir_email,
			exibir_endereco: studentData.exibir_endereco,
			exibir_telefone: studentData.exibir_telefone,
			exibir_apresentacao: studentData.exibir_apresentacao,
			exibir_idade: true,
		});

		const gatheredBadges: IBadge[] = [];

		const cursosConcluidos = studentData.cursos_concluidos.map((curso: any) => {
			return curso.curso;
		});
		if (studentData.aluno_fundador) {
			gatheredBadges.push(Badges.membroFundador);
		}
		if (studentData.grupos.includes("Alunos")) {
			gatheredBadges.push(Badges.assinante);
		}

		setBadges([...badges, ...setUserBadges(cursosConcluidos), ...gatheredBadges]);
	}, []);

	const setUserBadges = useCallback(
		(cursos: string[]) => {
			const badgesGroup: IBadge[] = [];
			if (cursos.findIndex(element => element.toLowerCase().includes("Formação Avançada".toLowerCase())) > -1) {
				badgesGroup.push(Badges.formacaoAvancada);
			}

			if (cursos.findIndex(element => element.toLowerCase().includes("ABA para Pais e Aplicadores".toLowerCase())) > -1) {
				badgesGroup.push(Badges.cursoABA);
			}

			if (cursos.findIndex(element => element.toLowerCase().includes("Masterclass".toLowerCase())) > -1 || cursos.findIndex(element => element.toLowerCase().includes("CAPACITAÇÃO AVANÇADA EM INCLUSÃO ESCOLAR DE ALUNOS".toLowerCase())) > -1) {
				badgesGroup.push(Badges.masterClass);
			}

			if (cursos.findIndex(element => element.toLowerCase().includes("VB-MAPP".toLowerCase())) > -1) {
				badgesGroup.push(Badges.vbmapp);
			}

			return badgesGroup;
		},
		[userEmail]
	);

	const getBoletim = useCallback(async () => {
		const courseService = new CourseService();
		try {
			const response = await courseService.boletim();
			setBoletins(response);
			// console.log("BOLETIM => ", response);
		} catch (error) {
			setBoletins([]);
			console.error("Erro no boletim => ", error);
		}
	}, []);

	const getUserAceites = async () => {
		const termsService = new TermsService();
		try {
			const response = await termsService.getAceites();
			// console.log("Aceites => ", response);
			setUserAceites(response);
		} catch (error) {
			console.log("Erro ao listar aceites => ", error);
		}
	};

	useEffect(() => {
		getStudentData();
		getBoletim();
		getUserAceites();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (student.grupos !== undefined) {
			setUserGroups(student.grupos)
		};
	}, [student])

	const handleChangeVisibility = useCallback(
		async (key: string) => {
			const getFlag = showUserData[key];
			setShowUserData({ ...showUserData, [key]: !getFlag });

			const studentProfileService = new StudentProfileService();
			//const response =
			await studentProfileService.update(
				student.id,
				{
					nome: userName,
					...showUserData,
					[key]: !getFlag,
				},
				true // isUser
			);
			//console.log("RESPONSE UPDATE SHOW => ", response);
		},
		[showUserData, student.id, userName]
	);

	return (
		<>
			<PageContainer showBreadcrumb={false} showUserAvatar={false} showSchoolLogo={false} showSearch={false}>
				<StudentDashboardHeader
					student={student}
					onUpdateAction={getStudentData}
					isPrivateProfile
					showLogout
					studentName={userName}
					studentAddress={`${userCity}/${userState}`}
					avatarUrl={userAvatar}
					showBadges
					badges={badges}
					genericBadges={genericBadges}
				/>
				<GreyContainer>
					<TabGroup>
						{userGroups && userGroups.includes("VendaPJ") && <>
							<TabsWrapper>
								<Tab onClick={() => setActiveUserTab(1)} className={activeUserTab === 1 ? "active" : "inactive"}>Minhas Informações</Tab>
								<Tab onClick={() => setActiveUserTab(2)} className={activeUserTab === 2 ? "active" : "inactive"}>Meu Grupo</Tab>
							</TabsWrapper>
						</>}
						<TabContent className={activeUserTab === 1 ? "active" : "inactive"}>
							{/* TAB Minhas informações */}
							<Box display='flex'>
								<InnerContentContainer>
									<Box>
										<TitleTextWhoCanSee
											title='Apresentação Pessoal'
											text={userPresentation}
											whoCanSeeChange={() => {
												handleChangeVisibility("exibir_apresentacao");
											}}
											whoCanSeeValue={showUserData.exibir_apresentacao ? "all" : "owner"}
										/>
										<Divider height='2px' gradient margin='40px 0' />
									</Box>
									<Box
										display='grid'
										gridTemplateColumns={{
											sm: "100%",
											//md: "25% 25% 25% 25%",
											md: "33% 33% 33%",
										}}
										gridRowGap='30px'>
										<TitleTextWhoCanSee
											title='Email'
											text={userEmail || "Não informado"}
											whoCanSeeChange={() => {
												handleChangeVisibility("exibir_email");
											}}
											whoCanSeeValue={showUserData.exibir_email ? "all" : "owner"}
										/>
										<TitleTextWhoCanSee
											title='Profissão'
											text={userProfession}
											whoCanSeeChange={() => {
												handleChangeVisibility("exibir_dados_profissionais");
											}}
											whoCanSeeValue={showUserData.exibir_dados_profissionais ? "all" : "owner"}
										/>
										{/* <TitleTextWhoCanSee
									title='Idade'
									text={String(userAge)}
									whoCanSeeChange={() => {
										handleChangeVisibility("exibir_idade");
									}}
									whoCanSeeValue={showUserData.exibir_idade ? "all" : "owner"}
								/> */}
										<TitleTextWhoCanSee
											title='Celular'
											text={userCellPhone}
											whoCanSeeChange={() => {
												handleChangeVisibility("exibir_celular");
											}}
											whoCanSeeValue={showUserData.exibir_celular ? "all" : "owner"}
										/>
									</Box>
									<Divider height='2px' gradient margin='40px 0' />
									<TitleTextWhoCanSee
										title='Endereço e Mapa'
										text={userAddress}
										align='center'
										whoCanSeeChange={() => {
											handleChangeVisibility("exibir_endereco");
										}}
										whoCanSeeValue={showUserData.exibir_endereco ? "all" : "owner"}
									/>
									<Divider height='2px' color='transparent' margin='10px 0' />
									{userAddress ? (
										<>
											<StudentProfileMap studentData={{ name: userName, id: student.id }} editable lat={student.ltd} lng={student.lng} zoom={17} address={userAddress} />
										</>
									) : (
										""
									)}
									{userCertificates.length > 0 && (
										<>
											<Divider height='20px' color='transparent' margin='10px 0' />
											<Typography variant='title'>Meus Certificados</Typography>
											<Divider height='2px' color='transparent' margin='10px 0' />
											{userCertificates.map((certificado) => {
												return (
													<Alert
														key={certificado.created}
														backgroundColor='#FFF'
														endAdornment={
															<Typography variant='text' size='0.875rem' color='#828282' align='right'>
																Emitido em: {certificado.created} {/* separa data da hora */}
															</Typography>
														}
														text={certificado.curso}
														fontWeight='400'
														link={certificado.certificado_url}
														emitsCertificate
													/>
												);
											})}
										</>
									)}
									{userSeals.length > 0 && (
										<>
											<Divider height='20px' color='transparent' margin='10px 0' />

											<Typography variant='title'>Meus Selos</Typography>
											<Divider height='2px' color='transparent' margin='10px 0' />
											{userSeals.map((seal: IUserSeal) => {
												return (
													<Alert
														key={seal.codigo}
														backgroundColor='#FFF'
														endAdornment={
															<Typography variant='text' size='0.875rem' color='#828282' align='right'>
																Emitido em: {seal.created} {/* separa data da hora */}
															</Typography>
														}
														text={seal.curso}
														fontWeight='400'
														link={seal.selo_url}
														emitsCertificate
													/>
												);
											})}
										</>
									)}

									<Divider height='20px' color='transparent' margin='10px 0' />
									{boletins && boletins.length > 0 && (
										//@ts-ignore
										<BoletimWrapper id='printArea' ref={componentRef}>
											<img src={printLogo} className='printLogo' alt='' />
											<FlexPrintTitle>
												<div>
													<Typography variant='title'>Meus Boletins</Typography>
													<Typography variant='text'>Apenas a maior nota de cada avaliação é exibida.</Typography>
												</div>
												<ReactToPrint
													documentTitle='Histórico de Notas - CIA Autismo'
													trigger={() => (
														<button title='Imprimir histórico de notas'>
															<FaPrint size={24} color='#333' />
														</button>
													)}
													//@ts-ignore
													content={() => componentRef.current}
												/>
											</FlexPrintTitle>

											{boletins.map((boletim: IBoletim, index) => (
												<React.Fragment key={index}>
													<BoletimCourseName>
														<b>{boletim.curso}</b>
													</BoletimCourseName>
													{boletim.avaliacoes.map((avaliacao: IAvaliacao, index) => (
														<React.Fragment key={index}>
															<BoletimCourseCard>
																<BoletimCourseCardCell width={{ d: "40%", m: "100%" }} margin={{ d: "0 0 0 0", m: "0 0 16px 0" }}>
																	<b>Questionário</b> {avaliacao.questionario}
																</BoletimCourseCardCell>
																<BoletimCourseCardCell width={{ d: "30%", m: "100%" }} margin={{ d: "0 0 0 0", m: "0 0 16px 0" }}>
																	<b>Módulo</b> {avaliacao.modulo}
																</BoletimCourseCardCell>
																<BoletimCourseCardCell width={{ d: "10%", m: "30%" }}>
																	<b>Nota Min.</b> {avaliacao.nota_corte}%
																</BoletimCourseCardCell>
																<BoletimCourseCardCell width={{ d: "10%", m: "30%" }}>
																	<b>Nota</b> {avaliacao.nota}%
																</BoletimCourseCardCell>
																<BoletimCourseCardCell width={{ d: "10%", m: "30%" }}>
																	<b>Status</b>{" "}
																	{avaliacao.aprovado ? (
																		<span>
																			<FaCheck color='#429F53' /> Aprovado
																		</span>
																	) : (
																		<span>
																			<FaTimes color='#C01525' /> Reprovado
																		</span>
																	)}
																</BoletimCourseCardCell>
															</BoletimCourseCard>
														</React.Fragment>
													))}
												</React.Fragment>
											))}
										</BoletimWrapper>
									)}
									<Divider height='20px' color='transparent' margin='10px 0' />

									{userAceites.length > 0 && (
										<>
											<Divider height='20px' color='transparent' margin='10px 0' />

											<Typography variant='title'>Meus Aceites de Contrato</Typography>
											<Divider height='2px' color='transparent' margin='10px 0' />
											{userAceites.map((aceite: IAceiteTermo) => {
												return (
													<Alert
														key={aceite.id}
														backgroundColor='#FFF'
														endAdornment={
															<Typography variant='text' size='0.875rem' color='#828282' align='right'>
																Emitido em: {aceite.created} {/* separa data da hora */}
															</Typography>
														}
														text={`Termo aplicado à: ${aceite.nomes_cursos}`}
														fontWeight='400'
														link={aceite.arquivo}
													/>
												);
											})}
										</>
									)}
									<Divider height='20px' color='transparent' margin='10px 0' />
									<AccessTimers />
								</InnerContentContainer>
							</Box>

							{/* END TAB Minhas informações */}
						</TabContent>
						<TabContent className={activeUserTab === 2 ? "active" : "inactive"}>

							<Box display='flex'>
								<InnerContentContainer>
									<InfosPj />
								</InnerContentContainer>
							</Box>

						</TabContent>
					</TabGroup>

				</GreyContainer>
				{/* <InnerContentContainer>
        <Box display="flex" flexDirection="column" alignItems="center" margin="40px 0 20px 0">
          <Typography align="center" variant="title" size="1.25rem" color="#000" fontWeight="400">
            {mockData.userName}
            {' '}
            está cursando atualmente
          </Typography>
          <WhoCanSeeTag />
        </Box>
        <Grid container spacing={3} xs={12} style={{ margin: 0 }}>
          {' '}
          {courseCardsInfo().map((course) => <Grid item xs={12} lg={3}><CourseCard course={course} /></Grid>)}
        </Grid>
      </InnerContentContainer> */}
			</PageContainer>
		</>
	);
};
export { StudentProfile };
