import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import TextField from '@material-ui/core/TextField';
import { TextFieldProps as BaseTextFieldProps } from '@material-ui/core/TextField/TextField';

interface Props {
  name: string;
  label?: string;
  type?: string;
  onChange?: any;
};

type InputProps = JSX.IntrinsicElements['input'] & Props & BaseTextFieldProps;

const Input: React.FC<InputProps> = (
  {
    name, label, type, onChange, defaultValue, InputLabelProps, ...rest
  }: InputProps,
) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    fieldName,
    defaultValue: defaultFieldValue,
    registerField,
    error,
  } = useField(name);
  const defaultInputValue = defaultValue ?? defaultFieldValue;
  const [shrink, setShrink] = useState<boolean>(!!defaultInputValue);

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'value',
        clearValue(ref, resetValue: string) {
          const newValue = resetValue ?? defaultInputValue ?? '';
          ref.value = newValue;
          setShrink(!!newValue);
        },
        setValue(ref: HTMLInputElement, value: string) {
          if (ref) {
            const newValue = value ?? '';
            ref.value = newValue;
            setShrink(!!newValue);
          }
        },
      });
    }
  }, [fieldName, registerField, defaultInputValue, setShrink]);

  useEffect(() => {
    const input = inputRef.current;

    function handlerFocusEvent(evt: FocusEvent) {
      const inputValue = (evt.currentTarget as HTMLInputElement).value;
      if (!inputValue) setShrink(true);
    }

    function handlerBlurEvent(evt: FocusEvent) {
      const inputValue = (evt.target as HTMLInputElement).value;
      if (!inputValue) setShrink(false);
    }

    if (input) {
      input.addEventListener('focus', handlerFocusEvent);
      input.addEventListener('blur', handlerBlurEvent);
    }

    return () => {
      if (input) {
        input.removeEventListener('focus', handlerFocusEvent);
        input.removeEventListener('blur', handlerBlurEvent);
      }
    };
  }, [inputRef]);

  return (
    <>
      <TextField
        id={fieldName}
        error={!!error}
        helperText={error || ''}
        inputRef={inputRef}
        label={label}
        variant="standard"
        type={type}
        onChange={onChange}
        name={name}
        defaultValue={defaultInputValue}
        InputLabelProps={{
          ...InputLabelProps,
          shrink: InputLabelProps?.shrink ?? shrink,
        }}
      />

    </>
  );
};
Input.defaultProps = {
  type: 'text',
};
export { Input };
