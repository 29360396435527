import styled from "styled-components";
import { lighten } from "polished";

//comentarioEmResposta

const CommentTextBody = styled.div`
	margin: 0 0 0 16px;
	.comentarioEmResposta {
		text-decoration: none;
		color: ${(props) => props.theme.color.primary};
	}
	a {
		word-break: break-all;
	}
	img {
		max-width: 100% !important;
	}
`;

const CommentsTag = styled.span`
	white-space: nowrap;
	display: inline-block;
	padding: 5px;
	background: red;
	color: #fff;
	border-radius: 6px;
	font-size: 0.7rem;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	font-weight: 700;
`;

const Citation = styled.div`
	margin: 16px 0 16px 20px;
	padding: 8px 16px;
	border-left: 5px solid ${(props) => props.theme.color.primary};
	background: ${(props) => lighten(0.735, props.theme.color.primary)};
	.citedComment {
		font-size: 0.8rem;
		font-style: italic;
		display: block;
		margin: 0 0 12px;
		a {
			font-weight: 600;
			color: ${(props) => props.theme.color.primary};
			text-decoration: none;
		}
	}
	.citedCommentBody {
		font-size: 0.9rem;
	}
	img {
		max-width: 100% !important;
	}
`;

const CommentMeta = styled.div``;

export { CommentTextBody, CommentsTag, Citation, CommentMeta };
