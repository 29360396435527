import React, { useState } from "react";
import { IComment } from "../../helpers/formatDebateItem";

interface Props {
	children: React.ReactNode;
}

type IUseDebate = {
	debates: IComment[];
	updateDebates: (newDebates: IComment[]) => void;
};

const DebateContext = React.createContext<IUseDebate>({ debates: [], updateDebates: (newDebates) => console.warn("no debates provider") });

export const DebateProvider = (props: Props) => {
	const { children } = props;

	const [debates, setDebates] = useState<IComment[]>([]);

	const updateDebates = (newDebates: IComment[]) => {
		//console.log("NMEW DEBAT", newDebates)
		setDebates(newDebates);
	};
	const value = { debates, updateDebates };

	return <DebateContext.Provider value={value}>{children}</DebateContext.Provider>;
};
export const useDebateContext = () => React.useContext(DebateContext);
