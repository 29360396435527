import React from 'react';
import { VideoTimelinePoints } from '../../types/course';

import Swiper, { Navigation } from "swiper";
import { Swiper as Slider, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

import { VideoChaptersContainer, TimelineCard } from './VideoChaptersStyled'

export interface Props {
  timeline: VideoTimelinePoints[]
  seekFunction: (seekTo: number) => void;
}

Swiper.use([Navigation]);

const VideoChaptersWrapper: React.FC<Props> = ({
  timeline,
  seekFunction,
  ...props
}: Props) => {
  return (
    <VideoChaptersContainer {...props}>
      <Slider
        navigation
        spaceBetween={16}
        slidesPerView="auto"
        autoHeight={false}
      >
        {timeline.map((point: VideoTimelinePoints, index: number) => {
          return (
            <SwiperSlide key={index}>
              <TimelineCard onClick={() => seekFunction(point.tempo_segundos)}>
                <small>{point.tempo}</small>
                <span>{point.texto}</span>
              </TimelineCard>
            </SwiperSlide>
          )
        })}
      </Slider>
    </VideoChaptersContainer>
  );
};

VideoChaptersWrapper.defaultProps = {

}

export { VideoChaptersWrapper as VideoChapters };