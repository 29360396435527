import styled, { css } from "styled-components";

import { Props as containerProps } from "./index";

type Props = Omit<containerProps, "text">;

const AlertContainer = styled.span<Props>`
	display: flex;
	align-items: center;
	text-align: ${(props) => props.textAlign};
	width: 100%;
	margin-bottom: 10px;
	border-radius: 6px;
	border: 1px solid #f2f2f2;
	padding: 15px 20px;
	cursor: ${(props) => (props.noPointer ? "default" : "pointer")};
	font-size: ${(props) => props.fontSize};
	font-weight: ${(props) => props.fontWeight};
	${(props) =>
		props.borderColor &&
		css`
			border-color: ${props.borderColor};
		`}
	${(props) =>
		props.variant === "default" &&
		css`
			background-color: ${props.backgroundColor ? props.backgroundColor : "transparent"};
			color: ${props.textColor ? props.textColor : "#000"};
		`}
   ${(props) =>
		props.variant === "primary" &&
		css`
			background-color: ${props.backgroundColor ? props.backgroundColor : props.theme.color.primary};
			color: ${props.textColor ? props.textColor : "#FFF"};
		`}
   ${(props) =>
		props.variant === "warning" &&
		css`
			background-color: ${props.backgroundColor ? props.backgroundColor : "#fee263"};
			color: ${props.textColor ? props.textColor : "#000"};
		`}
  ${(props) =>
		props.variant === "outlined" &&
		css`
			background-color: "transparent";
			border: 0;
			color: ${props.textColor ? props.textColor : "#000"};
		`}
`;

export { AlertContainer };
