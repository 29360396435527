import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import {
	//IoIosHeart, IoIosHeartEmpty, IoMdNotificationsOutline, IoMdNotifications,
	IoIosArrowUp,
	IoIosArrowDown,
} from "react-icons/io";
import { DebateComment, DebateItem } from "../../../../types/debate";
import { Typography } from "../../../Typography";
// import { ReactionToolbar } from "../../..";
import { CardWrapper } from "../../../elements";
import { DebateHeader, DebateContent } from "./DebateItemStyled";
import { IconWrapper, IconsGroup } from "../../../elements/Icons";
import { Comment } from "../Comment";
import { isMobile } from "../../../../isMobile";

export interface Props {
	debateItem: DebateItem;
	type: "lesson" | "comunity" | "article";
	entityId?: number;
	showComments?: boolean;
	link?: boolean;
	replySuccessCallBack?: () => void;
	isAuthorUser?: boolean;
	onAction: () => void;
}

const DebateItemWrapper: React.FC<Props> = ({ debateItem, entityId, type, link, showComments, isAuthorUser, replySuccessCallBack, onAction }: Props) => {
	const [showItems, setShowItems] = useState(true);
	const handleToogleShowItems = () => {
		setShowItems(!showItems);
	};
	return (
		<CardWrapper alignItems='normal' padding='0' margin='0 0 20px 0'>
			<DebateHeader>
				{link ? (
					<Link className='link' to={`noticias/${debateItem.id}`}>
						<Typography variant='title' fontWeight='bold' size='1rem'>
							{debateItem.title}
						</Typography>
					</Link>
				) : (
					<Typography variant='title' fontWeight='bold' size='1rem'>
						{debateItem.title}
					</Typography>
				)}
				<Box display='flex' alignItems='center'>
					<IconsGroup marginLeft='8px' marginContainer='0 0 0 5px'>
						{showItems ? (
							<IconWrapper onClick={() => handleToogleShowItems()} icon={<IoIosArrowUp size='20px' />} filled={false} primaryColor />
						) : (
							<IconWrapper onClick={() => handleToogleShowItems()} icon={<IoIosArrowDown size='20px' />} filled={false} primaryColor />
						)}
					</IconsGroup>
				</Box>
			</DebateHeader>
			<AnimatePresence initial={false}>
				{showItems && (
					<motion.section
						key='content'
						initial='collapsed'
						animate='open'
						exit='collapsed'
						variants={{
							open: { opacity: 1, height: "auto", overflow: "visible" },
							collapsed: { opacity: 0, height: 0, overflow: "hidden" },
						}}
						transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}>
						<DebateContent>
							<Comment
								replySuccessCallBack={replySuccessCallBack}
								isAuthorUser={isAuthorUser}
								entityId={entityId}
								parentId={debateItem.id}
								type={type}
								showReactions={false}
								comment={{ ...debateItem.comment, id: debateItem.id }}
								link={link}
								onAction={() => onAction()}
							/>
							{showComments && (
								<>
									{debateItem.comments &&
										debateItem.comments
											.map((comment: DebateComment) => (
												<Comment
													replySuccessCallBack={replySuccessCallBack}
													entityId={entityId}
													parentId={debateItem.id || null}
													type={type}
													margin={isMobile ? "40px 0 0 0" : "40px 0 0 100px"}
													comment={comment}
													isAuthorUser={isAuthorUser}
													key={comment.id}
													onAction={() => onAction()}
												/>
											))
											.reverse()}
								</>
							)}
							{/* <ReactionToolbar
                reactions={debateItem.usersReactions}
                reactionsCount={debateItem.reactionsCount}
                usersCount={debateItem.studentsCount}
                commentsCount={debateItem.commentsCount}
              /> */}
						</DebateContent>
					</motion.section>
				)}
			</AnimatePresence>
		</CardWrapper>
	);
};

DebateItemWrapper.defaultProps = {
	showComments: true,
};

export { DebateItemWrapper as DebateItem };
