import styled from "styled-components";
import { Props } from "./index";

const StudentsAvatarGroupContainer = styled.div<Props>`
	display: flex;
	> div {
		margin: 0 0 0 -3px;
	}
	> div:nth-child(1) {
		z-index: 6;
	}
	> div:nth-child(2) {
		z-index: 5;
	}
	> div:nth-child(3) {
		z-index: 4;
	}
	> div:nth-child(4) {
		z-index: 3;
	}
	> div:nth-child(5) {
		z-index: 2;
	}
	> div:nth-child(6) {
		z-index: 1;
	}
	.studentsCount {
		display: flex;
		width: ${(props) => props.avatarSize};
		height: ${(props) => props.avatarSize};
		position: relative;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-weight: bold;
		font-size: ${(props) => props.fontSize};
		border-radius: 50%;
		background: ${(props) => props.theme.color.primary};
		.roundContainer {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			position: relative;
			overflow: hidden;
			box-shadow: 0 0 0 2px #fff, 0 0 0 10px transparent;
		}
	}
	.helpText {
		display: flex;
		align-items: center;
		margin-left: 5px;
	}
`;
export { StudentsAvatarGroupContainer };
