import styled, { css } from "styled-components";
import { isMobile } from "../../../isMobile";
import media from "../../../styles/media";
import { Props } from "./index";

const PageContainer = styled.div<Props>``;
const MainContent = styled.main<Props>`
	margin-top: ${(props) => props.theme.headerHeight};
	${media.lessThan("medium")`
        margin-top: 75px;
    `}
	&.bannerLargo {
		display: ${(props) => (props.hideBanner ? "none" : "block")};
		border: ${(props) => (props.hideBanner ? "none" : "1px solid")};
	}
	&.content {
		flex-grow: 1;
		transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
		margin-left: 75px;
		${media.lessThan("medium")`
            margin-left: 0;
        `}
		${!isMobile.any() && css`
			@media screen and (max-height: 700px) {
	 			margin-left: 55px !important;
			}
		`}
	}
	&.noMenu {
		margin-left: 0px;
	}
	footer {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 60px;
		border-top: 1px solid #ccc;
		color: #666;
		a {
			text-decoration: none;
			color: #666;
			&:hover {
				text-decoration: underline;
			}
		}
		.divider {
			${media.lessThan("medium")`
				visibility: hidden
			`}
		}
		${media.lessThan("medium")`
			flex-wrap: wrap;
			text-align: center;
			padding: 30px 0;
			height: auto;
            span, a {
				width: 100%;
			}
        `}
	}
`;

const FeedBackButton = styled.div<Props>`
	position: fixed;
	bottom: 25px;
	left: 95px;
	text-align: center;
	background: #94bb30;
	/* background: #f2c94c; */
	border-radius: 50px;
	z-index: 1119;
	height: 48px;
	overflow: hidden;
	${media.lessThan("medium")`
		bottom: 16px;
		left: 16px;
		right: auto;
		height: 55px;
		//display: none;
	`}
	a {
		/* color: ${(props) => props.theme.color.primary}; */
		color: white;
		text-decoration: none;
		display: flex;
		align-items: center;
		width: 395px;
		padding: 12px 24px;
		font-weight: 700;
		font-size: 1.025em;
		${media.lessThan("medium")`
			font-size: .9em;
			padding: 8px 20px;
			width: 200px;
			svg {
				display: none
			}
		`}
	}
	.feedbackTip {
		width: 100%;
		height: 95px;
		padding: 12px;
		border-radius: 5px;
		border: 1px solid #e0e0e0;
		position: absolute;
		top: 45px;
		left: 0;
		background-color: #fff;
		font-size: 0.9em;
		display: flex;
		align-items: center;
		box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
		opacity: 0;
		transition: 0.2s ease;
	}
	&:hover {
		box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
		overflow: visible;
		.feedbackTip {
			top: -100px;
			opacity: 1;
		}
		svg {
			transform: rotate(-15deg);
		}
	}
`;

export { PageContainer, MainContent, FeedBackButton };
