import styled from "styled-components";

const ContentLoadingErrorContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;

	.btnRefresh {
		display: flex;
		align-items: center;
		background: none;
		cursor: pointer;
		padding: 8px;
		border-radius: 5px;
		border: 1px solid #e0e0e0;
		position: relative;
		top: 0;
		color: rgba(0, 0, 0, 0.7);
		box-shadow: 0 2px 3px rgba(0, 0, 0, 0);
		transition: 0.3s ease;
		&:hover {
			box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
			color: rgba(0, 0, 0, 0.8);
			top: -2px;
		}
		.txt {
			margin-right: 7px;
		}
	}
`;
export { ContentLoadingErrorContainer };
