import styled from 'styled-components';
import { Props } from './index';
import media from 'styled-media-query';

const FeatureBannerContainer = styled.div<Props>`
  position: relative;
  width: 100%;
  height: 250px;
  overflow: hidden;
  ${media.lessThan('medium')`
    height: 200px;
  `}
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(to right, rgba(0,0,0,.5), rgba(0,0,0,0));
    position: absolute;
    z-index: 1;
  }
  img {
    position: absolute;
    z-index: 0;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    ${media.lessThan('medium')`
      height: 100%;
      top: 50%;
      object-fit: cover;
    `}
  }
  .topLayer{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    padding: 50px 0 0 30px;
    ${media.lessThan('medium')`
      padding: 10px 0 0 10px;
    `}
    .title {
      font-size: 1.875rem;
      color: white;
      display: block;
      text-shadow: 0 0 4px #000;
      ${media.lessThan('medium')`
        font-size: 1.175rem;
      `}
    }
    .subTitle {
      font-size: 0.775rem;
      color: white;
      display: block;
      margin-top: 40px;
      opacity: .9;
      ${media.lessThan('medium')`
        font-size: 0.775rem;
        margin-top: 5px
      `}
    }
    .buttonWrapper {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }
`;
export { FeatureBannerContainer };
