import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@material-ui/core';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { RelatedItemCard } from '../RelatedItemCard';
import {
  Typography, MultipleRelatedCheckboxInput,
} from '../../..';
import {
  Button,
} from '../../../elements';
import ProdLessonService from '../../../../services/prod.lesson.service';
import { SelectModuleLessonContainer } from './SelectModuleLessonStyled';
import { CreateLesson } from '../CreateLesson';

export interface Props {
  background?: string;
  action: (lessons: any) => void;
  currentSelectedLessons: LessonType[];
}

interface LessonType {
  id: number,
  name: string,
  materialsCount: number,
  description: string
}

const LessonOption = (lesson: LessonType) => (
  <RelatedItemCard title={lesson.name}>
    <Box display="grid" gridTemplateColumns="100px auto" width="100%">
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <Typography variant="text" size="1.25rem" fontWeight="bold">
          {lesson.materialsCount}
        </Typography>
        <Typography margin="0 0 0 5px" variant="text" color="#9a9a9a" size="0.875rem">
          {lesson.materialsCount > 1
            ? 'materiais' : 'material'}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        {lesson.description.length > 150
          ? <Typography variant="text" size="0.75rem"><div dangerouslySetInnerHTML={{ __html: `${lesson.description.substring(0, 140)} [ ... ]` }} /></Typography>
          : <Typography variant="text" size="0.75rem"><div dangerouslySetInnerHTML={{ __html: lesson.description }} /></Typography>}
      </Box>
    </Box>
  </RelatedItemCard>
);

const SelectModuleLessonWrapper: React.FC<Props> = ({
  action,
  currentSelectedLessons,
  ...props
}: Props) => {
  const [open, setOpen] = useState(false);
  const [lessonsOptions, setLessonsOptions] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [fullWidth, setFullWidth] = useState(true);
  const formRef = useRef<FormHandles>(null);
  const selectedLessonsIds = currentSelectedLessons.map((lesson: LessonType) => `${lesson.id}`);

  useEffect(() => {
    const lessonService = new ProdLessonService();
    lessonService.list().then((response) => {
      setLessons(response);
      const options = response.map((lesson: LessonType) => ({
        id: `${lesson.id}`,
        value: `${lesson.id}`,
        component: <LessonOption {...lesson} />,
      }));
      setLessonsOptions(options);
    });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (data: any) => {
    const lessonsIds = data.aulas.map((lessonId: string) => parseInt(lessonId));
    const selectedLessons = lessons.filter((lesson: any) => { if (lessonsIds.includes(lesson.id)) { return lesson; } });
    action(selectedLessons);
    handleClose();
  };

  const submitForm = () => {
    formRef?.current?.submitForm();
  };

  return (
    <>
      <Box display="flex" width="100%" justifyContent="flex-end" alignItems="center">
        <Button type="button" variant="add" onClick={handleClickOpen}>
          Incluir Aulas
        </Button>
      </Box>
      <Dialog
        fullWidth={fullWidth}
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          Aulas disponíveis
          {' '}
          <CreateLesson />
        </DialogTitle>
        <DialogContent>
          <Form initialData={{ aulas: selectedLessonsIds }} ref={formRef} onSubmit={handleSubmit}>
            <MultipleRelatedCheckboxInput name="aulas" options={lessonsOptions} />
          </Form>
        </DialogContent>
        <DialogActions>
          <Button variant="link" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="save" onClick={submitForm}>Salvar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

SelectModuleLessonWrapper.defaultProps = {
  background: '#FFF',
};

export { SelectModuleLessonWrapper as SelectModuleLesson };
