import React from 'react';
import { Notification } from './Notification';
import { Button } from '../../elements/Button';
import { FloatingCard, FloatingCardFooter } from '../../elements/FloatingCard';

export interface Props {
    // esse prop deve ser um array de objetos de notificação
    // botei esse array of any aqui pra tirar o erro e compilar e deu bom
    notifications: any[]
}

const NotificationsWrapper: React.FC<Props> = ({
  ...props
}: Props) => {
  const { notifications } = props;
  return (
    <FloatingCard {...props}>
      {notifications.map((notification, index) => (
        <Notification notification={notification} />
      ))}
      <FloatingCardFooter>
        <Button variant="outlined">
          Tudo Lido
        </Button>
        <Button variant="outlined">
          Meu Perfil
        </Button>
      </FloatingCardFooter>
    </FloatingCard>
  );
};


export { NotificationsWrapper as Notifications };
