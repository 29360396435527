import React, { useState, useEffect, useCallback } from "react";
import { Module, ModuleClass, Course } from "../../types/course";
import CourseService from "../../services/course.service";
import { isMobile } from "../../isMobile";

type CourseDetailContextType = {
	loading: boolean;
	showModuleList: boolean;
	isCourseFinished: boolean;
	courseCertificateUrl: string;
	course: Course | undefined;
	activeModule: Module | undefined;
	activeModuleClass?: ModuleClass | undefined;
	sealAvailable: boolean;
	sealUrl: string;
	nextLesson: ModuleClass | undefined;
	previousLesson: ModuleClass | undefined;
	nextModule: Module | undefined;
	previousModule: Module | undefined;
	updateActiveModule(module: Module): void;
	updateActiveModuleClass(moduleClass: ModuleClass | null): void;
	openModuleList: () => void;
	closeModuleList: () => void;
	getCourseDetails: () => void;
};

const CourseDetailContext = React.createContext<CourseDetailContextType | undefined>(undefined);

type Props = {
	children: React.ReactNode;
	slug: string;
	aulaid?: string;
};

export const CourseDetailProvider = ({ slug, aulaid, children }: Props) => {
	const [loading, setLoading] = useState(false);
	const [courseCertificateUrl, setCourseCertificateUrl] = useState("");
	const [isCourseFinished, setIsCourseFinished] = useState(false);
	const [sealAvailable, setSealAvailable] = useState(false);
	const [sealUrl, setSealUrl] = useState("");
	const [course, setCourse] = useState<Course | undefined>(undefined);
	const [activeModuleClass, setActiveModuleClass] = useState<ModuleClass | undefined>(undefined);
	const [activeModule, setActiveModule] = useState<Module | undefined>(undefined);
	const [showModuleList, setShowModuleList] = useState(!isMobile.any());
	const [nextLesson, setNextLesson] = useState<ModuleClass | undefined>(undefined);
	const [previousLesson, setPreviousLesson] = useState<ModuleClass | undefined>(undefined);
	const [nextModule, setNextModule] = useState<Module | undefined>(undefined);
	const [previousModule, setPreviousModule] = useState<Module | undefined>(undefined);

	const getNextLesson = (activeModule: Module, lessonIndex: number) => {
		// console.log("NEXT ->", activeModule.moduleClasses[lessonIndex + 1]);
		if (activeModule.moduleClasses[lessonIndex + 1]) {
			return setNextLesson(activeModule.moduleClasses[lessonIndex + 1]);
		}
		return setNextLesson(undefined);
	};

	const getPreviousLesson = (activeModule: Module, lessonIndex: number) => {
		// console.log("PREV ->", activeModule.moduleClasses[lessonIndex - 1]);
		if (activeModule.moduleClasses[lessonIndex - 1]) {
			return setPreviousLesson(activeModule.moduleClasses[lessonIndex - 1]);
		}
		return setPreviousLesson(undefined);
	};

	const getNextModule = (moduleIndex: number) => {
		// console.log("NEXT M ->", course?.modules[moduleIndex + 1]);
		if (course && course.modules[moduleIndex + 1]) {
			return setNextModule(course.modules[moduleIndex + 1]);
		}
		return setNextModule(undefined);
	};

	const getPreviousModule = (moduleIndex: number) => {
		// console.log("PREV M ->", course?.modules[moduleIndex - 1]);
		if (course && course.modules[moduleIndex - 1]) {
			return setPreviousModule(course.modules[moduleIndex - 1]);
		}
		return setPreviousModule(undefined);
	};

	const getNextAndPreviousLessons = (lessonIndex: number, moduleIndex: number) => {
		// console.log("getNextAndPreviousLessons ->", activeModuleClass);
		if (activeModule && activeModuleClass) {
			getNextLesson(activeModule, lessonIndex);
			getPreviousLesson(activeModule, lessonIndex);
			getNextModule(moduleIndex);
			getPreviousModule(moduleIndex);
		}
	};

	const updateActiveModule = (module: Module) => {
		setActiveModule(module);

		if (module && activeModule && module.id !== activeModule.id && !module.released) {
			setShowModuleList(false);
		}
	};

	const updateActiveModuleClass = async (moduleClass: ModuleClass) => {
		// console.log("TESTE ACTIVE MODULE CLASS", moduleClass);
		if (moduleClass && activeModuleClass && moduleClass.id !== activeModuleClass.id) {
			const courseService = new CourseService();
			await courseService.mark_as_last_seen(moduleClass.id);

			if (isMobile.any()) {
				setShowModuleList(false);
			}
			setActiveModuleClass(moduleClass);
		}
	};

	const openModuleList = () => {
		setShowModuleList(true);
	};

	const closeModuleList = () => {
		setShowModuleList(false);
	};

	const getQuiz = async (moduleId: number) => {
		const courseService = new CourseService();
		const response = await courseService.get_quiz_by_module_id(moduleId);

		return response;
	};

	const getCourseDetails = async () => {
		const courseService = new CourseService();
		const course = await courseService.get_by_slug(slug);

		setIsCourseFinished(course.isFinished);

		if (course.hasCertificate && course.isCertificateAvailable) {
			setCourseCertificateUrl(course.certificateUrl);
		}

		setSealAvailable(course.isSealAvailable);
		setSealUrl(course.sealUrl);
	};

	const populateCourse = async () => {
		setLoading(true);
		const courseService = new CourseService();

		const course = await courseService.get_by_slug(slug);

		setIsCourseFinished(course.isFinished);
		setCourseCertificateUrl(course.certificateUrl);
		setSealAvailable(course.isSealAvailable);
		setSealUrl(course.sealUrl);

		const modules = await courseService.get_modules_by_slug(course.name, slug);

		const lastSeen = await courseService.get_last_seen(slug);

		//console.log("MODULES FROM CONTEXT => ", modules);

		const modulesWithQuizPromise = modules.map((modulo: any) => {
			if (!modulo.quizID) return modulo;

			return getQuiz(modulo.id)
				.then((res) => {
					return { ...modulo, quizObject: res };
				})
				.catch((error) => {
					console.error("Deu erro aqui no questionário => ", error);
				});
		});

		//Espera até resolver todas as promises dentro do map anterior para setar os dados do curso

		Promise.all(modulesWithQuizPromise).then((modulesWithQuiz) => {
			let courseData = { ...course, modules: modulesWithQuiz };
			setCourse(courseData);
		});

		if (modules.length > 0) {
			let aId: number | undefined;

			if (aulaid) {
				aId = parseInt(aulaid);
			} else {
				aId = lastSeen;
			}

			//console.log("Aula ID => ", aId);
			if (aId) {
				const filtraAulaPorID: ModuleClass[] = modules
					.map((modulo: Module) => {
						return modulo.moduleClasses;
					}) // Retorna um array de arrays. Cada array retornado tem as aulas de um módulo.
					.flat() // Retorna um novo array contendo os elementos dos sub-arrays anteriores, ou seja, um array de todas as aulas do curso
					.filter((aula: ModuleClass) => {
						return aula.id === aId && aula.isReleased && !aula.blockedByQuiz;
					}); // Retorna um array de 1 elemento com a aula encontrada por ID

				if (filtraAulaPorID.length > 0) {
					const filtraModuloPelaAula: Module[] = modules.filter((modulo: Module) => {
						return modulo.name === filtraAulaPorID[0].moduleName;
					}); // Retorna o módulo cujo nome é igual ao módulo da aula filtrada pelo ID

					setActiveModule(filtraModuloPelaAula[0]);
					setActiveModuleClass(filtraAulaPorID[0]);
				} else {
					setActiveModule(modules[0]);
					if (modules[0].moduleClasses?.length > 0) {
						setActiveModuleClass(modules[0].moduleClasses[0]);
					}
				}
			} else {
				setActiveModule(modules[0]);
				if (modules[0].moduleClasses?.length > 0) {
					setActiveModuleClass(modules[0].moduleClasses[0]);
				}
			}
		}
		setTimeout(() => {
			setLoading(false);
		}, 500);
	};
	const shouldShowModules = useCallback(() => {
		// alert(isMobile.iPad());
		if (window.innerWidth > 900) {
			// alert("not showModules");
			setShowModuleList(false);
		} else {
			// alert("showModules");
			setShowModuleList(true);
		}
	}, [window.innerWidth]);

	useEffect(() => {
		window.addEventListener("orientationchange", function () {
			if (!isMobile.any() || isMobile.iPad()) {
				shouldShowModules();
			}
		});
		populateCourse();
	}, [slug, aulaid]);

	useEffect(() => {
		if (activeModule && activeModuleClass && course && course.modules) {
			let lessonIndex = 0;
			let moduleIndex = 0;
			lessonIndex = activeModule.moduleClasses.findIndex((lesson) => {
				return lesson.id === activeModuleClass.id;
			});
			moduleIndex =
				course.modules.findIndex((module) => {
					return module.id === activeModule.id;
				}) || 0;
			getNextAndPreviousLessons(lessonIndex, moduleIndex);
		}
	}, [activeModuleClass, activeModule, course]);

	const value = {
		loading,
		course,
		activeModule,
		updateActiveModule,
		activeModuleClass,
		updateActiveModuleClass,
		showModuleList,
		openModuleList,
		closeModuleList,
		getCourseDetails,
		isCourseFinished,
		courseCertificateUrl,
		sealAvailable,
		sealUrl,
		nextLesson,
		previousLesson,
		nextModule,
		previousModule,
	};

	return <CourseDetailContext.Provider value={value}>{children}</CourseDetailContext.Provider>;
};

export const useCourseDetail = () => React.useContext(CourseDetailContext);
