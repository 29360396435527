import React from 'react';
import { Box } from '@material-ui/core';
import { LoadingPlaceholder } from '../../../elements';
import {
  RelatedItemCardContainer, TitleWrapper, InfoWrapper, Content, Order,
} from './RelatedItemCardStyled';

export interface Props {
    showOrder?: boolean
}

const LoadingRelatedItemCardWrapper: React.FC<Props> = ({
  showOrder,
}: Props) => (
  <RelatedItemCardContainer>
    <Order order={1} />
    <InfoWrapper>
      <TitleWrapper>
        <div><LoadingPlaceholder variant="onelinetext" /></div>

      </TitleWrapper>
    </InfoWrapper>
    <Content>
      <Box width="100%">
        <LoadingPlaceholder variant="onelinetext" />
      </Box>
    </Content>
  </RelatedItemCardContainer>
);

LoadingRelatedItemCardWrapper.defaultProps = {
  showOrder: false,
};

export { LoadingRelatedItemCardWrapper as LoadingRelatedItemCard };
