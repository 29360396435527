import styled from "styled-components";
import media from "../../styles/media";

const AccessTimersContainer = styled.div`
	display: flex;
	gap: 1rem;
	flex-wrap: wrap;
`;

const TimerBox = styled.div<{ diasRestantes: number }>`
	padding: 10px;
	width: 300px;
	border-radius: 10px;
	border-width: 1px;
	border-style: solid;
	background: ${(props) => (props.diasRestantes < 90 ? "#faf0f0" : "white")};
	border-color: ${(props) => (props.diasRestantes < 90 ? "red" : "#f2f2f2")};
	${media.lessThan("medium")`
    width: 100%;
  `}
	div {
		margin-bottom: 0.7rem;
	}
	strong {
		font-weight: bold;
		opacity: 0.8;
		&.alerta {
			color: rgba(255, 0, 0, 0.7);
		}
	}
	span {
		opacity: 0.7;
	}
	big {
		display: block;
		margin: 0 0 1rem;
		font-weight: bold;
	}
`;

export { AccessTimersContainer, TimerBox };
