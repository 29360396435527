import styled from "styled-components";

interface Props {
	backgroundColor?: string;
}

const ReactionIconCounterContainer = styled.div<Props>`
	display: flex;
	align-items: center;
	border-radius: 100px;
	padding: 4px 7px;
	color: #000;
	font-size: 0.875rem;
	font-weight: bold;
	line-height: 14px;
	background-color: ${(props) => props.backgroundColor};
	margin-left: 3px;
	svg {
		color: ${(props) => props.theme.color.primary};
	}
	.counter {
		margin-left: 4px;
	}
`;
export { ReactionIconCounterContainer };
