import React, { ReactNode } from 'react';
import { Box } from '@material-ui/core';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { CircularAvatarPhoto } from '../../../elements';
import {
  RelatedItemCardContainer, Order, TitleWrapper, Subtitle, Content, InfoWrapper,
} from './RelatedItemCardStyled';

export interface Props {
  order?: number,
  imageUrl?: string,
  showImage?: boolean,
  title: string,
  subtitle?: string,
  children?: ReactNode
}

const RelatedItemCardWrapper: React.FC<Props> = ({
  order,
  imageUrl,
  showImage,
  title,
  subtitle,
  children,
  ...props
}: Props) => (
  <RelatedItemCardContainer>
    {order
      && (
      <Order order={order}>
        {order > 1
          && <TiArrowSortedUp size="20px" color="#C4C4C4" />}
        <div className="order-number">{order}</div>
        <TiArrowSortedDown size="20px" color="#C4C4C4" />
      </Order>
      )}
    <InfoWrapper>

      {showImage
            && (
            <Box margin="-10px 10px -10px -10px">
              <CircularAvatarPhoto
                avatarImg={imageUrl}
                avatarName={title}
                size="50px"
                borderColor="primary"
                lighten
                borderType="single"
                innerBorderSize="2px"
              />
            </Box>
            )}
      <TitleWrapper>
        <div>{title}</div>
        {subtitle
            && <Subtitle>{subtitle}</Subtitle>}
      </TitleWrapper>
    </InfoWrapper>
    <Content>
      {children}
    </Content>
  </RelatedItemCardContainer>
);

RelatedItemCardWrapper.defaultProps = {

};

export { RelatedItemCardWrapper as RelatedItemCard };
