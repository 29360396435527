import { createGlobalStyle } from "styled-components";
import media from "./media";

export const GlobalStyles = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap');
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        border: 0;
        &:focus, &::visited{
            outline: none
        }
    }
    
	.MuiDrawer-root {
        ${media.lessThan("medium")`
            background: none !important;
            z-index: 999999999 !important;
        `}
	}
`;
