import styled from 'styled-components';
import { AlertContainer } from '../AlertStyled';

const MonitorAlertContainer = styled(AlertContainer)`
  
 
`;

const MonitorAvatarWrapper = styled.div`
  position: absolute;
`;
const MonitorAlertContent = styled.div`
  margin-left: 130px;
`;
export { MonitorAlertContainer, MonitorAvatarWrapper, MonitorAlertContent };
