import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@material-ui/core";

//import { isMobile } from "../../isMobile";
import { TitleTextWhoCanSee } from "../../components/features/UserProfile";
import { Typography, PageContainer, StudentDashboardHeader, InnerContentContainer, GreyContainer } from "../../components";
import {
	StudentProfileMap,
	Divider,
	Alert,
	// WhoCanSeeTag, CourseCard, DataChart
} from "../../components/elements";
import PublicProfileService from "../../services/publicProfile.service";
import { IGenericSeal, IStudentApiResponse } from "../../services/student.profile.service";
import { PublicProfileContainer } from "./PublicProfileStyled";
import { Badges } from "../../helpers/badgesList";
import { isMobile } from "../../isMobile";

interface IBadge {
	name: string;
	image: string;
}
export interface Props {
	background?: string;
}

const PublicProfile: React.FC<Props> = ({ ...props }) => {
	const [studentData, setStudentData] = useState({} as IStudentApiResponse);
	const [isMonitor, setIsMonitor] = useState(false);
	const [badges, setBadges] = useState<IBadge[]>([]);
	const [genericBadges, setGenericBadges] = useState<IGenericSeal[]>([]);

	const { id } = useParams<{ id: string }>();

	const setUserBadges = useCallback(
		(cursos: string[]) => {
			const badgesGroup: IBadge[] = [];
			if (cursos.includes("Formação Avançada em Terapia Comportamental") || cursos.includes("Formação Avançada em Terapia ABA 2020")) {
				badgesGroup.push(Badges.formacaoAvancada);
			}

			if (cursos.includes("Curso em Terapia ABA no Autismo para Pais e Aplicadores") || cursos.includes("Curso em Terapia ABA para Pais e Aplicadores 2021")) {
				badgesGroup.push(Badges.cursoABA);
			}

			if (cursos.includes("Masterclass em Inclusão Escolar no TEA") || cursos.includes("Masterclass em Inclusão Escolar no TEA 2020")) {
				badgesGroup.push(Badges.masterClass);
			}

			if (cursos.includes("Curso Avançado em Protocolo VB-MAPP 2020") || cursos.includes("Curso Avançado em Protocolo VB-MAPP")) {
				badgesGroup.push(Badges.vbmapp);
			}

			return badgesGroup;
		},
		[id]
	);

	const getStudentData = useCallback(async () => {
		const publicProfileService = new PublicProfileService();
		const response = await publicProfileService.get_by_id(id);
		setStudentData(response);
		//console.log("STUDENT DATA ==> ", response);

		if (response.grupos.includes("Tutor") || response.grupos.includes("Professor") || response.grupos.includes("Mentores")) {
			setIsMonitor(true);
		}

		const gatheredBadges: IBadge[] = [];

		const cursosConcluidos = response.cursos_concluidos.map((curso: any) => {
			return curso.curso;
		});
		if (response.aluno_fundador) {
			gatheredBadges.push(Badges.membroFundador);
		}
		if (response.grupos.includes("Alunos")) {
			gatheredBadges.push(Badges.assinante);
		}

		setGenericBadges(response.selos_genericos);
		setBadges([...badges, ...setUserBadges(cursosConcluidos), ...gatheredBadges]);
	}, [id]);

	useEffect(() => {
		getStudentData();
	}, [id]);
	return (
		<PageContainer showBreadcrumb={false} showSearch={false}>
			<PublicProfileContainer {...props}>
				<StudentDashboardHeader
					studentName={studentData.nome}
					studentAddress={studentData.exibir_endereco ? `${studentData.cidade}/${studentData.uf}` : "Endereço não disponível"}
					avatarUrl={studentData.foto}
					showLogout={false}
					isMonitor={isMonitor}
					showBadges
					badges={badges}
					genericBadges={genericBadges}
				/>
				<GreyContainer>
					<Box display='flex'>
						<InnerContentContainer>
							<Box>
								<TitleTextWhoCanSee title='Apresentação Pessoal' text={studentData.apresentacao || "Não informado"} />
								<Divider height='2px' gradient margin='40px 0' />
							</Box>
							<Box display='flex' justifyContent='center' flexWrap='wrap'>
								<div style={{ margin: isMobile.any() ? "0 0 25px" : "0 15px", width: isMobile.any() ? "100%" : "20%", minWidth: "280px" }}>
									<TitleTextWhoCanSee title='Familiar de pessoa com TEA?' text={studentData.is_familiar ? "Sim" : "Não"} />
								</div>
								<div style={{ margin: isMobile.any() ? "0" : "0 15px", width: isMobile.any() ? "100%" : "20%", minWidth: "280px" }}>
									<TitleTextWhoCanSee title='Atua profissionalmente com TEA?' text={studentData.is_profissional ? "Sim" : "Não"} />
								</div>
							</Box>
							<Divider height='2px' gradient margin='40px 0' />
							<Box
								display='grid'
								gridTemplateColumns={{
									sm: "100%",
									//md: "25% 25% 25% 25%",
									md: "33% 33% 33%",
								}}
								gridRowGap='30px'>
								{studentData.exibir_email ? (
									<TitleTextWhoCanSee title='Email' text={studentData.email ? studentData.email : "Não informado"} />
								) : (
									<TitleTextWhoCanSee title='Email' text='Não disponível' />
								)}

								{studentData.exibir_dados_profissionais ? (
									<TitleTextWhoCanSee align='center' title='Profissão' text={studentData.profissao} />
								) : (
									<TitleTextWhoCanSee title='Profissão' text='Não disponível' />
								)}

								{/* <TitleTextWhoCanSee title='Idade' text={"30"} /> */}

								{studentData.exibir_celular ? (
									<TitleTextWhoCanSee title='Celular' text={studentData.celular ? studentData.celular : "Não informado"} />
								) : (
									<TitleTextWhoCanSee title='Celular' text='Não disponível' />
								)}
							</Box>
							<Divider height='2px' gradient margin='40px 0' />
							{studentData.exibir_endereco ? (
								<>
									<TitleTextWhoCanSee
										title='Endereço e Mapa'
										text={`${studentData.logradouro}, ${studentData.numero} - ${studentData.bairro},${studentData.cidade} / ${studentData.uf}`}
										align='center'
									/>

									<Divider height='2px' color='transparent' margin='10px 0' />
									<StudentProfileMap
										address={`${studentData.logradouro}, ${studentData.numero} - ${studentData.bairro},${studentData.cidade} / ${studentData.uf}`}
										lat={studentData.ltd}
										lng={studentData.lng}
										zoom={17}
									/>
								</>
							) : (
								<TitleTextWhoCanSee title='Endereço e Mapa' text='Não disponível' align='center' />
							)}
							<Divider height='2px' gradient margin='40px 0' />

							<TitleTextWhoCanSee title='Cursos Concluídos' text='' />

							{studentData.cursos_concluidos?.map((certificado) => {
								return (
									<Alert
										key={certificado.id}
										backgroundColor='#FFF'
										endAdornment={
											<Typography variant='text' size='0.875rem' color='#828282' align='right'>
												Emitido em: {certificado.created.split(" ")[0]} {/* separa data da hora */}
											</Typography>
										}
										text={certificado.curso}
										fontWeight='400'
									/>
								);
							})}
						</InnerContentContainer>
					</Box>
				</GreyContainer>
			</PublicProfileContainer>
		</PageContainer>
	);
};

export { PublicProfile };
