import React, { useEffect, useRef, InputHTMLAttributes } from 'react';
import { useField } from '@unform/core';
import { Box } from '@material-ui/core';
import { CheckboxInputContainer, CheckboxInputWrapper } from './CheckboxInputStyled';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    id: string;
    name: string;
    defaultChecked?: boolean;
    label: string;
    helpText?: string;
}

const CheckboxInput: React.FC<Props> = ({
  name, id, label, helpText, defaultChecked, ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue = false, registerField } = useField(name);

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'checked',
      });
    }
  }, [fieldName, registerField]);

  return (
    <Box display="grid">
      <CheckboxInputWrapper>
        <input
          defaultChecked={Boolean(defaultValue) || Boolean(defaultChecked)}
          ref={inputRef}
          className="checkbox-input"
          type="checkbox"
          id={id}
          {...rest}
        />
        <label htmlFor={id} key={id}>
          <CheckboxInputContainer>
            <div className="checkbox-label">{label}</div>
            {helpText
                        && <div className="checkbox-help-text">{helpText}</div>}
          </CheckboxInputContainer>
        </label>
      </CheckboxInputWrapper>

    </Box>
  );
};
CheckboxInput.defaultProps = {
  defaultChecked: false,
};
export { CheckboxInput };
