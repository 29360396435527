import React, { ReactNode } from "react";
import { Box } from "@material-ui/core";
import { Breadcrumb } from "../../Structure/Breadcrumb";
import { Typography } from "../..";
import { PageTitleContainer } from "./PageTitleStyled";

export interface Props {
	title?: string;
	highlightText?: number | string;
	showBreadcrumb?: boolean;
	children?: ReactNode;
}

const PageTitleWrapper: React.FC<Props> = ({ title, highlightText, showBreadcrumb, children }: Props) => (
	<PageTitleContainer>
		{showBreadcrumb && <Breadcrumb />}
		<Box display='flex' alignItems='center' margin='0 20px'>
			{title && (
				<Typography variant='title' fontWeight='700'>
					{title}
				</Typography>
			)}
			{highlightText ? (
				<Typography margin={title ? "0 0 0 10px" : "0"} variant='title' size='1.25rem' primary>
					{highlightText}
				</Typography>
			) : (
				""
			)}
			{children && children}
		</Box>
	</PageTitleContainer>
);

PageTitleWrapper.defaultProps = {
	showBreadcrumb: true,
};

export { PageTitleWrapper as PageTitle };
