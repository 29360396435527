import React, { ReactNode } from 'react';
import { CourseCardContainer } from './CourseCardStyled';
import { CourseCard } from '../CourseCard';
import { ICourse as IProducerCourse } from '../../../../services/prod.course.service';

export interface Props {
  course:IProducerCourse;
  editUrl:string;
  viewUrl:string;
}

// estes dados não estão sendo retornados da api
const producerInformation = { modules: 999, students: 999, suitableCourses: 999 };

const CourseCardWrapper: React.FC<Props> = ({
  course,
  editUrl,
  viewUrl,
  ...props
}: Props) => (
  <CourseCard
    course={{
      name: course.name,
      imageUrl: course.thumbnail,
      teachers: course.teachers.map((teacher) => teacher.nome),
      description: course.description,
      certificate: course.hasCertificate,
      status: 'completed', // não está sendo retornado da api
      releasedLessons: 999, // não está sendo retornado da api
      slug: course.slug,
      producerInformation, // não está sendo retornado da api
      created: course.created,
    }}
    editUrl={editUrl}
    viewUrl={viewUrl}
  />
);
CourseCardWrapper.defaultProps = {

};

export { CourseCardWrapper as ProducerCourseCard };
