import styled from "styled-components";
import { Props } from "./index";

const ContratosContainer = styled.div<Props>`
	.MuiDrawer-root {
		display: none;
	}
`;

const TermBody = styled.div`
	margin: 25px 0 80px;
	max-height: 60vh;
	overflow: auto;
	img {
		display: block;
		margin: 0 auto 25px;
		max-width: 100%;
		height: auto !important;
	}
	p,
	ol,
	ul {
		margin: 0 0 25px;
		line-height: 1.2em;
		opacity: 0.8;
	}
	ol,
	ul {
		list-style-position: inside;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 7px;
		color: ${(props) => props.theme.color.primary} !important;
		strong {
			color: ${(props) => props.theme.color.primary} !important;
		}
	}
`;

export { ContratosContainer, TermBody };
