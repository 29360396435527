import { useState } from "react";
import formatComments, { IComment } from "../helpers/formatDebateItem";

interface IUseDebate {
	service: any;
	successMessage: string;
	currentDebates: IComment[] | undefined;
	updateDebates: (debates: IComment[]) => void;
	isUpdate?: boolean;
	item?: any;
	timestamp?: number;
}

export const useDebate = (props: IUseDebate) => {
	const { service, currentDebates, updateDebates, timestamp } = props;

	const [loading, setLoading] = useState(false);

	const [page, setPage] = useState(1);
	const [hasNextPage, setHasNextPage] = useState(false);
	const debates = currentDebates ? currentDebates : [];

	async function list() {
		setLoading(true);
		const response = await service.list(true);

		//console.log("RESPONSE HOOK ->", response);

		updateDebates(formatComments(response.results));

		if (response.next) {
			handlePageNumber(response.next);
			setHasNextPage(true);
		} else {
			setHasNextPage(false);
		}
		setLoading(false);
	}

	const handlePageNumber = (nextUrl: string) => {
		if (nextUrl) {
			const page = nextUrl.split("page=")[1];
			setPage(parseInt(page));
		}
	};

	async function loadMoreComments() {
		if (page < 2) return;
		try {
			setLoading(true);
			const response = await service.loadPage(page, timestamp);

			updateDebates([...debates, ...formatComments(response.results)]);
			setLoading(false);

			//console.log("MORE COMMENTS => ", response);

			if (response.next) {
				handlePageNumber(response.next);
				setHasNextPage(true);
			} else {
				setHasNextPage(false);
			}
		} catch (error) {
			//console.log("error fetching comments", error);
		}
	}

	return { loading, loadMoreComments, list, hasNextPage };
};
