import styled from 'styled-components';
import arrow from '../../../assets/images/arrow.svg';


const WhoCanSeeTagContainer = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #E0E0E0;
    border-radius: 100px;
    margin-top: 5px;
    > div{
      display: flex;
      align-items: center;
      height: 25px;
      padding: 5px;
    }
    .selectWrapper{
        border-left: 1px solid #E0E0E0;
        select{
          font-size: 0.75rem;
          background: url(${arrow}) no-repeat transparent;
          background-position: 99% center;
          padding-right: 10px;
          appearance: none; 
          height: 100%;
          color: #333;
         text-transform: uppercase;
         option{
           padding: 10px;
         }
        }
    }
`;
export { WhoCanSeeTagContainer };
