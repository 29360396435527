import styled from "styled-components";

const ProducerCoursesContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	position: relative;
	top: 20px;
	width: 100%;
	.courseLink {
		position: relative;
		transition: 0.3s ease;
		top: 0;
		h1 {
			transition: 0.3s ease;
		}
		&:hover {
			top: -20px;
			h1 {
				color: ${(props) => props.theme.color.primary};
			}
		}
	}
	span {
		color: ${(props) => props.theme.color.primary};
		font-weight: bold;
		font-size: 1rem;
	}
`;

export { ProducerCoursesContainer };
