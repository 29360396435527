import BaseService from "./base.service";
import api from "./api";
//import { headers } from "./config";

export default class NotificationsService extends BaseService {
	constructor(options) {
		if (options) {
			if (options.unread === true) {
				// console.log("OPTIONS", options);
				super("notificacoes/nao-lidas");
			} else {
				super("notificacoes");
			}
		} else {
			super("notificacoes");
		}
	}
	async get_next_page(page) {
		try {
			const response = await api.get(`${this.resource}?page=${page}`);
			return response.data;
		} catch (error) {
			console.error("Erro ao carregar mais notificações");
		}
	}
	async mark_all_as_read() {
		try {
			const response = await api.get(`${this.resource}/ler-todas`);
			return response.data;
		} catch (error) {
			console.error("Não foi possível marcar as noticicações como lidas", error);
		}
	}
	async delete_all() {
		try {
			const response = await api.delete(`${this.resource}/apagar-todas`);
			return response.data;
		} catch (error) {
			console.error("Não foi possível apagar as noticicações", error);
		}
	}
}
